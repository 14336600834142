const { REACT_APP_WEBHOOK_API_URL: API_URL } = process.env;


class WebhookClient {
  baseUrl: string = `${API_URL || ''}/webhook-api`;

  getAuth = () => {
    const token = localStorage.getItem('token');
    return `Bearer ${token}`;
  }

  get = async (endpoint: string, isJson: boolean) => {
    const resp = await fetch(`${this.baseUrl}/${endpoint}`, {
      mode: 'cors',
      headers: {
        Authorization: this.getAuth(),
      }
    });
    if (isJson) {
      return resp.json();
    }
    return resp.text();
  }
}

export const webhookClient = new WebhookClient();