import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Admin
import {
  Home as AdminHome,
  Referrals as AdminReferrals,
  DeviceApps as AdminOrgDeviceApps,

  ListAdminUsers as AdminListAdminUsers,
  CreateAdminUser as AdminCreateAdminUser,
  ViewAdminUser as AdminViewAdminUser,
  EditAdminUser as AdminEditAdminUser,

  ListOrganisations as AdminOrganisations,
  CreateOrganisation as AdminCreateOrganisation,
  ViewOrganisation as AdminViewOrganisation,
  EditOrganisation as AdminEditOrganisation,

  ListOrganisationUsers as AdminListOrganisationUsers,
  CreateOrganisationUser as AdminCreateOrganisationUser,
  ViewOrganisationUser as AdminViewOrganisationUser,
  EditOrganisationUser as AdminEditOrganisationUser,

  ListLocations as AdminLocations,
  CreateLocation as AdminCreateLocation,
  ViewLocation as AdminViewLocation,
  EditLocation as AdminEditLocation,

  ListLocationUsers as AdminListLocationUsers,
  CreateLocationUser as AdminCreateLocationUser,
  ViewLocationUser as AdminViewLocationUser,
  EditLocationUser as AdminEditLocationUser,

  ListLocationStaff as AdminListLocationStaff,
  CreateLocationStaff as AdminCreateLocationStaff,
  ViewLocationStaff as AdminViewLocationStaff,
  EditLocationStaff as AdminEditLocationStaff,

  ListLocationPrinters as AdminListLocationPrinters,
  CreateLocationPrinters as AdminCreateLocationPrinters,
  EditLocationPrinters as AdminEditLocationPrinters,

  ListLocationVouchers as AdminListLocationVouchers,
  CreateLocationVoucher as AdminCreateLocationVoucher,
  EditLocationVoucher as AdminEditLocationVoucher,

  ViewLocationOrders as AdminViewLocationOrders,

  ViewLocationIntegrations as AdminViewLocationIntegrations,
  EditLocationIntegrations as AdminEditLocationIntegrations,

  ListMenus as AdminListMenus,
  CreateMenu as AdminCreateMenu,
  ViewMenu as AdminViewMenu,
  EditMenu as AdminEditMenu,
  UploadMenu as AdminUploadMenu,

  CreateCategory as AdminCreateCategory,
  ViewCategory as AdminViewCategory,
  EditCategory as AdminEditCategory,

  CreateCategoryItem as AdminCreateCategoryItem,
  EditCategoryItem as AdminEditCategoryItem,

  ListReports as AdminListReports,
  CreateReport as AdminCreateReport,

} from './views/Admin';

// Organisation
import {
  Home as OrgHome,

  ListUsers as OrgListUsers,
  CreateUser as OrgCreateUser,
  ViewUser as OrgViewUser,
  EditUser as OrgEditUser,

  ListLocations as OrgLocations,
  CreateLocation as OrgCreateLocation,
  ViewLocation as OrgViewLocation,
  EditLocation as OrgEditLocation,

  ListLocationUsers as OrgListLocationUsers,
  CreateLocationUser as OrgCreateLocationUser,
  ViewLocationUser as OrgViewLocationUser,
  EditLocationUser as OrgEditLocationUser,

  ListLocationStaff as OrgListLocationStaff,
  CreateLocationStaff as OrgCreateLocationStaff,
  ViewLocationStaff as OrgViewLocationStaff,
  EditLocationStaff as OrgEditLocationStaff,

  ListLocationPrinters as OrgListLocationPrinters,
  CreateLocationPrinters as OrgCreateLocationPrinters,
  EditLocationPrinters as OrgEditLocationPrinters,

  ListLocationVouchers as OrgListLocationVouchers,
  CreateLocationVoucher as OrgCreateLocationVoucher,
  EditLocationVoucher as OrgEditLocationVoucher,

  ViewLocationOrders as OrgViewLocationOrders,
  ListReports as OrgListReports,

  ViewLocationIntegrations as OrgViewLocationIntegrations,
  EditLocationIntegrations as OrgEditLocationIntegrations,

  ListMenus as OrgListMenus,
  CreateMenu as OrgCreateMenu,
  ViewMenu as OrgViewMenu,
  EditMenu as OrgEditMenu,
  UploadMenu as OrgUploadMenu,

  CreateCategory as OrgCreateCategory,
  ViewCategory as OrgViewCategory,
  EditCategory as OrgEditCategory,

  CreateCategoryItem as OrgCreateCategoryItem,
  EditCategoryItem as OrgEditCategoryItem,

} from './views/Organisation';

// Location
import {
  Home as LocHome,

  // ListBookings as LocListBookings,

  ListUsers as LocListUsers,
  CreateUser as LocCreateUser,
  ViewUser as LocViewUser,
  EditUser as LocEditUser,

  ListStaff as LocListStaff,
  CreateStaff as LocCreateStaff,
  ViewStaff as LocViewStaff,
  EditStaff as LocEditStaff,

  ListPrinters as LocListPrinters,
  CreatePrinters as LocCreatePrinters,
  EditPrinters as LocEditPrinters,

  ListVouchers as LocListVouchers,
  CreateVoucher as LocCreateVoucher,
  EditVoucher as LocEditVoucher,

  ViewLocationOrders as LocViewOrders,
  ListReports as LocListReports,

  ListMenus as LocListMenus,
  CreateMenu as LocCreateMenu,
  ViewMenu as LocViewMenu,
  EditMenu as LocEditMenu,
  UploadMenu as LocUploadMenu,

  CreateCategory as LocCreateCategory,
  ViewCategory as LocViewCategory,
  EditCategory as LocEditCategory,

  CreateCategoryItem as LocCreateCategoryItem,
  EditCategoryItem as LocEditCategoryItem,

} from './views/Location';

// Public
import {
  SignIn,
  InitialSignIn,
  Splash,
  ResetPassword,
  Referral,
  XeroSuccess,
  XeroUnsuccessful,
} from './views/Public';

import NotFound from './components/Routing/NotFound';
import NotAuthorised from './components/Routing/NotAuthorised';
import { ProtectedRoleRoute } from './components/Routing';

import { adminRoles, organisationRoles, locationRoles } from './components/AuthProvider';

const Routes = () => {

  return (
    <Switch>

      {/* ---------- public ---------- */}
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path="/initial-sign-in" component={InitialSignIn} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/referral/:referral_name" component={Referral} />
      <Route exact path="/integrations/xero/success" component={XeroSuccess} />
      <Route exact path="/integrations/xero/unsuccessful" component={XeroUnsuccessful} />

      <Route exact path="/" component={Splash} />

      {/* ---------- admin ---------- */}
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/home" component={AdminHome} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/referrals" component={AdminReferrals} />
      
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users" component={AdminListAdminUsers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/create" component={AdminCreateAdminUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/:admin_user_id" component={AdminViewAdminUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/users/:admin_user_id/edit" component={AdminEditAdminUser} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/reports" component={AdminListReports} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/reports/create" component={AdminCreateReport} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/device-apps" component={AdminOrgDeviceApps} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations" component={AdminOrganisations} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/create" component={AdminCreateOrganisation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id" component={AdminViewOrganisation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/edit" component={AdminEditOrganisation} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users" component={AdminListOrganisationUsers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users/create" component={AdminCreateOrganisationUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users/:organisation_user_id" component={AdminViewOrganisationUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/users/:organisation_user_id/edit" component={AdminEditOrganisationUser} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations" component={AdminLocations} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/create" component={AdminCreateLocation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id" component={AdminViewLocation} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/edit" component={AdminEditLocation} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/users" component={AdminListLocationUsers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/users/create" component={AdminCreateLocationUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/users/:location_user_id" component={AdminViewLocationUser} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/users/:location_user_id/edit" component={AdminEditLocationUser} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/staff" component={AdminListLocationStaff} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/staff/create" component={AdminCreateLocationStaff} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/staff/:staff_user_id" component={AdminViewLocationStaff} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/staff/:staff_user_id/edit" component={AdminEditLocationStaff} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/printers" component={AdminListLocationPrinters} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/printers/create" component={AdminCreateLocationPrinters} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/printers/:printer_id/edit" component={AdminEditLocationPrinters} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/vouchers" component={AdminListLocationVouchers} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/vouchers/create" component={AdminCreateLocationVoucher} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/vouchers/:voucher_id/edit" component={AdminEditLocationVoucher} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/orders" component={AdminViewLocationOrders} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/integrations" component={AdminViewLocationIntegrations} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/locations/:location_id/integrations/edit" component={AdminEditLocationIntegrations} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus" component={AdminListMenus} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/create" component={AdminCreateMenu} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/upload" component={AdminUploadMenu} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id" component={AdminViewMenu} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/edit" component={AdminEditMenu} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/create" component={AdminCreateCategory} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id" component={AdminViewCategory} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id/edit" component={AdminEditCategory} />

      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id/items/create" component={AdminCreateCategoryItem} />
      <ProtectedRoleRoute roles={adminRoles} exact path="/admin/organisations/:organisation_id/menus/:menu_id/:category_type/categories/:category_id/items/:item_id/edit" component={AdminEditCategoryItem} />


      {/* ---------- organisation ---------- */}
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/home" component={OrgHome} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/reports" component={OrgListReports} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users" component={OrgListUsers} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users/create" component={OrgCreateUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users/:organisation_user_id" component={OrgViewUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/users/:organisation_user_id/edit" component={OrgEditUser} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations" component={OrgLocations} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/create" component={OrgCreateLocation} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id" component={OrgViewLocation} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/edit" component={OrgEditLocation} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users" component={OrgListLocationUsers} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users/create" component={OrgCreateLocationUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users/:location_user_id" component={OrgViewLocationUser} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/users/:location_user_id/edit" component={OrgEditLocationUser} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff" component={OrgListLocationStaff} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff/create" component={OrgCreateLocationStaff} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff/:staff_user_id" component={OrgViewLocationStaff} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/staff/:staff_user_id/edit" component={OrgEditLocationStaff} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/printers" component={OrgListLocationPrinters} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/printers/create" component={OrgCreateLocationPrinters} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/printers/:printer_id/edit" component={OrgEditLocationPrinters} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/vouchers" component={OrgListLocationVouchers} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/vouchers/create" component={OrgCreateLocationVoucher} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/vouchers/:voucher_id/edit" component={OrgEditLocationVoucher} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/integrations" component={OrgViewLocationIntegrations} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/integrations/edit" component={OrgEditLocationIntegrations} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/locations/:location_id/orders" component={OrgViewLocationOrders} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus" component={OrgListMenus} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/create" component={OrgCreateMenu} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/upload" component={OrgUploadMenu} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id" component={OrgViewMenu} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/edit" component={OrgEditMenu} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/create" component={OrgCreateCategory} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/:category_id" component={OrgViewCategory} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/:category_id/edit" component={OrgEditCategory} />

      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/:category_id/items/create" component={OrgCreateCategoryItem} />
      <ProtectedRoleRoute roles={[...organisationRoles, ...adminRoles]} exact path="/org/menus/:menu_id/:category_type/categories/:category_id/items/:item_id/edit" component={OrgEditCategoryItem} />

      {/* ---------- location ---------- */}
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/home" component={LocHome} />
      <ProtectedRoleRoute roles={[...locationRoles, ...adminRoles]} exact path="/loc/reports" component={LocListReports} />

      {/* <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/bookings" component={LocListBookings} /> */}

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users" component={LocListUsers} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users/create" component={LocCreateUser} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users/:location_user_id" component={LocViewUser} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/users/:location_user_id/edit" component={LocEditUser} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff" component={LocListStaff} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff/create" component={LocCreateStaff} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff/:staff_user_id" component={LocViewStaff} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/staff/:staff_user_id/edit" component={LocEditStaff} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/printers" component={LocListPrinters} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/printers/create" component={LocCreatePrinters} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/printers/:printer_id/edit" component={LocEditPrinters} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/vouchers" component={LocListVouchers} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/vouchers/create" component={LocCreateVoucher} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/vouchers/:voucher_id/edit" component={LocEditVoucher} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/orders" component={LocViewOrders} />
      
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus" component={LocListMenus} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/create" component={LocCreateMenu} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/upload" component={LocUploadMenu} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id" component={LocViewMenu} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id/edit" component={LocEditMenu} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id/:category_type/categories/create" component={LocCreateCategory} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id/:category_type/categories/:category_id" component={LocViewCategory} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id/:category_type/categories/:category_id/edit" component={LocEditCategory} />

      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id/:category_type/categories/:category_id/items/create" component={LocCreateCategoryItem} />
      <ProtectedRoleRoute roles={[...locationRoles, ...organisationRoles, ...adminRoles]} exact path="/loc/menus/:menu_id/:category_type/categories/:category_id/items/:item_id/edit" component={LocEditCategoryItem} />



      <Route exact path="/not-authorised" component={NotAuthorised} />
      <Route exact component={NotFound} />

    </Switch>
  );
};

export default Routes;
