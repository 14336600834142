import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertLocationMutation = gql`
  mutation ($object: locations_insert_input!) {
    insert_locations_one(object: $object) {
      id
    }
  }
`;

export const createLocation = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_locations_one: {
      id: string,
    }
  }>({ mutation: insertLocationMutation!, variables });
  return res.data?.insert_locations_one.id;
}
