import { types, query, params } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _createLocationUserInitQuery = () => ({
  locations: {
    id: types.string,
    name: types.string,
    users: params(
      {
        where: { pin: { _is_null: false } },
      },
      [{
        pin: types.string,
      }],
    ),
  }
});

type X = Unpacked<typeof _createLocationUserInitQuery>
export type Location = X['locations'];

export const createLocationUserInitQuery = () => gql`${query(_createLocationUserInitQuery())}`;
