import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomStepper from '../../../../../../components/CustomStepper';
import { Menu, validationErrors, ValidationErrors, MenuReducerAction, validation } from '../../../Common';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  menu: Menu,
  dispatch: React.Dispatch<{ type: MenuReducerAction, value: any }>,
  handleSave: () => void,
  handleAnother?: () => void,
}

const MenuSteps = ({ classes, completeLabel, menu, dispatch, handleSave, handleAnother }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    description: false,
    availableFrom: false,
    availableTo: false,
    menuType: false,
    incompatibleTimes: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = validation.name(menu.name);
      errorItems.description = validation.name(menu.description);
      errorItems.availableFrom = validation.name(menu.available_from);
      errorItems.availableTo = validation.name(menu.available_to);
      errorItems.menuType = validation.menuType(menu.menu_types);
      errorItems.incompatibleTimes = validation.incompatibleTimes(menu.available_from, menu.available_to);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [menu, errors]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        handleAnother={handleAnother}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            gridSize: 6,
            component:
              <StepDetails
                menu={menu}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Summary',
            gridSize: 6,
            component:
              <StepReview
                menu={menu}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(MenuSteps);
