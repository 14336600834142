import React, { useState, useEffect, useCallback } from 'react';
import { Unpacked } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import { GridCard } from '../../../../components/Grid';

import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { numberToMoney } from '../../../../utils/stringUtils';
import { Grid, Typography } from '@material-ui/core';
import { exactDateString } from '../../../../utils/dateUtils';

const _query = (startDate: string, endDate: string) => ({
  order_served_dish_items: params(
    { 
      where: {
        order: { paid: { _eq: true }, total: { _gt: 0 } },
        _and: [
          { created_at: { _gte: rawString(startDate) } },
          { created_at: { _lte: rawString(endDate) } },
        ]
      } 
    },
    [{
      order: { quick_sale: types.boolean },
      item: types.custom<{ id: string, name: string, price: number }>(),
      modifiers: types.custom<{ originalPrice: number }[]>(),
      price: types.number,
      created_at: types.string,
      served_at: types.string,
    }],
  ),
  order_takeaway_dish_items: params(
    { 
      where: {
        order: { paid: { _eq: true }, total: { _gt: 0 } },
        _and: [
          { created_at: { _gte: rawString(startDate) } },
          { created_at: { _lte: rawString(endDate) } },
        ]
      } 
    },
    [{
      item: types.custom<{ id: string, name: string, price: number }>(),
      modifiers: types.custom<{ originalPrice: number }[]>(),
      price: types.number,
    }],
  ),
  order_deliverect_dish_items: params(
    { 
      where: {
        order: { paid: { _eq: true }, total: { _gt: 0 } },
        _and: [
          { created_at: { _gte: rawString(startDate) } },
          { created_at: { _lte: rawString(endDate) } },
        ]
      } 
    },
    [{
      item: types.custom<{ id: string, name: string, price: number }>(),
      modifiers: types.custom<{ originalPrice: number }[]>(),
      price: types.number,
    }],
  ),
  order_served_drink_items: params(
    { 
      where: {
        order: { paid: { _eq: true }, total: { _gt: 0 } },
        _and: [
          { created_at: { _gte: rawString(startDate) } },
          { created_at: { _lte: rawString(endDate) } },
        ]
      } 
    },
    [{
      order: { quick_sale: types.boolean },
      item: types.custom<{ id: string, name: string, price: number }>(),
      modifiers: types.custom<{ originalPrice: number }[]>(),
      price: types.number,
      created_at: types.string,
      served_at: types.string,
    }],
  ),
  order_takeaway_drink_items: params(
    { 
      where: {
        order: { paid: { _eq: true }, total: { _gt: 0 } },
        _and: [
          { created_at: { _gte: rawString(startDate) } },
          { created_at: { _lte: rawString(endDate) } },
        ]
      } 
    },
    [{
      item: types.custom<{ id: string, name: string, price: number }>(),
      modifiers: types.custom<{ originalPrice: number }[]>(),
      price: types.number,
    }],
  ),
  order_deliverect_drink_items: params(
    { 
      where: {
        order: { paid: { _eq: true }, total: { _gt: 0 } },
        _and: [
          { created_at: { _gte: rawString(startDate) } },
          { created_at: { _lte: rawString(endDate) } },
        ]
      } 
    },
    [{
      item: types.custom<{ id: string, name: string, price: number }>(),
      modifiers: types.custom<{ originalPrice: number }[]>(),
      price: types.number,
    }],
  ),
});

type OrderItem = Unpacked<typeof _query>['order_served_dish_items'][0];

interface ValueItems {
  totalDishes: number,
  totalDrinks: number,
  waitDishes: number,
  waitDrinks: number,
  totalDishesPrice: string,
  totalDrinksPrice: string,
  topDishes: string[],
  topDrinks: string[],
  worstDishes: string[],
  worstDrinks: string[],
}

interface ListItem {
  id: string;
  name: string;
  total: number;
}

interface Props {
  startDate: Date,
  endDate: Date,
  locale: string,
  currency: string,
}

const mergeItems = (orderItems: OrderItem[], items: ListItem[]) => {
  orderItems.forEach((i: OrderItem) => {
    let item = items.find((a: ListItem) => a.id === i.item.id);
    if (!item)  {
      item = { id: i.item.id, name: i.item.name, total: 0 };
      items.push(item);
    }
    item.total++;
  });

  items.sort((a, b) => b.total - a.total);
}

const calculateWait = (orderItems: OrderItem[]): number => {
  const v1 = orderItems.length;
  const v2 = orderItems.reduce((a: number, b: OrderItem) => a + Math.round((((new Date(b.created_at).getTime() - new Date(b.served_at).getTime()) % 86400000) % 3600000) / 60000), 0);
  const v3 = Math.round(v2 / v1);
  return v3 > 0 ? v3 : 0;
}

export const initQuery = (startDate: string, endDate: string) => gql`${query(_query(startDate, endDate))}`;

const MetricDishesDrinks = ({ startDate, endDate, currency, locale }: Props): React.ReactElement => {
  const [value, setValue] = useState<ValueItems>({
    totalDishes: 0,
    totalDrinks: 0,
    waitDishes: 0,
    waitDrinks: 0,
    totalDishesPrice: '',
    totalDrinksPrice: '',
    topDishes: [],
    topDrinks: [],
    worstDishes: [],
    worstDrinks: [],
  });

  const { data, loading } = useQuery(initQuery(exactDateString(startDate), exactDateString(endDate)), { fetchPolicy: 'no-cache' });
  
  const formatMoney = useCallback((v: number) => numberToMoney(v, currency, locale, true), [currency, locale]);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const dishItems: ListItem[] = [];
      const drinkItems: ListItem[] = [];

      const dishes: OrderItem[] = [...data.order_served_dish_items, ...data.order_takeaway_dish_items, ...data.order_deliverect_dish_items];
      const drinks: OrderItem[] = [...data.order_served_drink_items, ...data.order_takeaway_drink_items, ...data.order_deliverect_drink_items];

      mergeItems(dishes, dishItems);
      mergeItems(drinks, drinkItems);

      const totalDishesPrice = dishes.reduce((a: number, b: OrderItem) => a + b.item.price + b.modifiers.reduce((c, d) => c + d.originalPrice, 0), 0);
      const totalDrinksPrice = drinks.reduce((a: number, b: OrderItem) => a + b.item.price + b.modifiers.reduce((c, d) => c + d.originalPrice, 0), 0);

      const waitDishes = calculateWait(data.order_served_dish_items.filter((i: OrderItem) => !i.order.quick_sale && i.served_at !== null));
      const waitDrinks = calculateWait(data.order_served_drink_items.filter((i: OrderItem) => !i.order.quick_sale && i.served_at !== null));

      setValue({
        totalDishes: dishes.length,
        totalDrinks: drinks.length,
        waitDishes,
        waitDrinks,
        totalDishesPrice: formatMoney(totalDishesPrice),
        totalDrinksPrice: formatMoney(totalDrinksPrice),
        topDishes: [...dishItems].splice(0, 5).map((i) => `${i.name} - ${i.total}`),
        topDrinks: [...drinkItems].splice(0, 5).map((i) => `${i.name} - ${i.total}`),
        worstDishes: [...dishItems].reverse().splice(0, 5).map((i) => `${i.name} - ${i.total}`),
        worstDrinks: [...drinkItems].reverse().splice(0, 5).map((i) => `${i.name} - ${i.total}`),
      });
    }
    return () => { mounted = false; };
  }, [data, formatMoney]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h4'>Dishes and Drinks</Typography>
      </Grid>
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="AverageDishWait"
        title="Average dish wait"
        subTitle="Time from order placed to order served in minutes"
        counterLabel='Minutes'
        counterSmall
        counter={value.waitDishes} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="AverageDrinkWait"
        title="Average drink wait"
        subTitle="Time from order placed to order served in minutes"
        counterLabel='Minutes'
        counterSmall
        counter={value.waitDrinks} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="TotalDishSales"
        title="Total dish sales"
        subTitle="All dish item sales excluding discounts"
        counterSmall
        counter={value.totalDishesPrice} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="TotalDrinkSales"
        title="Total drink sales"
        subTitle="All drink item sales excluding discounts"
        counterSmall
        counter={value.totalDrinksPrice} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="TotalDishes"
        title="Total dishes sold"
        subTitle="All dish items sold"
        counterSmall
        counter={value.totalDishes} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="TotalDrinks"
        title="Total drinks sold"
        subTitle="All drink items sold"
        counterSmall
        counter={value.totalDrinks} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="TopDishes"
        title="Top dish sales"
        subTitle="Top dishes sold for all order types"
        counterSmall
        components={value.topDishes.map((i) => <li key={i}>{i}</li>)} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="WorstDishes"
        title="Worst dish sales"
        subTitle="Worst dishes sold for all order types"
        counterSmall
        components={value.worstDishes.map((i) => <li key={i}>{i}</li>)} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="TopDrinks"
        title="Top drink sales"
        subTitle="Top drinks sold for all order types"
        counterSmall
        components={value.topDrinks.map((i) => <li key={i}>{i}</li>)} />
      <GridCard
        fill
        gridSize={4}
        loading={loading}
        key="WorstDrinks"
        title="Worst drink sales"
        subTitle="Worst drinks sold for all order types"
        counterSmall
        components={value.worstDrinks.map((i) => <li key={i}>{i}</li>)} />
    </>
  );
};

export default MetricDishesDrinks;
