import moment from 'moment';
import { DateLike } from '../react-app-env';
import { numberToMoney } from './stringUtils';

export const getDate = (date: DateLike, format: string): string => moment(new Date(date)).format(format || 'dddd Do MMMM YYYY');

export const calculatePercentage = (value: number, percentage: number) => value * percentage;

export const getPercentageOfValue = (value: number, percentage: number) => value - calculatePercentage(value, percentage);

export const getPercentageValueOfTotal = (value: number, percentage: number) => value - (value / (1 + percentage));

export const createPriceTaxString = (price: number, tax: number, currency: string, locale: string, isPennies = false) => {
  const t = isPennies ? tax / 1000 : tax / 10;
  const a = getPercentageValueOfTotal(price, t);
  return numberToMoney(a, currency, locale, isPennies);
}
