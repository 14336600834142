import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _createLocationPrintersInitQuery = (id: string) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        name: types.string,
        menus: [{
          id: types.string,
          name: types.string,
          categories: [{
            id: types.string,
            name: types.string,
            category_type: types.string,
          }],
        }],
      },
      devices: params(
        {
          where: { device_type: { _eq: rawString('PRINTER') } },
        },
        [{
          id: types.string,
          name: types.string,
          device_config: types.custom<{ location: string, categories: { id: string, name: string, category_type: string }[] }>(),
        }],
      ),
    },
  ),
});

type X = Unpacked<typeof _createLocationPrintersInitQuery>
export type Location = X['locations_by_pk'];

export const createLocationPrintersInitQuery = (id: string) => gql`${query(_createLocationPrintersInitQuery(id))}`;
