import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Dashboard from '../../../../layouts/Dashboard';
import CreateVoucherCode from '../../../../components/Global/Vouchers/Create';

import { createVoucherCodeInitQuery, Category, CodeVouchers } from './queries';

interface Props extends RouteComponentProps {

}

const CreateVoucherOrg = ({ history }: Props): React.ReactElement => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [usedCodes, setUsedCodes] = useState<CodeVouchers[]>([]);

  const { data: createMenuInitData } = useQuery(createVoucherCodeInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createMenuInitData) {
      setCategories(createMenuInitData.categories);
      setUsedCodes(createMenuInitData.vouchers_code);
    }
    return () => { mounted = false; };
  }, [createMenuInitData]);

  const handleFinish = () => {
    history.push(`/loc/vouchers`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Vouchers', link: `/loc/vouchers` },
          { label: 'Create' },
        ]}>
        <CreateVoucherCode
          categories={categories}
          used_codes={usedCodes}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(CreateVoucherOrg);
