import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {},
  list: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    padding: theme.spacing(1),
    height: '100%',
  },
  filters: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  toggleButton: {
    height: 40,
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'row',
    height: 80,
  },
  customDatePickers: {
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  customDatePicker: {
    width: 400,
    marginRight: theme.spacing(3),
  },
  listItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

export default styles;
