import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  TextField,
  withStyles,
} from '@material-ui/core';


import Autocomplete from '@material-ui/lab/Autocomplete';

import { LocationUser, ValidationErrors, LocationUserReducerAction, LocationUserType, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  user: LocationUser,
  userTypes: LocationUserType[],
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: LocationUserReducerAction, value: any}>,
}

const StepUserRole = ({ classes, user, userTypes, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'user_type') {
      errorItems.user_type = validation.user_type(user.user_type);
    }
    setErrors({...errors, ...errorItems});
  }, [user, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <Autocomplete
          className={classes.textField}
          id="organisationUserTypeList"
          options={userTypes}
          getOptionLabel={(i: LocationUserType) => i.description}
          value={userTypes.find((i) => i.user_type === user.user_type) || null}
          onBlur={() => validate('user_type')}
          onChange={(_: unknown, newValue: LocationUserType | null) => {
            const value = newValue ? newValue.user_type : '';
            dispatch({ type: LocationUserReducerAction.USER_TYPE, value });
          }}
          renderInput={(params) =>
            <TextField
              data-qa="user-type-input"
              {...params}
              label="User type"
              name="user"
              error={errors.user_type as boolean}
              helperText={errors.user_type} />}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(StepUserRole);
