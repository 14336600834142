import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editLocationInitQuery = (id: string) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    },
  ),
});

type X = Unpacked<typeof _editLocationInitQuery>
export type Location = X['locations_by_pk'];

export const editLocationInitQuery = (id: string) => gql`${query(_editLocationInitQuery(id))}`;
