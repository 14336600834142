import React, { useContext, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Tooltip,
  ClickAwayListener,
  Divider,
  Grow,
  Paper,
  Popper,
  IconButton,
} from '@material-ui/core';

import {
  AppsOutlined as HierarchyIcon,
  CloseOutlined as ClearIcon,
} from '@material-ui/icons';

import { WithStyles } from '@material-ui/core/styles';

import { AppContext } from '../../../../../../components/StateProvider';

import HierarchyList, { Hierarchy } from '../HierarchyList';
import { ListItemRole } from '../SidebarItemsList';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  hierarchy: Hierarchy,
  isExpanded: boolean,
  rootRole?: ListItemRole,
}

const HierarchyMenu = ({ classes, rootRole, history, hierarchy, isExpanded }: Props): React.ReactElement => {
  const { selectedHierarchyItem, selectedHierarchy, setSelectedHierarchy } = useContext(AppContext)!;

  const [isHierarchyMenuOpen, setIsHierarchyMenuOpen] = useState(false);
  const hierarchyAnchorRef = useRef<HTMLButtonElement>(null);

  const handleHierarchyMenuToggle = () => {
    setIsHierarchyMenuOpen(i => true);
  };

  const handleHierarchyMenuClose = () => {
    setIsHierarchyMenuOpen(false);
  };

  const handleClear = () => {
    setSelectedHierarchy(undefined);
    if (rootRole) {
      history.replace(`/${rootRole.key}/home`);
    }
  };

  return (
    <div className={classes.root}>
      <List className={classes.list} component="div" disablePadding>
        <ListItem
          onClick={handleHierarchyMenuToggle}
          className={`${classes.listItem} ${selectedHierarchyItem ? classes.listItemActive : ''}`}>
          {isExpanded && (
            <>
              <ListItemIcon className={classes.listItemIcon} >
                <HierarchyIcon />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText} classes={{ primary: classes.listItemText }}
                primary={selectedHierarchyItem ? selectedHierarchyItem.name : 'Hierarchy'}
                secondary={selectedHierarchy ? selectedHierarchy.route.label : ''}
                ref={hierarchyAnchorRef}
                aria-controls={isHierarchyMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true" />
                {selectedHierarchyItem && (
                  <Tooltip title="Clear" className={classes.clearIcon} arrow>
                    <IconButton size="small" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                )}
            </>
          )}
          {!isExpanded && (
            <>
              <Tooltip
                title={selectedHierarchyItem ? selectedHierarchyItem.name : 'Hierarchy'}
                placement="right"
                ref={hierarchyAnchorRef}
                aria-controls={isHierarchyMenuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true">
                <ListItemIcon className={classes.listItemIcon}>
                  <HierarchyIcon />
                </ListItemIcon>
              </Tooltip>
            </>
          )}
          <Popper
            open={isHierarchyMenuOpen}
            anchorEl={hierarchyAnchorRef.current}
            className={classes.menu}
            role={undefined}
            placement={isExpanded ? 'bottom' : 'right-start'}
            transition
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement }}>
                  <ClickAwayListener onClickAway={handleHierarchyMenuClose}>
                    <Paper variant="outlined" className={classes.paper}>
                      <HierarchyList hierarchy={hierarchy} />
                    </Paper>
                  </ClickAwayListener>
              </Grow>
            )}
          </Popper>
        </ListItem>
      </List>
      <Divider className={classes.listDivider} />
    </div>
  );
};

export default withRouter(withStyles(styles)(HierarchyMenu));
