import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { read, utils } from 'xlsx';

import {
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  DeleteOutline as DeleteIcon,
} from '@material-ui/icons';

import { Menu, ValidationErrors, UploadMenuReducerAction, ZettleUpload, EnumZettleUploadProps, ZettleUploadItem, EnumZettleUploadItemType } from '../..';

import styles from './styles';
import UploadFileContainer from '../../../../../../UploadFileContainer';
import CustomSortableTable, { SortableTableHeader, SortableTableRow } from '../../../../../../CustomSortableTable';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { capitaliseFirstOnly } from '../../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  menu: Menu,
  validate: (step: number) => void,
  errors: ValidationErrors,
  dispatch: React.Dispatch<{ type: UploadMenuReducerAction, value: any }>,
}

const StepUpload = ({ classes, menu, errors, validate, dispatch }: Props): React.ReactElement => {
  const handleFile = (file: string) => {
    const wb = read(file, { type: 'base64' });
    const data = utils.sheet_to_json<ZettleUpload>(wb.Sheets[wb.SheetNames[0]]);

    const items: ZettleUploadItem[] = [];
    data.forEach((i) => {
      if (i[EnumZettleUploadProps.NAME]) {
        const groups = [i[EnumZettleUploadProps.OPTION1_NAME], i[EnumZettleUploadProps.OPTION2_NAME], i[EnumZettleUploadProps.OPTION3_NAME]].filter((i) => i);
        items.push({
          name: capitaliseFirstOnly(i[EnumZettleUploadProps.NAME]),
          price: groups.length > 0 ? 0 : +i[EnumZettleUploadProps.PRICE] * 100,
          tax: +i[EnumZettleUploadProps.VAT] * 10,
          variants: [],
          modifiers: [],
          groups,
          type: EnumZettleUploadItemType.UNKNOWN,
          category: '',
        });
      }
      const item = items[items.length - 1];
      item.variants.push({
        option1: {
          name: i[EnumZettleUploadProps.OPTION1_NAME],
          value: i[EnumZettleUploadProps.OPTION1_VALUE],
        },
        option2: {
          name: i[EnumZettleUploadProps.OPTION2_NAME],
          value: i[EnumZettleUploadProps.OPTION2_VALUE],
        },
        option3: {
          name: i[EnumZettleUploadProps.OPTION3_NAME],
          value: i[EnumZettleUploadProps.OPTION3_VALUE],
        },
        price: i[EnumZettleUploadProps.PRICE] * 100,
      });
    });
    dispatch({ type: UploadMenuReducerAction.FILE_UPLOAD, value: items });
  }

  const createRow = (item: ZettleUploadItem): SortableTableRow => ({
    key: item.name,
    columns: [
      { key: 'name', label: item.name },
      { key: 'variants', label: item.variants.length },
      { key: 'type', label: '', component: (
        <ToggleButtonGroup size='small' value={item.type} exclusive onChange={(_, type: EnumZettleUploadItemType) => {
          dispatch({ type: UploadMenuReducerAction.ITEM_TYPE, value: { type, item } });
          validate(1);
        }}>
          <ToggleButton value={EnumZettleUploadItemType.DISH} disabled={item.type === EnumZettleUploadItemType.DISH}>Dish</ToggleButton>
          <ToggleButton value={EnumZettleUploadItemType.DRINK} disabled={item.type === EnumZettleUploadItemType.DRINK}>Drink</ToggleButton>
        </ToggleButtonGroup>
      )},
    ],
    actions: [
      {
        label: 'Delete',
        endIcon: <DeleteIcon />,
        destructive: true,
        onClick: () => dispatch({ type: UploadMenuReducerAction.ITEM_DELETE, value: item }),
      }
    ],
  });

  const headers: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'variants', label: 'Variants', align: 'center' },
    { key: 'type', label: 'Type' },
  ];

  return (
    <>
      <div className={classes.root}>
        <FormControl className={classes.upload} component="fieldset" error={errors.fileUpload as boolean}>
          <FormLabel component="legend">File Upload</FormLabel>
          <div style={{ marginTop: 8 }}>
            <UploadFileContainer label='Zettle file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' handleUpload={(file) => handleFile(file)} />
          </div>
          <FormHelperText>{errors.fileUpload as string}</FormHelperText>
        </FormControl>
        <Typography className={classes.text} color="error">{errors.itemsNotAssigned}</Typography>
        {menu.items.length > 0 && (
          <>
            {menu.items.filter((i) => i.type === EnumZettleUploadItemType.UNKNOWN).length > 0 && (
              <CustomSortableTable
                title='Items'
                description='Please set each item to either a dish, drink or delete the item'
                orderdBy='name'
                ordered='asc'
                counter='filtered'
                headers={headers}
                rows={menu.items.filter((i) => i.type === EnumZettleUploadItemType.UNKNOWN).map((item: ZettleUploadItem) => createRow(item))}
                />
            )}
            <CustomSortableTable
              title='Dishes'
              orderdBy='name'
              ordered='asc'
              counter='filtered'
              headers={headers}
              rows={menu.items.filter((i) => i.type === EnumZettleUploadItemType.DISH).map((item: ZettleUploadItem) => createRow(item))}
              />
              <CustomSortableTable
                title='Drinks'
                orderdBy='name'
                ordered='asc'
                counter='filtered'
                headers={headers}
                rows={menu.items.filter((i) => i.type === EnumZettleUploadItemType.DRINK).map((item: ZettleUploadItem) => createRow(item))}
                />
          </>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepUpload);
