import React, { useState, useReducer, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { locationReducer, LocationType } from '../Common';
import Steps from '../Common/components/Steps';

import { createLocationInitQuery } from './queries';
import { createLocation } from './mutations';

import styles from './styles';


interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  tax_rate: number,
  handleFinish: (id: string) => void,
}

const CreateLocation = ({ classes, history, organisation_id, tax_rate = 0, handleFinish }: Props): React.ReactElement => {
  const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);
  const [location, dispatchLocation] = useReducer(locationReducer, {
    name: '',
    email: '',
    phone: '',
    website: '',
    tax_number: '',
    number_of_tables: 0,
    location_type: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_country: '',
    address_postcode: '',
    tax_rate: tax_rate,
    currency: '', 
    locale: '',
    can_manage_menus: false,
    has_kitchen_printer: false,
    has_bar_printer: false,
    has_staff: false,
    has_station_printers: false,
    service_charge: null,
    idle_timeout: 30,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: createLocationInitData } = useQuery(createLocationInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createLocationInitData) {
      setLocationTypes(createLocationInitData.enum_location_types);
    }
    return () => { mounted = false; };
  }, [createLocationInitData]);


  const handleSave = async () => {
    setSaving(true);
    const insertLocationVariables = {
      object: {
        organisation_id,
        ...location,
        integrations: {
          data: {
            organisation_id,
            previous_report_at: new Date().toISOString(),
          }
        }
      },
    };
    const location_id: string | undefined = await createLocation(insertLocationVariables);
    if (location_id) {
      setSaving(false);
      handleFinish(location_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        location={location}
        locationTypes={locationTypes}
        dispatch={dispatchLocation}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating Location" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateLocation));
