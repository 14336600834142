import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  price: {
    minWidth: theme.spacing(20),
    maxWidth: theme.spacing(20),
  },
  tax: {
    minWidth: theme.spacing(25),
    maxWidth: theme.spacing(25),
  },
  textField: {
    marginRight: theme.spacing(1),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  delete: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(7),
    height: theme.spacing(7),
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.error.main,
    }
  },
});

export default styles;
