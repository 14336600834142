import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import CreateLocationPrinters from '../../../../components/Global/LocationPrinters/Create';

import { createLocationPrintersInitQuery, Location } from './queries';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const LocCreateLocationPrinters = ({ classes, history }: Props): React.ReactElement => {

  const [location, setLocation] = useState<Location>({ 
    name: '',
    organisation: {
      menus: [],
    },
    devices: [],
  });

  const { data: locationUserInitData } = useQuery(createLocationPrintersInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitData) {
      setLocation(locationUserInitData.locations[0]);
    }
    return () => { mounted = false; };
  }, [locationUserInitData]);

  const handleFinish = () => {
    history.push(`/loc/printers`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Printers', link: `/loc/printers` },
          { label: 'Create' },
        ]}>
        <CreateLocationPrinters
          locations={location.devices.map((d) => d.device_config.location)}
          menus={location.organisation.menus}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(LocCreateLocationPrinters));
