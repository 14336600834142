import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  withStyles,
} from '@material-ui/core';

import ReadOnlyBlock from '../../../../../ReadOnlyBlock';
import { LocationPrinter,  } from '../..';

import styles from './styles';
import { booleanToYesNo } from '../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  printer: LocationPrinter,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, printer, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Name', value: printer.name },
          { label: 'Location', value: printer.location },
          { label: 'Print copy to till', value: booleanToYesNo(printer.printCopyToTill) },
          { label: `Menu Categories - ${printer.categories.length}`, list: printer.categories.map((c) =>c.name) },
        ]}
        handleEdit={() => setStep(0)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
