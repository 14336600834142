import React, { useState, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { locationStaffReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createLocationUser } from './mutations';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  location_id?: string,
  usedPins: string[],
  handleFinish: (id: string) => void,
}

const CreateLocationStaff = ({ classes, history, organisation_id, location_id, usedPins, handleFinish }: Props): React.ReactElement => {

  const [user, dispatchUser] = useReducer(locationStaffReducer, {
    first_name: '',
    last_name: '',
    pin: '',
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: {
        organisation_id,
        location_id,
        user_type: 'WAITER',
        staff: true,
        ...user,
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
      }
    };
    const user_id: string | undefined = await createLocationUser(createVariables);
    if (user_id) {
      setSaving(false);
      handleFinish(user_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        user={user}
        usedPins={usedPins}
        dispatch={dispatchUser}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateLocationStaff));
