import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    width: theme.spacing(48),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
  },
  info: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  passwordDetail: {
    marginTop: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(3),
  },
  logo: {
    width: theme.spacing(30),
    alignSelf: 'center',
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
});

export default styles;
