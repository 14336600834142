import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { capitaliseFirst } from '../../../../../utils/stringUtils';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import ViewCategory from '../../../../../components/Global/Categories/View';

import { selectCategoryInitQuery, Category, Location} from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  menu_id: string,
  category_type: 'dish' | 'drink',
  category_id: string,
}

const ViewCategoryOrg = ({ classes, history }: Props): React.ReactElement => {
  const { menu_id, category_type, category_id } = useParams<UrlParams>();

  const [category, setCategory] = useState<Category>({
    name: '',
    menu: {
      name: '',
    },
  });
  const [location, setLocation] = useState<Location>({
    can_manage_menus: false,
  });

  const { data: categoryInitData } = useQuery(selectCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryInitData) {
      setCategory(categoryInitData.categories_by_pk);
      setLocation(categoryInitData.locations[0]);
    }
    return () => { mounted = false; };
  }, [categoryInitData, category_type]);

  const handleEdit = () => {
    history.push(`/loc/menus/${menu_id}/${category_type}/categories/${category_id}/edit`);
  };

  const handleEditItem = (id: string) => {
    history.push(`/loc/menus/${menu_id}/${category_type}/categories/${category_id}/items/${id}/edit`);
  };

  const handleCreateItem = () => {
    history.push(`/loc/menus/${menu_id}/${category_type}/categories/${category_id}/items/create`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Menus', link: `/loc/menus` },
        { label: category.menu.name, link: `/loc/menus/${menu_id}` },
        { label: `${capitaliseFirst(category_type)} Categories` },
        { label: category.name },
      ]}>
      <ViewCategory
        category_id={category_id}
        category_type={category_type} 
        handleEdit={location.can_manage_menus ? handleEdit : undefined}
        handleEditItem={location.can_manage_menus ? handleEditItem : undefined}
        handleCreateItem={location.can_manage_menus ? handleCreateItem : undefined} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewCategoryOrg));
