import React, { useState, useReducer, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { locationUserReducer, LocationUserType } from '../Common';
import Steps from '../Common/components/Steps';

import { createLocationUserInitQuery } from './queries';
import { createLocationUser } from './mutations';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  location_id?: string,
  pinEnabled: boolean,
  usedPins: string[],
  handleFinish: (id: string) => void,
}

const CreateLocationUser = ({ classes, history, organisation_id, location_id, pinEnabled, usedPins, handleFinish }: Props): React.ReactElement => {
  const [userTypes, setUserTypes] = useState<LocationUserType[]>([]);

  const [user, dispatchUser] = useReducer(locationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    pin: null,
    user_type: null,
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: locationUserInitData } = useQuery(createLocationUserInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitData) {
      setUserTypes(locationUserInitData.enum_user_types);
    }
    return () => { mounted = false; };
  }, [locationUserInitData]);

  const handleSave = async () => {
    setSaving(true);

    const createVariables = {
      object: {
        organisation_id,
        location_id,
        ...user,
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
        email: user.email.trim(),
        pin: pinEnabled ? user.pin : null,
      }
    };
    const user_id: string | undefined = await createLocationUser(createVariables);
    if (user_id) {
      setSaving(false);
      handleFinish(user_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        user={user}
        userTypes={userTypes}
        pinEnabled={pinEnabled}
        usedPins={usedPins}
        dispatch={dispatchUser}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating User" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateLocationUser));
