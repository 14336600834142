
import { query, params, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _organisationsInitQuery = {
  organisations: params(
    { order_by: { name: 'asc' }},
    [{
      id: types.string,
      name: types.string,
      locations: [{
        id: types.string,
        name: types.string,
        device_apps: [{
          device_id: types.string,
          device_type: types.string,
          device_model: types.string,
          app_version: types.string,
          last_login_at: types.string,
          last_login_user: {
            id: types.string,
            full_name: types.string,
          },
          login_total: types.string,
          login_month: types.string,
          created_at: types.string,
        }]
      }]
    }],
  )
};

type X = typeof _organisationsInitQuery
export type Organisation = X['organisations'][0];
export type Location = Organisation['locations'][0];
export type DeviceApp = Location['device_apps'][0];

export const organisationsInitQuery = gql`${query(_organisationsInitQuery)}`;


