import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateCategoryMutation = gql`
  mutation ($pk_columns: categories_pk_columns_input!, $set: categories_set_input!) {
    update_categories_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateCategory = async (variables: object) => {
  return await gqlClient.mutate<{
    update_categories_by_pk: {
      id: string,
    }
  }>({ mutation: updateCategoryMutation!, variables });
}
