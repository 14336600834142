
import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _createLocationInitQuery = () => ({
  organisations: {
      tax_rate: types.number,
    },
});

type X = Unpacked<typeof _createLocationInitQuery>
export type Organisation = X['organisations'];

export const createLocationInitQuery = () => gql`${query(_createLocationInitQuery())}`;
