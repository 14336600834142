import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import { WithStyles } from '@material-ui/core/styles';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  title?: string,
  subtitle?: string,
  listItems: ListItemRoleItem[],
  isExpanded: boolean,
}

export interface ListItemRoles {
  admin: ListItemRole,
  owner: ListItemRole,
  manager: ListItemRole,
}

export interface ListItemRole {
  key: string,
  title: string,
  items: ListItemRoleItem[],
}

export interface ListItemRoleItem {
  label: string,
  icon: React.ReactElement,
  iconActive: React.ReactElement,
  to: string,
}

const SidebarListItems = ({ classes, history, title, subtitle, listItems, isExpanded }: Props): React.ReactElement => {
  return (
    <div className={classes.root}>
      {isExpanded && title && (
        <Typography variant="body1">{title}</Typography>
      )}
      {isExpanded && subtitle && (
        <Typography variant="subtitle2" color="textSecondary">{subtitle}</Typography>
      )}
      <List className={classes.list} component="div" disablePadding>
        {listItems.map((i: ListItemRoleItem) => (
          <ListItem
            key={i.label}
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to={i.to}>
            {isExpanded && (
              <>
                <ListItemIcon className={classes.listItemIcon}>
                  {history.location.pathname.startsWith(i.to) && i.iconActive}
                  {!history.location.pathname.startsWith(i.to) && i.icon}
                </ListItemIcon>
                <ListItemText className={classes.listItemText} classes={{ primary: classes.listItemText }} primary={i.label} />
              </>
            )}
            {!isExpanded && (
              <>
                <Tooltip title={i.label} placement="right">
                  <ListItemIcon className={classes.listItemIcon}>
                    {history.location.pathname.startsWith(i.to) && i.iconActive}
                    {!history.location.pathname.startsWith(i.to) && i.icon}
                  </ListItemIcon>
                </Tooltip>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default withRouter(withStyles(styles)(SidebarListItems));
