import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import UploadMenu from '../../../../components/Global/Menus/Upload';

import { menusInitQuery, Organisation } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const UploadMenuOrg = ({ classes, history }: Props): React.ReactElement => {

  const [organisation, setOrganisation] = useState<Organisation>({
    locale: '',
    currency: '',
  });

  const { data: menusInitData } = useQuery(menusInitQuery());

  useEffect(() => {
    let mounted = true;
    if (mounted && menusInitData) {
      setOrganisation(menusInitData.organisations[0]);
    }
    return () => { mounted = false; };
  }, [menusInitData]);

  const handleFinish = () => {
    history.push(`/loc/menus`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Menus', link: `/loc/menus` },
        { label: 'Upload' },
      ]}>
        <UploadMenu
          locale={organisation.locale}
          currency={organisation.currency}
          handleFinish={handleFinish} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(UploadMenuOrg));
