import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../react-app-env';
import gqlClient from '../../utils/apolloClient';

const _getAdminHierarchyQuery = () => ({
  organisations: [{
    id: types.string,
    name: types.string,
    locations: [{
      id: types.string,
      name: types.string,
    }],
  }],
});

export type AdminHierarchyQuery = Unpacked<typeof _getAdminHierarchyQuery>

export const getAdminHierarchyQuery = async () => {
  const res = await gqlClient.query<AdminHierarchyQuery>({ query: gql`${query(_getAdminHierarchyQuery())}`!});
  return res.data.organisations;
};

const _getOrganisationHierarchyQuery = () => ({
  locations: [{
    id: types.string,
    name: types.string,
  }],
});

export type OrganisationHierarchyQuery = Unpacked<typeof _getOrganisationHierarchyQuery>

export const getOrganisationHierarchyQuery = async () => {
  const res = await gqlClient.query<OrganisationHierarchyQuery>({ query: gql`${query(_getOrganisationHierarchyQuery())}`!});
  return res.data.locations;
};


