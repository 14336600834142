import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';


const categoryNextOrderIndexQuery = gql`
  query ($menu_id: uuid!, $category_type: enum_category_types_enum!) {
    categories_aggregate(where: { menu_id: { _eq: $menu_id }, category_type: { _eq: $category_type } }) {
      aggregate {
        max {
          order_index,
        }
      }
    }
  }
`;

export const getCategoryNextOrderIndex = async (variables: object) => {
  const res=  await gqlClient.query<{
    categories_aggregate: {
      aggregate: {
        max: {
          order_index: number,
        },
      },
    }
  }>({ query: categoryNextOrderIndexQuery!, variables });
  return res.data?.categories_aggregate.aggregate.max.order_index || 0;
}
