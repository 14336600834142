import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertReportMutation = gql`
  mutation ($object: reports_insert_input!) {
    insert_reports_one(object: $object) {
      id
    }
  }
`;

export const createReport = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_reports_one: {
      id: string,
    }
  }>({ mutation: insertReportMutation!, variables });
  return res.data?.insert_reports_one.id;
}
