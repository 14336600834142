import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  withStyles,
} from '@material-ui/core';

import ReadOnlyBlock from '../../../../../ReadOnlyBlock';
import { booleanToYesNo, numberToMoney, secondsToTime } from '../../../../../../utils/stringUtils';
import { createPriceTaxString } from '../../../../../../utils/conversions';
import { Allergy, Modifier, CategoryItem } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  categoryItem: CategoryItem,
  currency: string,
  locale: string,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, categoryItem, currency, locale, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        gridSize={6}
        title={categoryItem.name}
        items={[
          { label: 'Description', value: categoryItem.description },
          { label: 'Active', value: booleanToYesNo(categoryItem.active) },
          { label: 'Price', value: numberToMoney(categoryItem.price, currency, locale, true) },
          { label: 'Tax', value: `${categoryItem.tax / 10}% tax = ${createPriceTaxString(categoryItem.price, categoryItem.tax, currency, locale, true)}` },
          { label: 'Prep time', value: secondsToTime(categoryItem.prep_time) },
          { label: 'Bypass prep', value: booleanToYesNo(categoryItem.bypass_prep) },
          { label: 'Auto serve when ready', value: booleanToYesNo(categoryItem.auto_serve_when_ready) },
        ]}
        handleEdit={() => setStep(0)} />
        <ReadOnlyBlock
          gridSize={6}
          items={[
            { label: 'Modifiers', list: categoryItem.modifiers?.map((i: Modifier) => `${i.name} | ${numberToMoney(i.price, currency, locale, true)} | ${i.tax / 10}% tax = ${createPriceTaxString(i.price, i.tax, currency, locale, true)}`) }
          ]}
          handleEdit={() => setStep(1)} />
          <ReadOnlyBlock
            gridSize={6}
            items={[
              { label: 'Allergies', list: categoryItem.allergies?.map((i: Allergy) => i.name) }
            ]}
            handleEdit={() => setStep(2)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
