import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import { booleanToYesNo } from '../../../../utils/stringUtils';
import ColourPickerItem from '../../../../components/Global/ColourPicker/item';
import ListCategoryItems from '../../../../components/Global/CategoryItem/List';

import { Organisation, Category, CategoryItem, CategoryItemAssignment } from '../Common/types';

import styles from './styles';
import { selectCategoryInitQuery } from './queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  category_id: string,
  category_type: 'dish' | 'drink',
  handleEdit?: () => void,
  handleEditItem?: (id: string) => void,
  handleCreateItem?: () => void,
}

const ViewCategory = ({ classes, organisation_id, category_id, category_type, handleEdit, handleEditItem, handleCreateItem, history }: Props): React.ReactElement => {
  const [category, setCategory] = useState<Category>({
    id: '',
    name: '',
    colour_id: '',
    active: false,
  });
  const [organisation, setOrganisation] = useState<Organisation>({
    id: '',
    name: '',
    currency: '',
    locale: '',
  });
  const [items, setItems] = useState<CategoryItem[]>([]);
  
  const { data: categoryInitData } = useQuery(selectCategoryInitQuery(category_id, category_type), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryInitData) {
      const cat = categoryInitData.categories_by_pk;
      setCategory(cat);
      setOrganisation(cat.organisation);
      const itms = cat[`${category_type}_assignments`].map((i: CategoryItemAssignment) => i[category_type])
      setItems(itms.sort((a: CategoryItem, b: CategoryItem) => a.order_index - b.order_index));
    }
    return () => { mounted = false; };
  }, [categoryInitData, category_type]);


  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item xs={12}>
        <ReadOnlyBlock
          title={category.name}
          gridSize={3}
          items={[
            { label: 'Active', value: booleanToYesNo(category.active) },
            { label: 'Colour', components: [<ColourPickerItem key="colour_picker" small colour_id={category.colour_id} />]  },
          ]}
          handleEdit={handleEdit} />
      </Grid>
      <Grid item xs={12}>
        <ListCategoryItems
          organisation_id={organisation_id}
          category_type={category_type}
          currency={organisation.currency}
          locale={organisation.locale}
          items={items}
          handleSetItems={setItems}
          handleEditItem={handleEditItem}
          handleCreateItem={handleCreateItem} />
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewCategory));
