
import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

export default styles;
