import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../react-app-env';
import gqlClient from '../../utils/apolloClient';

const _getOrganisationQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
    },
  ),
});

export type OrganisationQuery = Unpacked<typeof _getOrganisationQuery>;

export const getOrganisationQuery = async (id: string) => {
  const res = await gqlClient.query<OrganisationQuery>({ query: gql`${query(_getOrganisationQuery(id))}`!});
  return res.data.organisations_by_pk;
};

const _getLocationQuery = (id: string) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      id: types.string,
      name: types.string,
    },
  ),
});

export type LocationQuery = Unpacked<typeof _getLocationQuery>;

export const getLocationQuery = async (id: string) => {
  const res = await gqlClient.query<LocationQuery>({ query: gql`${query(_getLocationQuery(id))}`!});
  return res.data.locations_by_pk;
};
