import React from 'react';
import { WithStyles } from '@material-ui/core/styles';

import { booleanToYesNo, capitaliseFirst } from '../../../../../../utils/stringUtils';

import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  Chip,
  withStyles,
} from '@material-ui/core';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Location, LocationType } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  location: Location,
  locationTypes: LocationType[],
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, location, locationTypes, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        title={location.name}
        gridSize={6}
        items={[
          { label: 'Location type', value: locationTypes.find((i) => i.location_type === location.location_type)?.description },
          { label: 'Email', value: location.email },
          { label: 'Phone', value: location.phone },
          { label: 'Website', value: location.website || 'No website provided' },
        ]}
        handleEdit={() => setStep(0)} />
      <ReadOnlyBlock
        items={[
          { label: 'Address', list: [
            location.address_line_1,
            location.address_line_2,
            location.address_city,
            location.address_country,
            location.address_postcode,
          ]},
        ]}
        handleEdit={() => setStep(1)} />
      <ReadOnlyBlock
        gridSize={6}
        items={[
          { label: 'Tax number', value: `${location.tax_number}` },
          { label: 'Currency', value: `${location.currency}` },
          { label: 'Locale', value: `${location.locale}` },
          { label: 'Number of tables', value: `${location.number_of_tables}` },
          { label: 'Can manage menus', value: booleanToYesNo(location.can_manage_menus) },
          { label: 'Has a kitchen tablet', value: booleanToYesNo(location.has_kitchen_printer) },
          { label: 'Has a bar tablet', value: booleanToYesNo(location.has_bar_printer) },
          { label: 'Staff enabled (PIN access)', value: booleanToYesNo(location.has_staff) },
          location.has_staff ? { label: 'Idle timeout', value: `${location.idle_timeout} seconds`} : null,
        ]}
        handleEdit={() => setStep(2)} />
      <ReadOnlyBlock
        title="Service charge"
        gridSize={6}
        items={location.service_charge ? [
          { label: 'Order types', components: location.service_charge.order_types!.map((m: string) => (
            <Chip
              key={m}
              className={classes.chip}
              size="small"
              variant="outlined"
              label={capitaliseFirst(m.toLowerCase().replace(/_/g, ' '))} />))
          },
          { label: 'Percentage', value: `${location.service_charge.percentage}%` },
          { label: 'Minimum covers', value: `${location.service_charge.min_covers}` },
        ] : [
          { label: 'Service charge is not activated' },
        ]}
        handleEdit={() => setStep(3)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
