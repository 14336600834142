import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import LocationOrders from '../../../../components/Global/LocationOrders';

import { selectLocationOrdersInitQuery, Location} from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  location_id: string,
}

const ViewLocationOrders = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
  });

  const { data: locationOrdersInitData } = useQuery(selectLocationOrdersInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationOrdersInitData) {
      setLocation(locationOrdersInitData.locations_by_pk);
    }
    return () => { mounted = false; };
  }, [locationOrdersInitData]);

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Locations', link: `/org/locations` },
        { label: location.name, link: `/org/locations/${location_id}` },
        { label: 'Orders' },
      ]}>
      <LocationOrders location_id={location_id} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewLocationOrders));
