import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const getColoursQuery = gql`
  query {
    colours {
      id,
    },
  }
`;

export const getColours = async () => {
  const res=  await gqlClient.query<{
    colours: {
      id: string,
    }[],
  }>({ query: getColoursQuery! });
  return res.data?.colours;
}


const menuNextOrderIndexQuery = gql`
  query {
    menus_aggregate {
      aggregate {
        max {
          order_index,
        }
      }
    }
  }
`;

export const getMenuNextOrderIndex = async () => {
  const res=  await gqlClient.query<{
    menus_aggregate: {
      aggregate: {
        max: {
          order_index: number,
        },
      },
    }
  }>({ query: menuNextOrderIndexQuery! });
  return res.data?.menus_aggregate.aggregate.max.order_index || 0;
}

const menuOrgNextOrderIndexQuery = gql`
  query ($organisation_id: uuid!) {
    menus_aggregate(where: { organisation_id: { _eq: $organisation_id } }) {
      aggregate {
        max {
          order_index,
        }
      }
    }
  }
`;

export const getOrgMenuNextOrderIndex = async (variables: object) => {
  const res=  await gqlClient.query<{
    menus_aggregate: {
      aggregate: {
        max: {
          order_index: number,
        },
      },
    }
  }>({ query: menuOrgNextOrderIndexQuery!, variables });
  return res.data?.menus_aggregate.aggregate.max.order_index || 0;
}
