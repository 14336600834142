
import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _createLocationInitQuery = () => ({
  enum_location_types: [{
    location_type: types.string,
    description: types.string,
  }],
});

export const createLocationInitQuery = () => gql`${query(_createLocationInitQuery())}`;
