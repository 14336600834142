import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {},
  chip: {
    marginRight: theme.spacing(1),
  }
});

export default styles;
