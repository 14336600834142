import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import { GridCard, GridActionCard } from '../../../../components/Grid';

import { organisationsInitQuery, Organisation } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const ListOrganisations = ({ classes, history }: Props): React.ReactElement => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);

  const { data: organisationsInitData } = useQuery(organisationsInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && organisationsInitData) {
      setOrganisations(organisationsInitData.organisations);
    }
    return () => { mounted = false; };
  }, [organisationsInitData]);

  const handleCreate = () => {
    history.push('/admin/organisations/create');
  };

  const handleEdit = (id: string) => {
    history.push(`/admin/organisations/${id}/edit`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/organisations/${id}`);
  };

  return (
    <Dashboard title="Organisations">
      <Grid container spacing={4} alignItems="stretch">
        <GridActionCard onClick={handleCreate} fill />
        {organisations.map((organisation: Organisation) => (
          <GridCard
            fill
            key={organisation.id}
            title={organisation.name}
            counter={organisation.locations_aggregate.aggregate.count}
            counterLabel="Locations"
            handleEdit={() => handleEdit(organisation.id)}
            handleView={() => handleView(organisation.id)} />
          ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListOrganisations));
