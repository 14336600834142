import React, { useState, useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { getDaysPast, getMonthsPast, getStartOfWeek } from '../../../../../utils/dateUtils';

import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';

import { ReportMenuItemSales, ReportDailyTotals } from '../../../../../components/Global/CustomReports/Reports';
import { ReportData } from '../../../../../components/Global/CustomReports/Common';
import Metrics from '../../../../../components/Global/CustomReports/Metrics/metrics';

import styles from './styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import CustomDatePicker from '../../../../../components/CustomDatePicker';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

interface CustomDates {
  startDate: Date,
  endDate: Date,
  dateType: EnumCustomDateType,
  errors: CustomDatesErrors,
}

interface CustomDatesErrors {
  startDate: string,
  endDate: string,
}

enum EnumCustomDateType {
  ONE = 'ONE',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  THREE_MONTH = '3_MONTH',
  CUSTOM = 'CUSTOM',
}

const Reports = ({ classes }: Props): React.ReactElement => {
  const [report, setReport] = useState<ReportData | null>(null);
  const [namedReport, setNamedReport] = useState<string | null>(null);

  const [customDates, setCustomDates] = useState<CustomDates>({
    startDate: new Date(), 
    endDate: new Date(), 
    dateType: EnumCustomDateType.ONE, 
    errors: {
      startDate: '',
      endDate: '',
    }
  });

  const getStartDate = useCallback((dateType?: EnumCustomDateType): Date => {
    let date: Date = new Date();
    switch (dateType) {
      case EnumCustomDateType.ONE:
        date = getDaysPast(0);
        break;
      case EnumCustomDateType.WEEK:
        date = getStartOfWeek(0);
        break;
      case EnumCustomDateType.MONTH:
        date = getMonthsPast(1);
        break;
      case EnumCustomDateType.THREE_MONTH:
        date = getMonthsPast(3);
        break;
      case EnumCustomDateType.CUSTOM:
        date = customDates.startDate;
        break;
      default:
        break;
    }
    date.setHours(0,0,0);
    return date;
  }, [customDates]);

  const getEndDate = useCallback((dateType?: EnumCustomDateType): Date => {
    let date: Date = new Date();
    if ((dateType || customDates.dateType) === EnumCustomDateType.CUSTOM) {
      date = customDates.endDate;
    }
    date.setHours(23,59,59);
    return date;
  }, [customDates]);

  const validateCustomDate = (data: CustomDates) : CustomDates => {
    const value: CustomDates = { ...data };
    if (value.dateType === EnumCustomDateType.CUSTOM) {
      if (customDates.startDate > customDates.endDate) {
        value.errors.startDate = 'Start date must be before end date';
      }
    }
    return value;
  };

  const _updateDates = (dateType: EnumCustomDateType) => {
    const value: CustomDates = {
      startDate: getStartDate(dateType),
      endDate: getEndDate(dateType),
      dateType,
      errors: {
        startDate: '',
        endDate: '',
      }
    };
    setCustomDates(validateCustomDate(value));
  }

  const historyTypes = [
    { key: EnumCustomDateType.ONE, label: 'Today' },
    { key: EnumCustomDateType.WEEK, label: 'This week' },
    { key: EnumCustomDateType.MONTH, label: 'This Month' },
    { key: EnumCustomDateType.THREE_MONTH, label: 'Last 3 Months' },
    { key: EnumCustomDateType.CUSTOM, label: 'Custom' },
  ];

  const currency = 'GBP';
  const locale = 'en-gb';

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.filters}>
          <div className={classes.datePicker}>
            <ToggleButtonGroup
              size="small"
              value={customDates.dateType}
              exclusive
              color="primary"
              onChange={(_: unknown, value: string | null) => _updateDates(value as EnumCustomDateType || EnumCustomDateType.ONE)}>
              {historyTypes.map((i) => (
                <ToggleButton
                  className={classes.toggleButton}
                  key={i.key}
                  value={i.key}>
                    {i.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            {customDates.dateType === 'CUSTOM' && (
              <div className={classes.customDatePickers}>
                <div className={classes.customDatePicker}>
                  <CustomDatePicker
                    small
                    label="Start date"
                    date={customDates.startDate}
                    error={customDates.errors.startDate !== ''}
                    allowPastDates
                    helperText={customDates.errors.startDate !== '' ? customDates.errors.startDate as string : undefined}
                    yearsFromNow={(2021 - new Date().getFullYear())}
                    handleDateChange={(startDate: Date) => setCustomDates((d) => ({ ...d, startDate }))} />
                </div>
                <div className={classes.customDatePicker}>
                  <CustomDatePicker
                    small
                    label="End date"
                    date={customDates.endDate}
                    error={customDates.errors.endDate !== ''}
                    allowPastDates
                    helperText={customDates.errors.endDate !== '' ? customDates.errors.endDate as string : undefined}
                    yearsFromNow={2021 - new Date().getFullYear()}
                    handleDateChange={(endDate: Date) => setCustomDates((d) => ({ ...d, endDate }))} />
                </div>
              </div>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={1}>
        <List
          className={classes.list}
          component="nav">
          <ListItem className={classes.listItem} key="metrics" button onClick={() => {setReport(null); setNamedReport(null)}} selected={report === null && namedReport === null}>
            <ListItemText primary="Metrics" />
          </ListItem>
          <Divider />
          <ListItem className={classes.listItem} key="items_plu" button onClick={() => setNamedReport('MENU_ITEM_SALES')} selected={namedReport === 'MENU_ITEM_SALES'}>
            <ListItemText primary="Item (PLU)" />
          </ListItem>
          <ListItem className={classes.listItem} key="order_by_day" button onClick={() => setNamedReport('DAILY_TOTALS')} selected={namedReport === 'DAILY_TOTALS'}>
            <ListItemText primary="Daily Totals" />
          </ListItem>
          {/* {reports.map((r) => (
            <ListItem className={classes.listItem} key={r.id} button onClick={() => setReport(r.schema as ReportData)} selected={report !== null && report.name === (r.schema as ReportData).name}>
              <ListItemText primary={r.name} />
            </ListItem>
          ))} */}
        </List>
      </Grid>
      {/* {report && (
        <Grid item xs={10}>
          <CustomReportTable data={report} />
          <CustomReportChart type="bar" data={report} />
        </Grid>
      )} */}
      {namedReport && (
        <>
          <Grid item xs={11}>
            {namedReport === 'MENU_ITEM_SALES' && <ReportMenuItemSales startDate={customDates.startDate} endDate={customDates.endDate} locale={locale} currency={currency} />}
            {namedReport === 'DAILY_TOTALS' && <ReportDailyTotals startDate={customDates.startDate} endDate={customDates.endDate} locale={locale} currency={currency} />}
          </Grid>
        </>
      )}
      {!report && !namedReport && (
        <Grid item xs={11}>
          <Metrics startDate={customDates.startDate} endDate={customDates.endDate} locale={locale} currency={currency} />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(Reports);
