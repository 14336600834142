import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateDishMutation = gql`
  mutation ($pk_columns: dishes_pk_columns_input!, $set: dishes_set_input!) {
    update_dishes_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateDish = async (variables: object) => {
  return await gqlClient.mutate<{
    update_dishes_by_pk: {
      id: string,
    }
  }>({ mutation: updateDishMutation!, variables });
}

const updateDrinkMutation = gql`
  mutation ($pk_columns: drinks_pk_columns_input!, $set: drinks_set_input!) {
    update_drinks_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateDrink = async (variables: object) => {
  return await gqlClient.mutate<{
    update_drinks_by_pk: {
      id: string,
    }
  }>({ mutation: updateDrinkMutation!, variables });
}

const updateDishItemModifierAssignmentMutation = gql`
  mutation ($assignments: [dish_modifier_assignments_insert_input!]!, $edits: [dish_modifiers_insert_input!]!, $remove: [uuid!]!) {
    insert_dish_modifier_assignments(objects: $assignments) {
      returning {
        id
      }
    }
    insert_dish_modifiers(
      objects: $edits, 
      on_conflict: { 
        constraint: dish_modifiers_pkey,
        update_columns: [name, price, tax] 
      }
    ) {
      affected_rows
    }
    delete_dish_modifier_assignments(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const updateDishItemModifierAssignments = async (assignments: object[], edits: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    insert_dish_modifier_assignments: {
      returning: [{
        id: string,
      }],
    },
    insert_dish_modifiers: {
      affected_rows: number,
    },
    delete_dish_modifier_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateDishItemModifierAssignmentMutation!, variables: { assignments, edits, remove } });
}

const updateDrinkItemModifierAssignmentMutation = gql`
  mutation ($assignments: [drink_modifier_assignments_insert_input!]!, $edits: [drink_modifiers_insert_input!]!, $remove: [uuid!]!) {
    insert_drink_modifier_assignments(objects: $assignments) {
      returning {
        id
      }
    }
    insert_drink_modifiers(
      objects: $edits, 
      on_conflict: { 
        constraint: drink_modifiers_pkey,
        update_columns: [name, price, tax] 
      }
    ) {
      returning {
      	id
      }
    }
    delete_drink_modifier_assignments(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const updateDrinkItemModifierAssignments = async (assignments: object[], edits: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    insert_drink_modifier_assignments: {
      returning: [{
        id: string,
      }],
    }
    insert_drink_modifiers: {
      affected_rows: number,
    },
    delete_drink_modifier_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateDrinkItemModifierAssignmentMutation!, variables: { assignments, edits, remove } });
}

const updateDishItemAllergyAssignmentMutation = gql`
  mutation ($assignments: [dish_allergy_assignments_insert_input!]!, $remove: [uuid!]!) {
    insert_dish_allergy_assignments(objects: $assignments) {
      returning {
        id
      }
    }
    delete_dish_allergy_assignments(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const updateDishItemAllergyAssignments = async (assignments: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    insert_dish_allergy_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_dish_allergy_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateDishItemAllergyAssignmentMutation!, variables: { assignments, remove } });
}

const updateDrinkItemAllergyAssignmentMutation = gql`
  mutation ($assignments: [drink_allergy_assignments_insert_input!]!, $remove: [uuid!]!) {
    insert_drink_allergy_assignments(objects: $assignments) {
      returning {
        id
      }
    }
    delete_drink_allergy_assignments(where: {id: {_in: $remove}}) {
      affected_rows
    }
  }
`;

export const updateDrinkItemAllergyAssignments = async (assignments: object[], remove: string[]) => {
  return await gqlClient.mutate<{
    insert_drink_allergy_assignments: {
      returning: [{
        id: string,
      }],
    }
    delete_drink_allergy_assignments: {
      affected_rows: number
    }
  }>({ mutation: updateDrinkItemAllergyAssignmentMutation!, variables: { assignments, remove } });
}
