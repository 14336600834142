import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationUsersInitQuery = (location_id: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    {
      users: params(
        {
          where: { staff: { _eq: false } }, 
        },
        [{
          id: types.string,
          full_name: types.string,
          email: types.string,
          user_type: types.string,
          pin: types.string,
          enum_user_type: {
            user_type: types.string,
            description: types.string,
          },
        }],
      ),
    }
  ),
  enum_user_types: [{
    user_type: types.string,
    description: types.string,
  }],
});

type X = Unpacked<typeof _selectLocationUsersInitQuery>
export type Location = X['locations_by_pk'];
export type LocationUser = Location['users'][0];
export type EnumUserType = X['enum_user_types'][0];

export const selectLocationUsersInitQuery = (location_id: string) => gql`${query(_selectLocationUsersInitQuery(location_id))}`;
