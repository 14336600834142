
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { ServiceCharge } from '../Common';

const _editLocationInitQuery = (id: string) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      email: types.string,
      phone: types.string,
      website: types.string,
      tax_number: types.string,
      currency: types.string,
      locale: types.string,
      number_of_tables: types.number,
      location_type: types.string,
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_country: types.string,
      address_postcode: types.string,
      can_manage_menus: types.boolean,
      has_kitchen_printer: types.boolean,
      has_bar_printer: types.boolean,
      has_staff: types.boolean,
      has_station_printers: types.boolean,
      service_charge: types.custom<ServiceCharge | null>(),
      idle_timeout: types.number,
    },
  ),
  enum_location_types: [{
    location_type: types.string,
    description: types.string,
  }],
});

export const editLocationInitQuery = (id: string) => gql`${query(_editLocationInitQuery(id))}`;
