import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const _updateDishOrderIndexMutation = gql`
  mutation ($objects: [dishes_insert_input!]!) {
    insert_dishes(
      objects: $objects, 
      on_conflict:{ 
        constraint: dishes_pkey, 
        update_columns: [order_index]
        }
      ) {
      affected_rows
    }
  }
`;

export const updateDishOrderIndexMutation = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_dishes: {
      affected_rows: string,
    }
  }>({ mutation: _updateDishOrderIndexMutation!, variables });
}

const _updateDrinkOrderIndexMutation = gql`
  mutation ($objects: [drinks_insert_input!]!) {
    insert_drinks(
      objects: $objects, 
      on_conflict:{ 
        constraint: drinks_pkey, 
        update_columns: [order_index]
        }
      ) {
      affected_rows
    }
  }
`;

export const updateDrinkOrderIndexMutation = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_drinks: {
      affected_rows: string,
    }
  }>({ mutation: _updateDrinkOrderIndexMutation!, variables });
}
