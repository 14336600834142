import { capitaliseFirstOnly, numbersOnly } from "../../../../utils/stringUtils";
import { ValidationType } from '../../../../react-app-env';

export interface Organisation {
  name: string,
  email: string,
  phone: string,
  organisation_type: string,
  currency: string,
  locale: string,
  tax_rate: number,
}

export interface ValidationErrors {
  name: ValidationType,
  email: ValidationType,
  phone: ValidationType,
  organisation_type: ValidationType,
  currency: ValidationType,
  locale: ValidationType,
  tax_rate: ValidationType,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  email: 'Please provide an email address',
  phone: 'Please provide a phone number',
  organisation_type: 'Please select an organisation type',
  currency: 'Please provide a currency',
  locale: 'Please provide a locale',
  tax_rate: 'Please provide a tax rate',
}

export interface Validation {
  name: (name: string) => ValidationType,
  email: (email: string) => ValidationType,
  phone: (phone: string) => ValidationType,
  organisation_type: (organisation_type: string) => ValidationType,
  currency: (currency: string) => ValidationType,
  locale: (locale: string) => ValidationType,
  tax_rate: (tax_rate: number) => ValidationType,
}

export const validation: Validation = {
  name: (name) => !name ? validationErrors.name : false,
  email: (email) => !email ? validationErrors.email : false,
  phone: (phone) => !phone ? validationErrors.phone : false,
  organisation_type: (organisation_type) => !organisation_type ? validationErrors.organisation_type : false,
  currency: (currency) => !currency ? validationErrors.currency : false,
  locale: (locale) => !locale ? validationErrors.locale : false,
  tax_rate: (tax_rate) => !tax_rate ? validationErrors.tax_rate : false,
}

export enum OrganisationReducerAction {
  NAME,
  EMAIL,
  PHONE,
  ORGANISATION_TYPE,
  CURRENCY,
  LOCALE,
  TAX_RATE,
  INIT,
}

export const organisationReducer = (state: Organisation, action: { type: OrganisationReducerAction, value: any }): Organisation =>  {
  switch (action.type) {
    case OrganisationReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case OrganisationReducerAction.EMAIL:
      return { ...state, email: action.value.toLowerCase() };
    case OrganisationReducerAction.PHONE:
      return { ...state, phone: `${numbersOnly(action.value)}` };
    case OrganisationReducerAction.ORGANISATION_TYPE:
      return { ...state, organisation_type: action.value };
    case OrganisationReducerAction.CURRENCY:
      return { ...state, currency: action.value };
    case OrganisationReducerAction.LOCALE:
      return { ...state, locale: action.value };
      case OrganisationReducerAction.TAX_RATE:
        return { ...state, tax_rate: +action.value * 10 };
    case OrganisationReducerAction.INIT:
      const { name, email, phone, organisation_type, currency, locale, tax_rate } = action.value;
      const obj = {
        name,
        email,
        phone,
        organisation_type,
        currency,
        locale,
        tax_rate,
      };
      return { ...obj as Organisation };
    default:
      throw new Error();
  }
}
