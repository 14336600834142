import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';

import { Report, ValidationErrors, validationErrors, ReportReducerAction } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  report: Report,
  tables: { key: string, label: string }[],
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: ReportReducerAction, value: any }>,
}

const StepDetails = ({ classes, report, tables, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'name') {
      errorItems.name = !report.name ? validationErrors.name : false;
    }
    if (field === 'table') {
      errorItems.table = !report.table ? validationErrors.table : false;
    }
    setErrors({...errors, ...errorItems});
  }, [report, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={report.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ReportReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}/>

        <FormControl variant="outlined" className={classes.textField} error={errors.table as boolean}>
          <InputLabel id="table-select-label">Table</InputLabel>
          <Select
            labelId="table-select-label"
            label="Table"
            autoWidth
            value={report.table}
            onChange={(event: React.ChangeEvent<any>) => dispatch({ type: ReportReducerAction.TABLE, value: event.target.value })}
            onBlur={() => validate('table')} >
            {tables.map((i) => <MenuItem key={i.key} value={i.key}>{i.label}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
