import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  withStyles,
} from '@material-ui/core';

import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { Report } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  report: Report,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, report, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        items={[
          { label: 'Report name', value: report.name },
          { label: 'Email', value: report.table },
        ]}
        handleEdit={() => setStep(0)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
