import React, { useEffect, useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { Allergy, AllergyAssignment, categoryItemReducer, CategoryItemReducerAction, Modifier, ModifierAssignment } from '../Common';
import Steps from '../Common/components/Steps';

import { 
  updateDish,
  updateDishItemAllergyAssignments,
  updateDishItemModifierAssignments,
  updateDrink,
  updateDrinkItemAllergyAssignments,
  updateDrinkItemModifierAssignments,
} from './mutations';

import { editCategoryItemInitQuery } from './queries';

import styles from './styles';
import { pluralise } from '../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  item_id: string,
  category_id: string,
  category_type: string,
  currency: string,
  locale: string,
  taxRate: number,
  organisation_id?: string,
  handleFinish: (id: string) => void,
}

const EditCategoryItem = ({ classes, item_id, category_id, category_type, currency, locale, taxRate, organisation_id, handleFinish, history }: Props): React.ReactElement => {
  const [categoryItem, dispatchCategoryItem] = useReducer(categoryItemReducer, {
    name: '',
    description: '',
    active: true,
    tag: '',
    price: 0,
    tax: taxRate,
    bypass_prep: false,
    should_print: true,
    auto_serve_when_ready: false,
    prep_time: 0,
    modifiers: [],
    allergies: [],
  });

  const [originalModifierAssignments, setOriginalModifierAssignments] = useState<ModifierAssignment[]>([]);
  const [originalAllergyAssignments, setOriginalAllergyAssignments] = useState<AllergyAssignment[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editCategoryItemInitData } = useQuery(editCategoryItemInitQuery(item_id, category_type), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editCategoryItemInitData) {
      const itemType = pluralise(category_type, 2);
      const catItem = editCategoryItemInitData[`${itemType}_by_pk`];
      dispatchCategoryItem({ type: CategoryItemReducerAction.INIT, value: catItem });

      setOriginalModifierAssignments(catItem.modifier_assignments);
      setOriginalAllergyAssignments(catItem.allergy_assignments);

    }
    return () => { mounted = false; };
  }, [editCategoryItemInitData, category_type, setOriginalModifierAssignments, setOriginalAllergyAssignments]);

  const handleSave = async () => {
    setSaving(true);

    const item: UnknownObject = {
      ...categoryItem,
    };

    delete item.modifiers;
    delete item.allergies;

    const mData: UnknownObject = {
      ...item,
      organisation_id,
    };

    const updateCategoryItemVariables = {
      pk_columns: {
        id: item_id,
      },
      set: mData,
    };


    const editAssignedModifiers = originalModifierAssignments;
    let editedModifiers: UnknownObject[] = categoryItem.modifiers
      .filter((mod: Modifier) => editAssignedModifiers.some((i: ModifierAssignment) => mod.id === i.modifier.id))
      .map((i: Modifier) => ({ ...i, organisation_id }));

    const assignedModifiers = originalModifierAssignments;
    let modifierAssignments: UnknownObject[] = categoryItem.modifiers
      .filter((mod: Modifier) => !assignedModifiers.some((i: ModifierAssignment) => mod.id === i.modifier.id))
      .map((mod: Modifier) => ({ 
        modifier: { 
          data: { 
            name: mod.name, 
            price: mod.price, 
            tax: mod.tax, 
            organisation_id,
          },
        },
      }));

    const modifierAssignmentsRemove = assignedModifiers
      .filter((i: ModifierAssignment) => !categoryItem.modifiers.some((mod: Modifier) => mod.id === i.modifier.id))
      .map((i: ModifierAssignment) => i.id);

    const assignedAllergies = originalAllergyAssignments;
    let allergyAssignments: UnknownObject[] = categoryItem.allergies
      .filter((al: Allergy) => !assignedAllergies.some((i: AllergyAssignment) => al.id === i.allergy.id))
      .map((al: Allergy) => ({ allergy_id: al.id, organisation_id }));
    
      const allergyAssignmentsRemove = assignedAllergies
      .filter((i: AllergyAssignment) => !categoryItem.allergies.some((al: Allergy) => al.id === i.allergy.id))
      .map((i: AllergyAssignment) => i.id);

    modifierAssignments = modifierAssignments.map((i: UnknownObject) => ({
      [`${category_type}_id`]: item_id,
      organisation_id,
      ...i,
    }));
    allergyAssignments = allergyAssignments.map((i: UnknownObject) => ({
      [`${category_type}_id`]: item_id,
      organisation_id,
      ...i,
    }));

    if (category_type === 'dish') {
      await updateDish(updateCategoryItemVariables);
      await updateDishItemModifierAssignments(modifierAssignments, editedModifiers, modifierAssignmentsRemove);
      await updateDishItemAllergyAssignments(allergyAssignments, allergyAssignmentsRemove);
    }
    if (category_type === 'drink') {
      await updateDrink(updateCategoryItemVariables);
      await updateDrinkItemModifierAssignments(modifierAssignments, editedModifiers, modifierAssignmentsRemove);
      await updateDrinkItemAllergyAssignments(allergyAssignments, allergyAssignmentsRemove);
    }

    setSaving(false);
    handleFinish(item_id);
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        categoryItem={categoryItem}
        currency={currency}
        locale={locale}
        taxRate={taxRate}
        category_id={category_id}
        category_type={category_type}
        dispatch={dispatchCategoryItem}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditCategoryItem));
