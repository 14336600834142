import React, { useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { Allergy, categoryItemReducer, CategoryItemReducerAction, Modifier } from '../Common';
import Steps from '../Common/components/Steps';

import { createDish, createDrink } from './mutations';
import { getCategoryDishNextOrderIndex, getCategoryDrinkNextOrderIndex } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  category_id: string,
  category_type: string,
  currency: string,
  locale: string,
  taxRate: number,
  organisation_id?: string,
  handleFinish: (id: string) => void,
}

const CreateCategoryItem = ({ classes, history, category_id, category_type, currency, locale, taxRate, organisation_id, handleFinish }: Props): React.ReactElement => {
  const defaultData = {
    name: '',
    description: '',
    active: true,
    tag: '',
    price: 0,
    tax: taxRate || 0,
    prep_time: 0,
    bypass_prep: true,
    should_print: true,
    auto_serve_when_ready: false,
    modifiers: [],
    allergies: [],
  };
  const [categoryItem, dispatchCategoryItem] = useReducer(categoryItemReducer, {...defaultData});
  
  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async (another: boolean) => {
    setSaving(true);

    let order_index: number = 0;
    if (category_type === 'dish') {
      order_index = await getCategoryDishNextOrderIndex(category_id);
    }
    if (category_type === 'drink') {
      order_index = await getCategoryDrinkNextOrderIndex(category_id);
    }

    const item: UnknownObject = {
      ...categoryItem,
    };

    delete item.modifiers;
    delete item.allergies;

    const data: UnknownObject = {
      ...item,
      organisation_id,
      order_index: order_index + 1,
      category_assignments: {
        data: {
          organisation_id,
          category_id,
        }
      },
      modifier_assignments: {
        data: categoryItem.modifiers.map((i: Modifier) => ({
          organisation_id,
          modifier: {
            data: {
              name: i.name,
              price: i.price,
              tax: i.tax,
              organisation_id,
            },
          },
        })),
      },
      allergy_assignments: {
        data: categoryItem.allergies.map((i: Allergy) => ({
          organisation_id,
          allergy_id: i.id,
        })),
      },
    };

    const insertCategoryItemVariables = {
      object: data,
    };

    let id: string | undefined;

    if (category_type === 'dish') {
      id = await createDish(insertCategoryItemVariables);
    }
    if (category_type === 'drink') {
      id = await createDrink(insertCategoryItemVariables);
    }
    if (id) {
      setSaving(false);
      if (another) {
        dispatchCategoryItem({ type: CategoryItemReducerAction.INIT, value: defaultData });
      } else {
        handleFinish(id);
      }
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        categoryItem={categoryItem}
        currency={currency}
        locale={locale}
        taxRate={taxRate}
        category_id={category_id}
        category_type={category_type}
        dispatch={dispatchCategoryItem}
        handleSave={() => handleSave(false)}
        handleAnother={() => handleSave(true)} />
      {saving && <CustomBackdrop label="Creating Category Item" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateCategoryItem));
