
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';

const _editVoucherCodeInitQuery = (id: string) => ({
  vouchers_code_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      code: types.string,
      value: types.number,
      voucher_type: types.string,
      single_use: types.string,
      can_expire: types.boolean,
      start_date: types.boolean,
      end_date: types.string,
      category_ids: types.custom<string[]>(),
    },
  ),
});

export const editVoucherCodeInitQuery = (id: string) => gql`${query(_editVoucherCodeInitQuery(id))}`;
