import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../../layouts/Dashboard';
import ViewLocationStaff from '../../../../../../components/Global/LocationStaff/View';

import { selectLocationUserInitQuery, LocationUser } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  organisation_id: string,
  location_id: string,
  staff_user_id: string,
}

const AdminViewLocationUser = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, location_id, staff_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<LocationUser>({
    full_name: '',
    location: {
      name: '',
      organisation: {
        name: '',
      },
    }
  });

  const { data: locationUserInitQuery } = useQuery(selectLocationUserInitQuery(staff_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitQuery) {
      setUser(locationUserInitQuery.users_location_by_pk);
    }
    return () => { mounted = false; };
  }, [locationUserInitQuery]);

  const handleEdit = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/staff/${staff_user_id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: user.location.organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
        { label: user.location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
        { label: 'Staff', link: `/admin/organisations/${organisation_id}/locations/${location_id}/staff` },
        { label: user.full_name },
      ]}>
      <ViewLocationStaff
        staff_user_id={staff_user_id}
        handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(AdminViewLocationUser));
