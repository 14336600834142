import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Button,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Referral, ReferralReducerAction } from '../../../Common';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  referral: Referral,
  dispatch: React.Dispatch<{ type: ReferralReducerAction, value: any }>,
  handleNext: () => void,
  handleBack: () => void,
}

const StepNotes = ({ classes, referral, dispatch, handleNext, handleBack }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.title} variant='h6'>Notes</Typography>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          label="Notes (optional)"
          variant="outlined"
          placeholder=""
          multiline
          inputProps={{
            rows: 10,
          }}
          value={referral.note}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: ReferralReducerAction.NOTE, value: event.target.value })} />

        <div className={classes.buttonContainer}>
          <Button className={classes.button} variant='outlined' color='primary' onClick={() => handleBack()}>Back</Button>
          <Button className={classes.button} variant='contained' color='primary'onClick={() => handleNext()}>Next</Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(StepNotes);
