
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Dashboard from '../../../../layouts/Dashboard';
import CreateMenu from '../../../../components/Global/Menus/Create';

interface Props extends RouteComponentProps {

}

const LocCreateMenu = ({ history }: Props): React.ReactElement => {
  const handleFinish = (id: string) => {
    history.push(`/loc/menus/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Menus', link: `/loc/menus` },
          { label: 'Create' },
        ]}>
        <CreateMenu
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(LocCreateMenu);
