import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../../layouts/Dashboard';
import EditVoucher from '../../../../../components/Global/Vouchers/Edit';

import { editVoucherCodeInitQuery, VoucherCode } from './queries';

interface Props extends RouteComponentProps {}

interface UrlParams {
  location_id: string,
  voucher_id: string,
}

const EditVoucherOrg = ({ history }: Props): React.ReactElement => {
  const { location_id, voucher_id } = useParams<UrlParams>();

  const [voucher, setVoucher] = useState<VoucherCode>({
    name: '',
    location: {
      name: '',
      voucher_codes: [],
    },
    organisation: {
      categories: [],
    }
  });

  const { data: editMenuInitData } = useQuery(editVoucherCodeInitQuery(voucher_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editMenuInitData) {
      setVoucher(editMenuInitData.vouchers_code_by_pk);
    }
    return () => { mounted = false; };
  }, [editMenuInitData]);

  const handleFinish = () => {
    history.push(`/org/locations/${location_id}/vouchers`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Locations', link: `/org/locations` },
          { label: voucher.location.name, link: `/org/locations/${location_id}` },
          { label: 'Vouchers', link: `/org/locations/${location_id}/vouchers` },
          { label: voucher.name, link: `/org/locations/${location_id}/vouchers/${voucher_id}` },
          { label: 'Edit' },
        ]}>
        <EditVoucher
          voucher_id={voucher_id}
          categories={voucher.organisation.categories}
          used_codes={voucher.location.voucher_codes}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(EditVoucherOrg);
