import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Button,
  withStyles,
} from '@material-ui/core';

import { ValidationErrors, CategoryItemReducerAction, Modifier } from '../..';
import { ModifierItem } from './components';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  modifiers: Modifier[],
  currency: string,
  locale: string,
  taxRate: number,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: CategoryItemReducerAction, value: any }>,
}

const StepModifiers = ({ classes, modifiers, currency, locale, taxRate, errors, setErrors, dispatch }: Props): React.ReactElement => {
  return (
    <>
      <div className={classes.modifiers}>
        {modifiers.map((modifier: Modifier) => (
          <ModifierItem
            key={modifier.id}
            data={modifier}
            currency={currency}
            locale={locale}
            taxRate={taxRate}
            dispatch={dispatch} />
        ))}
      </div>
      <div className={classes.actions}>
        <Button variant="outlined" onClick={() => dispatch({ type: CategoryItemReducerAction.ADD_MODIFIER, value: { taxRate } })}>Add Modifier</Button>
      </div>
    </>
  );
};

export default withStyles(styles)(StepModifiers);
