import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Typography,
  withStyles,
} from '@material-ui/core';

import { Logo } from '../../../../components/Logos';
import { XeroLogo } from '../../../../components/Logos/Partners';

import { CenteredLayout } from '../../../../layouts';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const StepXeroUnsuccessful = ({ classes }: Props): React.ReactElement => {
  
  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //   }
  //   return () => { mounted = false; };
  // }, []);

  return (
    <CenteredLayout>
      <div className={classes.root}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <Typography className={classes.text}>+</Typography>
        <div className={classes.xeroLogo}>
          <XeroLogo />
        </div>
        <Typography className={classes.text}>integration</Typography>
        <Typography className={classes.unsuccessful}>Something went wrong</Typography>
        <Typography className={classes.unsuccessful}>Please try again</Typography>
      </div>
    </CenteredLayout>
  );
};

export default withStyles(styles)(StepXeroUnsuccessful);
