
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editLocationPrinterInitQuery = (user_id: string) => ({
  location_devices_by_pk: params(
    {
      id: rawString(user_id)
    },
    {
      id: types.string,
      name: types.string,
      device_config: types.custom<{ location: string, categories: { id: string, name: string, catergory_type: string }[] }>(),
    },
  ),
});

type X = Unpacked<typeof _editLocationPrinterInitQuery>
export type LocationPrinter = X['location_devices_by_pk'];

export const editLocationPrinterInitQuery = (user_id: string) => gql`${query(_editLocationPrinterInitQuery(user_id))}`;
