import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import GridCard from '../../../../components/Grid/GridCard';

import styles from './styles';
// import { homeInitQuery } from './queries';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const Bookings = ({ classes, history }: Props): React.ReactElement => {
  // const [locationsCount, setLocationsCount] = useState<number>(0);

  // const { data: homeInitData } = useQuery(homeInitQuery, { fetchPolicy: 'no-cache' });

  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted && homeInitData) {
  //     setLocationsCount(homeInitData.locations_aggregate.aggregate.count);
  //   }
  //   return () => { mounted = false; };
  // }, [homeInitData]);

  const handleAction = (link: string) => {
    // history.push(link);
  }

  const actions = [
    {
      title: 'Bookings coming soon',
      createLink: '/loc/bookings/create',
      viewLink: '/loc/bookings',
    },
  ];

  return (
    <Dashboard title="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            key={action.title}
            title={action.title}
            editLabel="Create"
            handleView={() => handleAction(action.viewLink)} />
        ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(Bookings));
