import React from 'react';
import { Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client/react';

import { createBrowserHistory } from 'history';
import { WithStyles } from '@material-ui/core/styles';

import {
  CssBaseline,
  withStyles,
} from '@material-ui/core';

import Routes from '../../Routes';
import AuthProvider from '../AuthProvider';
import StateProvider from '../StateProvider';
import ApolloClient from '../../utils/apolloClient';

import CustomThemeProvider from '../CustomThemeProvider';
import { ThemeModes } from '../CustomThemeProvider/theme';

import styles from './styles';

const browserHistory = createBrowserHistory();

interface Props extends WithStyles<typeof styles> {}

const modes: ThemeModes = {
  primary: {
    main: '#95C01F',
    light: '#95C01F70',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#EE8E01',
    light: '#EE8E0170',
    contrastText: '#FFFFFF',
  },
  light: {
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f4',
    },
    text: {
      primary: '#000000DE',
      secondary: '#000000D2',
    }
  },
  dark: {
    background: {
      paper: '#222b36',
      default: '#171c24',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFFB3',
    }
  },
};

const App = ({ classes }: Props): React.ReactElement => {
  return (
    <CustomThemeProvider modes={modes}>
      <CssBaseline />
      <AuthProvider>
        <ApolloProvider client={ApolloClient}>
          <StateProvider>
            <div className={classes.root}>
              <Router history={browserHistory}>
                <Routes />
              </Router>
            </div>
          </StateProvider>
        </ApolloProvider>
      </AuthProvider>
    </CustomThemeProvider>
  );
};

export default withStyles(styles)(App);
