
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
const _editOrganisationInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      email: types.string,
      phone: types.string,
      organisation_type: types.string,
      currency: types.string,
      locale: types.string,
      tax_rate: types.string,
    },
  ),
});

export const editOrganisationInitQuery = (id: string) => gql`${query(_editOrganisationInitQuery(id))}`;
