
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _createCategoryItemInitQuery = (id: string) => ({
  categories_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        name: types.string,
        currency: types.string,
        locale: types.string,
        tax_rate: types.number,
      },
      menu: {
        name: types.string,
      },
    },
  ),
});

type X = Unpacked<typeof _createCategoryItemInitQuery>;
export type Category = X['categories_by_pk'];

export const createCategoryItemInitQuery = (id: string) => gql`${query(_createCategoryItemInitQuery(id))}`;
