import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  withStyles,
} from '@material-ui/core';

import { categoryStepsInitQuery, Colour } from './queries';

import styles from './styles';
import { useQuery } from '@apollo/client';

interface ItemProps extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  colour_id: string,
  small?: boolean,
}

const ColourPickerItem = ({ classes, colour_id, small = false }: ItemProps): React.ReactElement => {
  const [colours, setColours] = useState<Colour[]>([]);

  const { data: categoryStepsInitData } = useQuery(categoryStepsInitQuery(), { fetchPolicy: 'cache-first' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryStepsInitData) {
      setColours(categoryStepsInitData.colours);
    }
    return () => { mounted = false; };
  }, [categoryStepsInitData]);

  const colour = colours.find((i) => i.id === colour_id) || { id: '', hex: '', name: '' };

  return (
    <div className={`${classes.colourContainer} ${small ? classes.small : ''}`}>
      <div className={classes.colour} style={{ backgroundColor: colour.hex }}></div>
      <div className={classes.text}>{colour.name}</div>
    </div>
  );
};

export default withStyles(styles)(ColourPickerItem);
