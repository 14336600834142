import { gql } from '@apollo/client';
import gqlClient from '../../../utils/apolloClient';

const _updateAdminUserMutation = gql`
  mutation ($pk_columns: users_admin_pk_columns_input!, $set: users_admin_set_input!) {
    update_users_admin_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateAdminUser = async (variables: object) => {
  const res = await gqlClient.mutate<{
    update_users_admin_by_pk: {
      id: string,
    }
  }>({ mutation: _updateAdminUserMutation!, variables });
  return res.data?.update_users_admin_by_pk.id;
}

const _updateOrganisationUserMutation = gql`
  mutation ($pk_columns: users_organisation_pk_columns_input!, $set: users_organisation_set_input!) {
    update_users_organisation_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateOrganisationUser = async (variables: object) => {
  const res = await gqlClient.mutate<{
    update_users_organisation_by_pk: {
      id: string,
    }
  }>({ mutation: _updateOrganisationUserMutation!, variables });
  return res.data?.update_users_organisation_by_pk.id;
}

const _updateLocationUserMutation = gql`
  mutation ($pk_columns: users_location_pk_columns_input!, $set: users_location_set_input!) {
    update_users_location_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateLocationUser = async (variables: object) => {
  const res = await gqlClient.mutate<{
    update_users_location_by_pk: {
      id: string,
    }
  }>({ mutation: _updateLocationUserMutation!, variables });
  return res.data?.update_users_location_by_pk.id;
}

