import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  TextField,
  withStyles,
} from '@material-ui/core';

import { Location, ValidationErrors, LocationReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  location: Location,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: LocationReducerAction, value: any }>,
}

const StepDetails = ({ classes, location, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'address_line_1 ') {
      errorItems.address_line_1 = validation.address_line_1(location.address_line_1);
    }
    if (field === 'address_city') {
      errorItems.address_city = validation.address_city(location.address_city);
    }
    if (field === 'address_country') {
      errorItems.address_country = validation.address_country(location.address_country);
    }
    if (field === 'address_postcode') {
      errorItems.address_postcode = validation.address_postcode(location.address_postcode);
    }
    setErrors({...errors, ...errorItems});
  }, [location, errors, setErrors]);


  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="address_line_1-textfield"
          required
          label="Address line 1"
          variant="outlined"
          error={errors.address_line_1 as boolean}
          helperText={errors.address_line_1}
          value={location.address_line_1}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.ADDRESS_LINE_1, value: event.target.value })}
          onKeyUp={() => validate('address_line_1')}
          onBlur={() => validate('address_line_1')}/>
        <TextField
          className={classes.textField}
          data-qa="address_line_2-textfield"
          label="Address line 2"
          variant="outlined"
          value={location.address_line_2}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.ADDRESS_LINE_2, value: event.target.value })}
          onKeyUp={() => validate('address_line_2')}
          onBlur={() => validate('address_line_2')} />
        <TextField
          className={classes.textField}
          data-qa="address_city-textfield"
          required
          label="Address city"
          variant="outlined"
          error={errors.address_city as boolean}
          helperText={errors.address_city}
          value={location.address_city}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.ADDRESS_CITY, value: event.target.value })}
          onKeyUp={() => validate('address_city')}
          onBlur={() => validate('address_city')} />
        <TextField
          className={classes.textField}
          data-qa="address_country-textfield"
          required
          label="Address country"
          variant="outlined"
          error={errors.address_country as boolean}
          helperText={errors.address_country}
          value={location.address_country}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.ADDRESS_COUNTRY, value: event.target.value })}
          onKeyUp={() => validate('address_country')}
          onBlur={() => validate('address_country')} />
        <TextField
          className={classes.textField}
          data-qa="address_postcode-textfield"
          required
          label="Address postcode"
          variant="outlined"
          error={errors.address_postcode as boolean}
          helperText={errors.address_postcode}
          value={location.address_postcode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.ADDRESS_POSTCODE, value: event.target.value })}
          onKeyUp={() => validate('address_postcode')}
          onBlur={() => validate('address_postcode')} />
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
