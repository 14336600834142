import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { locationStaffReducer, LocationStaffReducerAction } from '../Common';
import Steps from '../Common/components/Steps';


import styles from './styles';
import { editLocationUserInitQuery } from './queries';
import { updateLocationUser } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  staff_user_id: string,
  usedPins: string[],
  handleFinish: () => void,
}

const EditLocationStaff = ({ classes, history, staff_user_id, usedPins, handleFinish }: Props): React.ReactElement => {

  const [user, dispatchUser] = useReducer(locationStaffReducer, {
    first_name: '',
    last_name: '',
    pin: '',
    active: true,
  });

  const [orignalPin, setOriginalPin] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  const { data: editLocationUserInitQueryData } = useQuery(editLocationUserInitQuery(staff_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationUserInitQueryData) {
      dispatchUser({ type: LocationStaffReducerAction.INIT, value: editLocationUserInitQueryData.users_location_by_pk });
      setOriginalPin(editLocationUserInitQueryData.users_location_by_pk.pin);
    }
    return () => { mounted = false; };
  }, [editLocationUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: staff_user_id,
      },
      set: {
        ...user,
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
      }
    };
    await updateLocationUser(updateVariables);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        user={user}
        usedPins={usedPins.filter((i) => i !== orignalPin)}
        dispatch={dispatchUser}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditLocationStaff));
