import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';
import { Unpacked } from '../../../../react-app-env';
import { params, query, rawString, types } from 'typed-graphqlify';

const _categoryDishNextOrderIndexQuery = (id: string) => ({
  dish_category_assignments: params(
    { where: { category_id: { _eq: rawString(id) } } },
    [{
      dish: {
        order_index: types.number,
      }
    }],
  )
});

export const getCategoryDishNextOrderIndex = async (id: string) => {
  const res =  await gqlClient.query<Unpacked<typeof _categoryDishNextOrderIndexQuery>>({ query: gql`${query(_categoryDishNextOrderIndexQuery(id))}`! });
  const a = res.data?.dish_category_assignments || [];
  return a.length > 0 ? Math.max(...a.map((i) => i.dish?.order_index)) : 0;
}

const _categoryDrinkNextOrderIndexQuery = (id: string) => ({
  drink_category_assignments: params(
    { where: { category_id: { _eq: rawString(id) } } },
    [{
      drink: {
        order_index: types.number,
      }
    }],
  )
});

export const getCategoryDrinkNextOrderIndex = async (id: string) => {
  const res =  await gqlClient.query<Unpacked<typeof _categoryDrinkNextOrderIndexQuery>>({ query: gql`${query(_categoryDrinkNextOrderIndexQuery(id))}`! });
  const a = res.data?.drink_category_assignments || [];
  return a.length > 0 ? Math.max(...a.map((i) => i.drink?.order_index)) : 0;
}

