import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ListMenus from '../../../../components/Global/Menus/List';

import { menusInitQuery, Menu } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}
interface UrlParams {
  organisation_id: string,
}

const ListMenusOrg = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [menus, setMenus] = useState<Menu[]>([]);

  const { data: menusInitData } = useQuery(menusInitQuery(organisation_id));

  useEffect(() => {
    let mounted = true;
    if (mounted && menusInitData) {
      setMenus(menusInitData.menus);
    }
    return () => { mounted = false; };
  }, [menusInitData]);

  const handleCreate = () => {
    history.push(`/org/menus/create`);
  };

  const handleView = (id: string) => {
    history.push(`/org/menus/${id}`);
  };

  const handleUpload = () => {
    history.push(`/org/menus/upload`);
  };

  const handleSetMenus = (menus: Menu[]) => setMenus(() => menus);

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Menus' },
      ]}>
        <ListMenus
          items={menus}
          handleSetMenus={handleSetMenus}
          handleUpload={handleUpload}
          handleView={handleView}
          handleCreate={handleCreate}/>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListMenusOrg));
