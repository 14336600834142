import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { capitaliseFirst } from '../../../../../utils/stringUtils';

import Dashboard from '../../../../../layouts/Dashboard';
import EditCategory from '../../../../../components/Global/Categories/Edit';

import { editCategoryInitQuery, Category } from './queries';

interface Props extends RouteComponentProps {}

interface UrlParams {
  menu_id: string,
  category_type: string,
  category_id: string,
}

const EditCategoryOrg = ({ history }: Props): React.ReactElement => {
  const { menu_id, category_type, category_id } = useParams<UrlParams>();

  const [category, setCategory] = useState<Category>({
    name: '', 
    menu: {
      name: '',
    },
  });
  const { data: editMenuInitData } = useQuery(editCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editMenuInitData) {
      setCategory(editMenuInitData.categories_by_pk);
    }
    return () => { mounted = false; };
  }, [editMenuInitData]);

  const handleFinish = (id: string) => {
    history.push(`/loc/menus/${menu_id}/${category_type}/categories/${category_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Menus', link: `/loc/menus` },
          { label: category.menu.name, link: `/loc/menus/${menu_id}` },
          { label: `${capitaliseFirst(category_type)} Categories` },
          { label: category.name, link: `/loc/menus/${menu_id}/${category_type}/categories/${category_id}` },
          { label: 'Edit' },
        ]}>
        <EditCategory
          category_id={category_id}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(EditCategoryOrg);
