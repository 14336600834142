import React from 'react';

import { 
  Grid,
 } from '@material-ui/core';

import { 
  MetricOrders,
  MetricPayments,
  MetricDishesDrinks,
  MetricVouchers,
  MetricDeliverect,
 } from '.';
 
 interface Props {
  startDate: Date,
  endDate: Date,
  locale: string,
  currency: string,
}

const Metrics = ({ startDate, endDate, locale, currency }: Props): React.ReactElement => {
  return (
    <Grid container spacing={2}>
      <MetricOrders startDate={startDate} endDate={endDate} locale={locale} currency={currency} />
      <MetricDishesDrinks startDate={startDate} endDate={endDate} locale={locale} currency={currency} />
      <MetricPayments startDate={startDate} endDate={endDate} />
      <MetricVouchers startDate={startDate} endDate={endDate} locale={locale} currency={currency} />
      <MetricDeliverect startDate={startDate} endDate={endDate} locale={locale} currency={currency} />
    </Grid>
  );
};

export default Metrics;
