import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { pluralise } from '../../../../utils/stringUtils';

import {
  Avatar,
  Grid,
  GridSize,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Search as SearchIcon,
  Clear as ClearSearchIcon,
 } from '@material-ui/icons/';

 import ToggleButton from '@material-ui/lab/ToggleButton';
 import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { GridCard, GridActionCard } from '../../../Grid';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  title?: string,
  pluraliseTitle?: boolean,
  users: User[],
  userTypes?: UserType[],
  gridSize?: GridSize,
  handleCreate: () => void,
  handleEdit: (id: string) => void,
  handleView: (id: string) => void,
}

interface User {
  id: string,
  full_name: string,
  user_type?: UserType,
  email?: string,
  pin?: string | null,
}

interface UserType {
  user_type: string,
  description: string,
}

const UsersList = ({ classes, title = 'User', pluraliseTitle = true, users, userTypes = [], gridSize = 4, handleCreate, handleEdit, handleView }: Props): React.ReactElement => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [usersFilter, setUsersFilter] = useState<string[]>([]);

  const filterUsers = () => {
    return users
      .filter((i) => i.full_name.toLowerCase().includes(searchValue.toLowerCase()))
      .filter((i) => usersFilter.length > 0 ? usersFilter.includes(i.user_type?.user_type || '') : true);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>
            <div className={classes.headerTitle}>
              <Avatar className={classes.counter} variant="rounded">
                {users.length}
              </Avatar>
              <Typography className={classes.title} variant="h6" id="sortableTableTitle" component="div">
                {pluraliseTitle ? pluralise(title, users.length) : title}
              </Typography>
            </div>
            {/* <Typography className={classes.title} variant="h5" component="h2">{users.length} {pluralise('User', users.length)}</Typography> */}
            <div className={classes.searchContainer}>
              {userTypes.length > 0 && (
                <ToggleButtonGroup size="medium" value={usersFilter} color="primary" onChange={(_: unknown, values: string[]) => setUsersFilter(values)}>
                  {userTypes.map((i) =>
                    <ToggleButton
                      className={classes.toggleButton}
                      key={i.user_type}
                      value={i.user_type}
                      disabled={users.filter(u => u.user_type?.user_type === i.user_type).length === 0}>
                        {`${users.filter(u => u.user_type?.user_type === i.user_type).length} ${i.description}`}
                    </ToggleButton>)}
                </ToggleButtonGroup>
              )}
              <Paper variant="outlined" className={classes.search}>
                <>
                  <InputBase
                    className={classes.searchInput}
                    placeholder="Search"
                    value={searchValue}
                    onChange={((event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || ''))}
                    startAdornment={<SearchIcon className={classes.searchButton} />}
                    endAdornment={
                      searchValue
                      ? <Tooltip title="Clear" arrow>
                          <InputAdornment position="end" className={classes.searchButton}>
                            <IconButton size="small" onClick={() => setSearchValue('')}>
                              <ClearSearchIcon />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      : <div className={classes.searchButton}></div>
                    }
                    inputProps={{ 'aria-label': 'search list' }} />
                </>
              </Paper>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <>
          <GridActionCard gridSize={gridSize} fill onClick={handleCreate} />
          {filterUsers().map((user) => (
            <GridCard
              fill
              gridSize={gridSize}
              key={user.id}
              title={user.full_name}
              subTitle={user.user_type?.description}
              description={user.email}
              counterSmall
              counter={user.pin || undefined}
              counterLabel="PIN"
              handleEdit={() => handleEdit(user.id)}
              handleView={() => handleView(user.id)} />
          ))}
        </>
      </Grid>
    </>
  );
};

export default withStyles(styles)(UsersList);
