import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
  },
  label:{
    marginTop: theme.spacing(2),
  },
});

export default styles;
