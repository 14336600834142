import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../../layouts/Dashboard';
import CreateMenu from '../../../../../components/Global/Menus/Create';

import { createMenuInitQuery, Organisation } from './queries';

interface Props extends RouteComponentProps {

}

interface UrlParams {
  organisation_id: string,
}

const AdminCreateMenu = ({ history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
  });

  const { data: createMenuInitData } = useQuery(createMenuInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createMenuInitData) {
      setOrganisation(createMenuInitData.organisations_by_pk);
    }
    return () => { mounted = false; };
  }, [createMenuInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/menus/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: '/admin/organisations' },
          { label: 'Menus', link: `/admin/organisations/${organisation_id}/menus` },
          { label: 'Create' },
        ]}>
        <CreateMenu
          organisation_id={organisation_id}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(AdminCreateMenu);
