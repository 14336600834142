import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../../layouts/Dashboard';
import EditLocationStaff from '../../../../../../components/Global/LocationStaff/Edit';

import { editLocationUserInitQuery, LocationUser } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  organisation_id: string,
  location_id: string,
  staff_user_id: string,
}

const AdminEditLocationUser = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, location_id, staff_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<LocationUser>({ 
    full_name: '',
    location: {
      name: '',
      organisation: {
        name: '',
      },
      users: [],
    },
  });

  const { data: editLocationUserInitQueryData } = useQuery(editLocationUserInitQuery(staff_user_id, location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationUserInitQueryData) {
      setUser(editLocationUserInitQueryData.users_location_by_pk);
    }
    return () => { mounted = false; };
  }, [editLocationUserInitQueryData]);

  const handleFinish = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/staff/${staff_user_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: user.location.organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
          { label: user.location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
          { label: 'Staff', link: `/admin/organisations/${organisation_id}/locations/${location_id}/staff` },
          { label: user.full_name, link: `/admin/organisations/${organisation_id}/locations/${location_id}/staff/${staff_user_id}` },
          { label: 'Edit' },
        ]}>
        <EditLocationStaff
          staff_user_id={staff_user_id}
          usedPins={user.location.users.filter((i) => i.pin).map((i) => i.pin)}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(AdminEditLocationUser));
