import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../../layouts/Dashboard';
import CreateVoucherCode from '../../../../../components/Global/Vouchers/Create';

import { createVoucherCodeInitQuery, Location } from './queries';

interface Props extends RouteComponentProps {

}

interface UrlParams {
  location_id: string,
}

const CreateVoucherOrg = ({ history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      categories: [],
    },
    voucher_codes: [],
  });

  const { data: createMenuInitData } = useQuery(createVoucherCodeInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createMenuInitData) {
      setLocation(createMenuInitData.locations_by_pk);
    }
    return () => { mounted = false; };
  }, [createMenuInitData]);

  const handleFinish = () => {
    history.push(`/org/locations/${location_id}/vouchers`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Locations', link: `/org/locations` },
          { label: location.name, link: `/org/locations/${location_id}` },
          { label: 'Vouchers', link: `/org/locations/${location_id}/vouchers` },
          { label: 'Create' },
        ]}>
        <CreateVoucherCode
          location_id={location_id}
          categories={location.organisation.categories}
          used_codes={location.voucher_codes}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(CreateVoucherOrg);
