import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';

import CreateLocation from '../../../../components/Global/Location/Create';

import { createLocationInitQuery, Organisation } from './queries';

import styles from './styles';


interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const OrgCreateLocation = ({ classes, history }: Props): React.ReactElement => {
  const [organisation, setOrganisation] = useState<Organisation>({
    tax_rate: 0,
  });

  const { data: createLocationInitData } = useQuery(createLocationInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createLocationInitData) {
      setOrganisation(createLocationInitData.organisations_by_pk);
    }
    return () => { mounted = false; };
  }, [createLocationInitData]);

  const handleFinish = (id: string) => {
    history.push(`/org/locations/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Locations', link: '/org/locations' },
          { label: 'Create' },
        ]}>
        {organisation.tax_rate && (
          <CreateLocation
            tax_rate={organisation.tax_rate}
            handleFinish={handleFinish} />
        )}
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(OrgCreateLocation));
