import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import UsersList from '../../../../../components/Global/Users/List';

import { selectAdminUsersInitQuery, AdminUser } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const ListAdminUsers = ({ classes, history }: Props): React.ReactElement => {
  const [users, setUsers] = useState<AdminUser[]>([]);

  const { data: adminUserInitQuery } = useQuery(selectAdminUsersInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && adminUserInitQuery) {
      const { users_admin } = adminUserInitQuery;
      setUsers(users_admin);
    }
    return () => { mounted = false; };
  }, [adminUserInitQuery]);

  const handleCreate = () => {
    history.push(`/admin/users/create`);
  };

  const handleView = (id: string) => {
    history.push(`/admin/users/${id}`);
  };

  const handleEdit = (id: string) => {
    history.push(`/admin/users/${id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Admin', },
        { label: 'Users', link: '/admin/users' },
      ]}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UsersList
            users={users}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
            handleView={handleView}
          />
        </Grid>
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ListAdminUsers));
