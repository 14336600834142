
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _editVoucherCodeInitQuery = (id: string) => ({
  vouchers_code_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      location: {
        name: types.string,
        voucher_codes: params(
          {
            where: { id: { _ne: rawString(id) } }
          }, 
          [{
            id: types.string,
            code: types.string,
          }],
        ),
      },
      organisation: {
        categories: [{
          id: types.string,
          name: types.string,
          menu: {
            name: types.string,
          },
        }],
      },
    },
  ),
});
type X = Unpacked<typeof _editVoucherCodeInitQuery>;
export type VoucherCode = X['vouchers_code_by_pk'];

export const editVoucherCodeInitQuery = (id: string) => gql`${query(_editVoucherCodeInitQuery(id))}`;
