import { UnknownObject, Unpacked } from '../../../../react-app-env';
import { queryBuilder, QueryField, QueryHeader, QueryFilter } from '../../../../utils/graphQL';
import gqlClient from '../../../../utils/apolloClient';
import { gql } from '@apollo/client';

import { capitaliseFirstOnly } from "../../../../utils/stringUtils";

export interface Report {
  name: string,
  table: string,
  filters: QueryFilter[],
  fields: QueryHeader[],
}

export interface ValidationErrors {
  name: string | boolean,
  table: string | boolean,
  fields: string | boolean,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  table: 'Please provide a table',
  fields: 'Please provide at least two feilds',
}

export enum ReportReducerAction {
  NAME,
  TABLE,
  ADD_FILTER,
  ADD_FIELD,
  REMOVE_FIELD,
  INIT,
}

export const reportReducer = (state: Report, action: { type: ReportReducerAction, value: any }): Report =>  {
  switch (action.type) {
    case ReportReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case ReportReducerAction.TABLE:
      return { ...state, table: action.value };
    case ReportReducerAction.ADD_FILTER:
      const f = state.filters;
      f.push({
        key: '',
        condition: 'eq',
        value: '',
      });
      return { ...state, filters: f };
    case ReportReducerAction.ADD_FIELD:
      const flds = state.fields;
      flds.push({
        key: action.value.key,
        label: action.value.label,
      });
      return { ...state, fields: flds.filter((thing, index, self) => index === self.findIndex((t) => ( t.key === thing.key && t.key === thing.key ))) };
    case ReportReducerAction.REMOVE_FIELD:
      const filds = state.fields.filter((f) => f.key !== action.value.key);
      return { ...state, fields: filds };
    case ReportReducerAction.INIT:
      const { name, table, filters, fields } = action.value;
      const obj = {
        name,
        table,
        filters,
        fields,
      };
      return { ...obj as Report };
    default:
      throw new Error();
  }
}





export interface ReportData {
  name: string,
  table: string,
  filters: QueryFilter[],
  headers: ReportDataHeader[],
  totals: string[],
  returns: QueryField[],
  output: ReportDataOutput,
}

export interface ReportDataHeader {
  key: string,
  label: string,
  format?: string,
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
}
export interface ReportDataOutput {
  calc: EnumReportOutputCalc,
  type: EnumReportOutputType,
  field?: string,
}

export enum EnumReportOutputCalc {
  SUM,
  DATA,
}

export enum EnumReportOutputType {
  GROUP,
  DATA,
}

export const createReportQuery = async (q: string, variables: UnknownObject, t: UnknownObject) => {
  type X = Unpacked<typeof t>;
  const res = await gqlClient.query<X>({ query: gql`${q}`!, variables });
  return res.data;
};

export const buildQuery = (qData: ReportData) => {
  const q = queryBuilder(qData.table, qData.filters, qData.returns);
  return createReportQuery(q.query, q.variables, q.returns);
};

export const getNestedObjectValue = (field: string, data: UnknownObject, totalSum?: boolean): string | number | boolean | Array<string | number | boolean> => {
  const nesting: string[] = field.split('.');
  if (Array.isArray(data)) {
    const c = data.map((i) => getNestedObjectValue(field, i, totalSum));
    const d = totalSum ? c.map((i) => Number(i)).reduce((a: number, b: number) => a + b, 0) : c.flat();
    return d;
  }
  if (nesting.length > 1) {
    return getNestedObjectValue(nesting.slice(1).join('.'), data[nesting[0]], totalSum);
  }
  return data[field];
}

export const getNestedObjectFinalKey = (field: string): string => {
  const nesting: string[] = field.split('.');
  if (nesting.length > 1) {
    return getNestedObjectFinalKey(nesting.slice(1).join('.'));
  }
  return field;
}

export const groupReportData = (data: UnknownObject[], groupOn: string) => {
  if (data.length === 0) {
    return [];
  }
  const groups: UnknownObject = {};
  data.forEach((i: UnknownObject) => {
    const values = getNestedObjectValue(groupOn, i);
    if (Array.isArray(values)) {
      values.forEach((i) => {
        const key = `${i}`
        const groupItem = groups[key];
        groups[key] = groupItem ? groupItem as number + 1 : 1;
      });
    } else {
      const groupItem = groups[ `${values}`];
      groups[`${values}`] = groupItem ? groupItem as number + 1 : 1;
    }
  });
  return groups;
}

export const CHART_COLORS = {
  green: 'rgba(149, 192, 31, 0.6)',
  red: 'rgba(255, 99, 132, 0.6)',
  orange: 'rgba(255, 159, 64, 0.6)',
  yellow: 'rgba(255, 205, 86, 0.6)',
  blue: 'rgba(54, 162, 235, 0.6)',
  purple: 'rgba(153, 102, 255, 0.6)',
  grey: 'rgba(201, 203, 207, 0.6)'
};

const NAMED_COLORS = [
  CHART_COLORS.green,
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
];

export const getNamedChartColor = (index: number) => {
  return NAMED_COLORS[index % NAMED_COLORS.length];
};
