
import { query, types } from 'typed-graphqlify';
import { gql } from '@apollo/client';

export const homeInitQuery = gql`${query({
  organisations_aggregate: {
    aggregate: {
      count: types.number,
    },
  },
})}`;
