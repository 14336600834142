import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {},
  fill: {
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  message: {
    marginBottom: theme.spacing(4),
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(4),
    '& > button': {
      marginLeft: theme.spacing(1),
    },
  },
});

export default styles;
