
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editVoucherCodeInitQuery = (id: string) => ({
  vouchers_code_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        categories: [{
          id: types.string,
          name: types.string,
          menu: {
            name: types.string,
          },
        }],
      },
    },
  ),
  vouchers_code: params(
    {
      where: { id: { _ne: rawString(id) } }
    }, 
    [{
      id: types.string,
      code: types.string,
    }],
  ),
});
type X = Unpacked<typeof _editVoucherCodeInitQuery>;
export type VoucherCode = X['vouchers_code_by_pk'];
export type CodeVouchers = X['vouchers_code'][0];


export const editVoucherCodeInitQuery = (id: string) => gql`${query(_editVoucherCodeInitQuery(id))}`;
