import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './components/App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://7051f09cfdfc4ab48eb44a20839ad602@o4504439871766528.ingest.sentry.io/4504548591992832",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: 'apos-hub@1.0.0',
  });
}

// import * as serviceWorker from './serviceWorker';

declare module '@material-ui/core/styles/createPalette' {
    interface CommonColors {
        black: string;
        white: string;
        green: string;
        red: string;
        neutral: string;
    }
    interface Palette {
        border: string;
    }
}

declare module '@material-ui/core/styles/createSpacing' {
    interface Spacing {
        unit: string;
    }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
// serviceWorker.unregister();
