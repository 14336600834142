import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { locationUserReducer, LocationUserReducerAction, LocationUserType } from '../Common';
import Steps from '../Common/components/Steps';


import styles from './styles';
import { editLocationUserInitQuery } from './queries';
import { updateLocationUser } from './mutations';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  location_user_id: string,
  pinEnabled: boolean,
  usedPins: string[],
  handleFinish: () => void,
}

const EditLocationUser = ({ classes, history, location_user_id, pinEnabled, usedPins, handleFinish }: Props): React.ReactElement => {

  const [userTypes, setUserTypes] = useState<LocationUserType[]>([]);
  const [user, dispatchUser] = useReducer(locationUserReducer, {
    first_name: '',
    last_name: '',
    email: '',
    pin: null,
    user_type: null,
    active: true,
  });

  const [orignalPin, setOriginalPin] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  const { data: editLocationUserInitQueryData } = useQuery(editLocationUserInitQuery(location_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationUserInitQueryData) {
      setUserTypes(editLocationUserInitQueryData.enum_user_types);
      dispatchUser({ type: LocationUserReducerAction.INIT, value: editLocationUserInitQueryData.users_location_by_pk });
      setOriginalPin(editLocationUserInitQueryData.users_location_by_pk.pin);
    }
    return () => { mounted = false; };
  }, [editLocationUserInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const updateVariables = {
      pk_columns: {
        id: location_user_id,
      },
      set: {
        ...user,
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
        email: user.email.trim(),
        pin: pinEnabled ? user.pin : null,
      }
    };
    await updateLocationUser(updateVariables);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        user={user}
        isEdit
        pinEnabled={pinEnabled}
        usedPins={usedPins.filter((i) => i !== orignalPin)}
        userTypes={userTypes}
        dispatch={dispatchUser}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditLocationUser));
