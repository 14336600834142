import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../react-app-env';
import gqlClient from '../../../utils/apolloClient';

export interface Discount {
  id: string,
  type: string,
  total: number,
  value: number,
  before: number,
}
export interface Refund {
  id: string,
  reference_id: string,
}
export interface OrderItemData {
  id: string,
  item: {
    name: string,
  },
  created_at: string,
  served_at: string,
  price: number,
  tax: number,
}

export interface OrderPaymentData {
  id: string,
  order_id: string,
  payment_method: string,
  reference_id: string,
  total: number,
  tip: number,
}

export interface OrderGiftVoucherData {
  id: string,
  total: number,
  balance: number,
}

const getServedAtData = (dateFrom: string, dateTo: string, opts: object) => {
  return ({
    order_served_dish_items: params(
      {
        where: { 
          ...opts,
          order: { 
            paid: { _eq: true }, 
            created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          }
        }
      }, 
      [{
        served_at: types.string,
        order: {
          id: types.string,
        },
      }],
    ),
    order_served_drink_items: params(
      {
        where: { 
          ...opts,
          order: { 
            paid: { _eq: true }, 
            created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          }
        }
      }, 
      [{
        served_at: types.string,
        order: {
          id: types.string,
        },
      }],
    ),
    order_takeaway_dish_items: params(
      {
        where: { 
          ...opts,
          order: { 
            paid: { _eq: true }, 
            created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          }
        }
      }, 
      [{
        served_at: types.string,
        order: {
          id: types.string,
        },
      }],
    ),
    order_takeaway_drink_items: params(
      {
        where: { 
          ...opts,
          order: { 
            paid: { _eq: true }, 
            created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          }
        }
      }, 
      [{
        served_at: types.string,
        order: {
          id: types.string,
        },
      }],
    ),
  });
}

const getData = (dateFrom: string, dateTo: string) => {
  return ({
    name: types.string,
    currency: types.string,
    locale: types.string,
    integrations: [{
      deliverect: types.custom<{ accountId: string, autoAccept: boolean, locationId: string } | null>(),
    }],
    orders_served: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          paid: { _eq: true },
        },
      },
      [{
        id: types.string,
        covers: types.number,
        quick_sale: types.boolean,
        table_number: types.number,
        order_number: types.string,
        paid: types.boolean,
        total: types.number,
        tax: types.number,
        discounts: types.custom<Discount[]>(),
        refunds: types.custom<Discount[]>(),
        service_charge: types.number,
        created_at: types.string,
        created_by_user: {
          full_name: types.string,
          first_name: types.string,
        },
      }],
    ),
    orders_takeaway: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          paid: { _eq: true },
        },
      },
      [{
        id: types.string,
        order_number: types.string,
        paid: types.boolean,
        total: types.number,
        tax: types.number,
        discounts: types.custom<Discount[]>(),
        refunds: types.custom<Discount[]>(),
        service_charge: types.number,
        created_at: types.string,
        created_by_user: {
          full_name: types.string,
          first_name: types.string,
        },
      }],
    ),
    orders_deliverect: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          deliverect_status: { _eq: "FINALIZED" },
          paid: { _eq: true },
        },
      },
      [{
        id: types.string,
        order_number: types.string,
        paid: types.boolean,
        total: types.number,
        tax: types.number,
        service_charge: types.number,
        delivery_by: types.string,
        created_at: types.string,
      }],
    ),
    order_served_payments: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          canceled: { _eq: false },
        },
      },
      [{
        id: types.string,
        order_id: types.string,
        payment_method: types.string,
        reference_id: types.string,
        total: types.number,
        tip: types.number,
      }],
    ),
    order_takeaway_payments: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          canceled: { _eq: false },
        },
      },
      [{
        id: types.string,
        order_id: types.string,
        payment_method: types.string,
        reference_id: types.string,
        total: types.number,
        tip: types.number,
      }],
    ),
    order_deliverect_payments: params(
      {
        where: {
          created_at: { _gte: rawString(dateFrom), _lt: rawString(dateTo) },
          canceled: { _eq: false },
        },
      },
      [{
        id: types.string,
        order_id: types.string,
        payment_method: types.string,
        reference_id: types.string,
        total: types.number,
        tip: types.number,
      }],
    ),
  });
};

const _selectLocationOrdersByPkInitQuery = (location_id: string, dateFrom: string, dateTo: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    { ...getData(dateFrom, dateTo) }
  ),
});

const _selectLocationOrdersInitQuery = (dateFrom: string, dateTo: string) => ({
  locations: [{ ...getData(dateFrom, dateTo) }]
});

const _selectLocationOrdersServedAtByPkInitQuery = (location_id: string, dateFrom: string, dateTo: string) => ({
  ...getServedAtData(dateFrom, dateTo, { location_id: { _eq: rawString(location_id) } }),
});

const _selectLocationOrdersServedAtInitQuery = (dateFrom: string, dateTo: string) => ({
  ...getServedAtData(dateFrom, dateTo, {}),
});

export type SelectLocationOrdersByPkInit = Unpacked<typeof _selectLocationOrdersByPkInitQuery>
export type SelectLocationOrdersInit = Unpacked<typeof _selectLocationOrdersInitQuery>
export type Location = SelectLocationOrdersByPkInit['locations_by_pk'] | SelectLocationOrdersInit['locations'][0];
export type OrderServed = Location['orders_served'][0];
export type OrderTakeaway = Location['orders_takeaway'][0];
export type OrderDeliverect = Location['orders_deliverect'][0];

export type SelectLocationOrdersServedAtByPkInit = Unpacked<typeof _selectLocationOrdersServedAtByPkInitQuery>
export type SelectLocationOrdersServedAtInit = Unpacked<typeof _selectLocationOrdersServedAtInitQuery>
export type OrderServedAtItem = SelectLocationOrdersServedAtInit['order_served_dish_items'][0] | 
                                SelectLocationOrdersServedAtInit['order_served_drink_items'][0] | 
                                SelectLocationOrdersServedAtInit['order_takeaway_dish_items'][0] | 
                                SelectLocationOrdersServedAtInit['order_takeaway_drink_items'][0];

export const selectLocationOrdersInitQuery = async (location_id: string | undefined, dateFrom: string, dateTo: string) => {
  if (location_id) {
    const res = await gqlClient.query<SelectLocationOrdersByPkInit>({query: gql`${query(_selectLocationOrdersByPkInitQuery(location_id, dateFrom, dateTo))}`!});
    return res.data.locations_by_pk;
  }
  const res = await gqlClient.query<SelectLocationOrdersInit>({query: gql`${query(_selectLocationOrdersInitQuery(dateFrom, dateTo))}`!});
  return res.data.locations[0];
};

export const selectLocationOrdersServedAtInitQuery = async (location_id: string | undefined, dateFrom: string, dateTo: string) => {
  if (location_id) {
    const res = await gqlClient.query<SelectLocationOrdersServedAtByPkInit>({
      query: gql`${query(_selectLocationOrdersServedAtByPkInitQuery(location_id, dateFrom, dateTo))}`!,
      fetchPolicy: 'no-cache',
    });
    return res.data;
  }
  const res = await gqlClient.query<SelectLocationOrdersServedAtInit>({
    query: gql`${query(_selectLocationOrdersServedAtInitQuery(dateFrom, dateTo))}`!,
    fetchPolicy: 'no-cache',
  });
  return res.data;
};

const getOrderDetailsData = () => {
  return {
    dish_items: [{
      id: types.string,
      item: types.custom<{ name: string }>(),
      created_at: types.string,
      served_at: types.string,
      price: types.number,
      tax: types.number,
    }],
    drink_items: [{
      id: types.string,
      item: types.custom<{ name: string }>(),
      created_at: types.string,
      served_at: types.string,
      price: types.number,
      tax: types.number,
    }],
    payments: params(
      {
        where: {
          canceled: { _eq: false },
        },
      },
      [{
        id: types.string,
        order_id: types.string,
        payment_method: types.string,
        reference_id: types.string,
        total: types.number,
        tip: types.number,
      }],
    ),
  }
}

const _selectOrderServedDetailsQuery = (orderId: string) => ({
  orders_served_by_pk: params(
    { id: rawString(orderId) },
    { 
      ...getOrderDetailsData(),
      gift_vouchers_purchased: [{
        id: types.string,
        total: types.number,
        balance: types.number,
      }],
    },
  ),
});

const _selectOrderTakeawayDetailsQuery = (orderId: string) => ({
  orders_takeaway_by_pk: params(
    { id: rawString(orderId) },
    { ...getOrderDetailsData() },
  ),
});

const _selectOrderDeliverectDetailsQuery = (orderId: string) => ({
  orders_deliverect_by_pk: params(
    { id: rawString(orderId) },
    { ...getOrderDetailsData() },
  ),
});

export type SelectOrderServedDetails = Unpacked<typeof _selectOrderServedDetailsQuery>
export type SelectOrderTakeawayDetails = Unpacked<typeof _selectOrderTakeawayDetailsQuery>
export type SelectOrderDeliverectDetails = Unpacked<typeof _selectOrderDeliverectDetailsQuery>

export const getOrderDetailsQuery = async (orderId: string, orderType: string) => {
  if (orderType === 'SERVED') {
    const res = await gqlClient.query<SelectOrderServedDetails>({query: gql`${query(_selectOrderServedDetailsQuery(orderId))}`!});
    return res.data.orders_served_by_pk;
  }
  if (orderType === 'TAKEAWAY') {
    const res = await gqlClient.query<SelectOrderTakeawayDetails>({query: gql`${query(_selectOrderTakeawayDetailsQuery(orderId))}`!});
    return {
      ...res.data.orders_takeaway_by_pk,
      gift_vouchers_purchased: [],
    };
  }
  if (orderType === 'DELIVERECT') {
    const res = await gqlClient.query<SelectOrderDeliverectDetails>({query: gql`${query(_selectOrderDeliverectDetailsQuery(orderId))}`!});
    return {
      ...res.data.orders_deliverect_by_pk,
      gift_vouchers_purchased: [],
    };
  }
  return null;
};