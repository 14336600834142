import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import CustomBackdrop from '../../../../components/CustomBackdrop';

import { organisationReducer, OrganisationReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { editOrganisationInitQuery } from './queries';
import { updateOrganisation } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  organisation_id: string,
}

const EditOrganisation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, dispatchOrganisation] = useReducer(organisationReducer, {
    name: '',
    email: '',
    phone: '',
    organisation_type: '',
    currency: '',
    locale: '',
    tax_rate: 0,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editOrganisationInitData } = useQuery(editOrganisationInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editOrganisationInitData) {
      const org = editOrganisationInitData.organisations_by_pk;
      dispatchOrganisation({ type: OrganisationReducerAction.INIT, value: org });
    }
    return () => { mounted = false; };
  }, [editOrganisationInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateOrganisationVariables = {
      pk_columns: {
        id: organisation_id,
      },
      set: organisation,
    };
    await updateOrganisation(updateOrganisationVariables);

    setSaving(false);
    handleFinish();
  };

  const handleFinish = () => {
    history.push(`/admin/organisations/${organisation_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Edit' },
        ]}>
        <Steps
          completeLabel="Save"
          organisation={organisation}
          dispatch={dispatchOrganisation}
          handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditOrganisation));
