import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';

import { Location, LocationType, ValidationErrors, LocationReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  location: Location,
  locationTypes: LocationType[],
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: LocationReducerAction, value: any }>,
}

const StepDetails = ({ classes, location, locationTypes, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'name') {
      errorItems.name = validation.name(location.name);
    }
    if (field === 'email') {
      errorItems.email = validation.email(location.email);
    }
    if (field === 'phone') {
      errorItems.phone = validation.phone(location.phone);
    }
    if (field === 'location_type') {
      errorItems.location_type = validation.location_type(location.location_type);
    }
    setErrors({...errors, ...errorItems});
  }, [location, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={location.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}/>

        <FormControl variant="outlined" required className={classes.textField} error={errors.location_type as boolean}>
          <InputLabel id="location_type-select-label">Location type</InputLabel>
          <Select
            labelId="location_type-select-label"
            label="Location type"
            autoWidth
            value={location.location_type}
            onChange={(event: React.ChangeEvent<any>) => dispatch({ type: LocationReducerAction.LOCATION_TYPE, value: event.target.value })} >
            {locationTypes.map((i: LocationType) => <MenuItem key={i.location_type} value={i.location_type}>{i.description}</MenuItem>)}
          </Select>
          <FormHelperText>{errors.location_type}</FormHelperText>
        </FormControl>

        <Divider className={classes.divider} />

        <TextField
          className={classes.textField}
          data-qa="email-textfield"
          required
          label="Contact email address"
          variant="outlined"
          error={errors.email as boolean}
          helperText={errors.email}
          value={location.email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.EMAIL, value: event.target.value })}
          onKeyUp={() => validate('email')}
          onBlur={() => validate('email')} />
        <TextField
          className={classes.textField}
          data-qa="phone-textfield"
          required
          label="Contact phone number"
          variant="outlined"
          error={errors.phone as boolean}
          helperText={errors.phone}
          value={location.phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.PHONE, value: event.target.value })}
          onKeyUp={() => validate('phone')}
          onBlur={() => validate('phone')} />

        <Divider className={classes.divider} />

        <TextField
          className={classes.textField}
          data-qa="website-textfield"
          label="Website"
          variant="outlined"
          value={location.website}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.WEBSITE, value: event.target.value })}/>

      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
