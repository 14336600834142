import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomStepper from '../../../../../../components/CustomStepper';
import { Location, LocationType, validationErrors, ValidationErrors, LocationReducerAction, validation } from '../..';
import StepDetails from '../StepDetails';
import StepSettings from '../StepSettings';
import StepServiceCharge from '../StepServiceCharge';
import StepAddress from '../StepAddress';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  location: Location,
  locationTypes: LocationType[],
  dispatch: React.Dispatch<{ type: LocationReducerAction, value: any }>,
  handleSave: () => void,
}

const OrganisationSteps = ({ classes, completeLabel, location, locationTypes, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    email: false,
    email_invalid: false,
    number_of_tables: false,
    phone: false,
    location_type: false,
    address_line_1: false,
    tax_number: false,
    tax_rate: false,
    currency: false,
    locale: false,
    address_city: false,
    address_country: false,
    address_postcode: false,
    service_charge_order_types: false,
    service_charge_percentage: false,
    service_charge_percentage_too_small: false,
    service_charge_percentage_too_large: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = validation.name(location.name);
      errorItems.location_type = validation.location_type(location.location_type);
      errorItems.email = validation.email(location.email);
      errorItems.phone = validation.phone(location.phone);
    }
    if (_step === 1) {
      errorItems.address_line_1 = validation.address_line_1(location.address_line_1);
      errorItems.address_city = validation.address_city(location.address_city);
      errorItems.address_country = validation.address_country(location.address_country);
      errorItems.address_postcode = validation.address_postcode(location.address_postcode);
    }
    if (_step === 2) {
      errorItems.tax_number = validation.tax_number(location.tax_number);
      errorItems.currency = validation.currency(location.currency);
      errorItems.locale = validation.locale(location.locale);
      errorItems.number_of_tables = validation.number_of_tables(location.number_of_tables);
    }
    if (_step === 3) {
      if (location.service_charge != null) {
        errorItems.service_charge_order_types = validation.service_charge_order_types(location.service_charge!.order_types);
        errorItems.service_charge_percentage = validation.service_charge_percentage(location.service_charge!.percentage);
      }
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [location, errors]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                location={location}
                locationTypes={locationTypes}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Address',
            component:
              <StepAddress
                location={location}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Settings',
            component:
              <StepSettings
                location={location}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Service Charge',
            component:
              <StepServiceCharge
                location={location}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Summary',
            gridSize: 8,
            component:
              <StepReview
                location={location}
                locationTypes={locationTypes}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationSteps);
