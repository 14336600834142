import { capitaliseFirst } from "../../../../../utils/stringUtils";
import { ValidationType } from '../../../../../react-app-env';

export interface OrganisationUser {
  first_name: string,
  last_name: string,
  email: string,
  active: boolean,
}
export interface Organisation {
  id: string,
  name: string,
}

export interface ValidationErrors {
  first_name: ValidationType,
  last_name: ValidationType,
  email: ValidationType,
  email_invalid: ValidationType,
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  email: 'Please provide an email address',
  email_invalid: 'Please provide a valid email address',
}

export enum OrganisationUserReducerAction {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  ACTIVE,
  INIT,
}

export const organisationUserReducer = (state: OrganisationUser, action: { type: OrganisationUserReducerAction, value: any }): OrganisationUser =>  {
  switch (action.type) {
    case OrganisationUserReducerAction.FIRST_NAME:
      return { ...state, first_name: capitaliseFirst(action.value) };
    case OrganisationUserReducerAction.LAST_NAME:
      return { ...state, last_name: capitaliseFirst(action.value) };
    case OrganisationUserReducerAction.EMAIL:
      return { ...state, email: action.value?.toLowerCase().replace(/ /g, '') };
    case OrganisationUserReducerAction.ACTIVE:
      return { ...state, active: action.value as boolean };
    case OrganisationUserReducerAction.INIT:
      const { first_name, last_name, email, active } = action.value;
      const obj = {
        first_name,
        last_name,
        email,
        active,
      };
      return { ...obj as OrganisationUser };
    default:
      throw new Error();
  }
}
