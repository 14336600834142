import React, { useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useLocalStorage from '../../../../hooks/useLocalStorage';

import {
  Divider,
  Tooltip,
  Paper,
  withStyles,
} from '@material-ui/core';

import { WithStyles } from '@material-ui/core/styles';

import {
  ChevronLeftOutlined as CollapseIcon,
  ChevronRightOutlined as ExpandIcon,
} from '@material-ui/icons';

import { AppContext } from '../../../../components/StateProvider';
import SidebarItemsList, { ListItemRole, ListItemRoles } from './components/SidebarItemsList';
import { Hierarchy } from './components/HierarchyList';
import { HierarchyMenu, SettingsMenu } from './components';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  userRole: string,
  listItemRoles: ListItemRoles,
  hierarchy?: Hierarchy,
  signOut: () => Promise<void>,
}

const Sidebar = ({ classes, history, listItemRoles, hierarchy, userRole, signOut }: Props): React.ReactElement => {
  const { selectedHierarchy } = useContext(AppContext)!;

  const [isExpanded, setIsExpanded] = useLocalStorage<boolean>('sidebar-expanded', true);

  const handleExpandToggle = () => {
    setIsExpanded((i) => !i);
  };

  const listItemRole = listItemRoles[userRole as keyof typeof listItemRoles] || undefined;
  const route = selectedHierarchy ? selectedHierarchy.route.key : '';
  const nestedList: ListItemRole | undefined = listItemRoles[route as keyof typeof listItemRoles] || undefined;
  const roleItems = nestedList || listItemRole;

  return (
    <nav className={classes.root}>
      <Paper
        square
        elevation={0}
        className={`${classes.container} ${isExpanded ? classes.containerExpand : classes.containerCollapse}`}>
          {hierarchy && (<HierarchyMenu rootRole={listItemRole} hierarchy={hierarchy} isExpanded={isExpanded} />)}
          <div className={classes.listContainer}>
            <SidebarItemsList listItems={roleItems?.items || []} isExpanded={isExpanded} />
          </div>
          <SettingsMenu signOut={signOut} isExpanded={isExpanded} />
      </Paper>
      <div className={classes.expandContainer} onClick={handleExpandToggle}>
        <Divider orientation="vertical" className={classes.expandDivider} />
        <Tooltip title={isExpanded ? 'Collapse' : 'Expand'} placement="bottom">
          <div className={classes.expandToggle}>
            {isExpanded && <CollapseIcon />}
            {!isExpanded && <ExpandIcon />}
          </div>
        </Tooltip>
      </div>
    </nav>
  );
};

export default withRouter(withStyles(styles)(Sidebar));
