import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import ListLocationStaff from '../../../../../components/Global/LocationStaff/List';

import { selectLocationUsersInitQuery, Location } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  location_id: string,
}

const OrgListLocationStaff = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location | UnknownObject>({});

  const { data: locationUsersInitQuery } = useQuery(selectLocationUsersInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUsersInitQuery) {
      setLocation(locationUsersInitQuery.locations_by_pk);
    }
    return () => { mounted = false; };
  }, [locationUsersInitQuery]);

  const handleCreate = () => {
    history.push(`/org/locations/${location_id}/staff/create`);
  };

  const handleView = (id: string) => {
    history.push(`/org/locations/${location_id}/staff/${id}`);
  };

  const handleEdit = (id: string) => {
    history.push(`/org/locations/${location_id}/staff/${id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Locations', link: `/org/locations` },
        { label: location.name, link: `/org/locations/${location_id}` },
        { label: 'Staff' },
      ]}>
      <ListLocationStaff
        location_id={location_id}
        handleCreate={handleCreate}
        handleView={handleView}
        handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(OrgListLocationStaff));
