import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { ReferralLocation, ReferralOrganisation } from './types';

const _selectReferralsInitQuery = (status: string) => ({
  referrals: params(
    { 
      where: {
        status: { _eq: rawString(status) },
      }
    },
    [{
      id: types.string,
      referral: types.string,
      organisation: types.custom<ReferralOrganisation>(),
      locations: types.custom<ReferralLocation[]>(),
      note: types.string,
      status: types.string,
      created_at: types.string,
    }],
  ),
});

export const selectReferralsInitQuery = (status: string) => gql`${query(_selectReferralsInitQuery(status))}`;
