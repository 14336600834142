import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import GridCard from '../../../components/Grid/GridCard';

import { homeInitQuery } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const Home = ({ classes, history }: Props): React.ReactElement => {
  const [organisationsCount, setOrganisationsCount] = useState<number>(0);

  const { data: homeInitData } = useQuery(homeInitQuery, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData) {
      setOrganisationsCount(homeInitData.organisations_aggregate.aggregate.count);
    }
    return () => { mounted = false; };
  }, [homeInitData]);

  const handleAction = (link: string) => {
    history.push(link);
  }

  const actions = [
    {
      title: 'Organisations',
      counter: organisationsCount,
      createLink: '/admin/organisations/create',
      viewLink: '/admin/organisations',
    },
    {
      title: 'Device Apps',
      viewLink: '/admin/organisations/device-apps',
    },
  ];

  return (
    <Dashboard title="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            fill
            gridSize={5}
            key={action.title}
            title={action.title}
            counter={action.counter}
            editLabel="Create"
            handleEdit={action.createLink ? () => handleAction(action.createLink) : undefined}
            handleView={() => handleAction(action.viewLink)} />
        ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(Home));
