import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../../../layouts/Dashboard';
import CreateVoucherCode from '../../../../../../components/Global/Vouchers/Create';

import { createVoucherCodeInitQuery, Location } from './queries';

interface Props extends RouteComponentProps {

}

interface UrlParams {
  organisation_id: string,
  location_id: string,
}

const CreateVoucherAdmin = ({ history }: Props): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
      categories: [],
    },
    voucher_codes: [],
  });

  const { data: createVoucherInitData } = useQuery(createVoucherCodeInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createVoucherInitData) {
      setLocation(createVoucherInitData.locations_by_pk);
    }
    return () => { mounted = false; };
  }, [createVoucherInitData]);

  const handleFinish = () => {
    history.push(`/admin/organisations/${organisation_id}/locations/${location_id}/vouchers`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: location.organisation.name, link: '/admin/organisations' },
          { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
          { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
          { label: 'Vouchers', link: `/admin/organisations/${organisation_id}/locations/${location_id}/vouchers` },
          { label: 'Create' },
        ]}>
        <CreateVoucherCode
          organisation_id={organisation_id}
          location_id={location_id}
          categories={location.organisation.categories}
          used_codes={location.voucher_codes}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(CreateVoucherAdmin);
