import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {},
  fields: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  relationships: {
    width: '100%',
    // padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  field: {
    padding: theme.spacing(1),
  },
  fieldType: {
    marginLeft: theme.spacing(4),
  },
  aggregateButon: {
    marginLeft: theme.spacing(4),
  }
});

export default styles;
