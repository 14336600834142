import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';


import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import { ListReports } from '../../../../components/Global/CustomReports/Reports';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const Reports = ({ classes, history }: Props): React.ReactElement => {
  return (
    <Dashboard title="Reports">
      <ListReports />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(Reports));
