
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _selectLocationInitQuery = (location_id: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    {
      name: types.string,
    },
  ),
});

type X = Unpacked<typeof _selectLocationInitQuery>
export type Location = X['locations_by_pk'];


export const selectLocationInitQuery = (location_id: string) => gql`${query(_selectLocationInitQuery(location_id))}`;
