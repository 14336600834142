import React, { useEffect, useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { categoryReducer, CategoryReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { updateCategory } from './mutations';

import styles from './styles';
import { editCategoryInitQuery } from './queries';
import { useQuery } from '@apollo/client';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  category_id: string,
  handleFinish: (id: string) => void,
}

const EditCategory = ({ classes, history, category_id, handleFinish }: Props): React.ReactElement => {
  const [category, dispatchCategory] = useReducer(categoryReducer, {
    name: '',
    colour_id: '',
    active: true,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editCategoryInitData } = useQuery(editCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editCategoryInitData) {
      dispatchCategory({ type: CategoryReducerAction.INIT, value: editCategoryInitData.categories_by_pk });
    }
    return () => { mounted = false; };
  }, [editCategoryInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateCategoryVariables = {
      pk_columns: {
        id: category_id,
      },
      set: category,
    };

    await updateCategory(updateCategoryVariables);

    setSaving(false);
    handleFinish(category_id);
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        category={category}
        dispatch={dispatchCategory}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Category" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditCategory));
