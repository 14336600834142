import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import Dashboard from '../../../../layouts/Dashboard';
import EditMenu from '../../../../components/Global/Menus/Edit';

import { editMenuInitQuery, Menu } from './queries';

interface Props extends RouteComponentProps {}

interface UrlParams {
  menu_id: string,
}

const EditMenuOrg = ({ history }: Props): React.ReactElement => {
  const { menu_id } = useParams<UrlParams>();

  const [menu, setMenu] = useState<Menu>({
    name: '',
  });

  const { data: editMenuInitData } = useQuery(editMenuInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editMenuInitData) {
      setMenu(editMenuInitData.menus_by_pk);
    }
    return () => { mounted = false; };
  }, [editMenuInitData]);

  const handleFinish = (id: string) => {
    history.push(`/org/menus/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Menus', link: `/org/menus` },
          { label: menu.name, link: `/org/menus/${menu_id}` },
          { label: 'Edit' },
        ]}>
        <EditMenu
          menu_id={menu_id}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(EditMenuOrg);
