import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';
import { booleanToYesNo } from '../../../../utils/stringUtils';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';

import { selectLocationUserInitQuery, LocationUser } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  location_user_id: string,
  handleEdit: () => void,
}

const ViewLocationUser = ({ classes, history, location_user_id, handleEdit }: Props): React.ReactElement => {
  const [user, setUser] = useState<LocationUser | UnknownObject>({});

  const { data: locationUserInitQuery } = useQuery(selectLocationUserInitQuery(location_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitQuery) {
      setUser(locationUserInitQuery.users_location_by_pk);
    }
    return () => { mounted = false; };
  }, [locationUserInitQuery]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <ReadOnlyBlock
            gridSize={6}
            title={user.full_name}
            items={user ? [
              { label: 'Email', value: user.email },
              { label: 'User type', value: user.enum_user_type?.description },
              { label: 'Active', value: booleanToYesNo(user.active) },
            ] : []}
            handleEdit={handleEdit} />
        </>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewLocationUser));
