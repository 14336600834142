import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
} from '@material-ui/core';

import CreateReportView from '../../../../components/Global/CustomReports/Create';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const CreateReport = ({ classes, history }: Props): React.ReactElement => (<CreateReportView />);

export default withRouter(withStyles(styles)(CreateReport));
