import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import EditLocationUser from '../../../../components/Global/LocationUsers/Edit';

import { editLocationUserInitQuery, LocationUser } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  location_user_id: string,
}

const OrgEditLocationUser = ({ classes, history }: Props): React.ReactElement => {
  const { location_user_id } = useParams<UrlParams>();

  const [user, setUser] = useState<LocationUser>({
    full_name: '',
    location: {
      has_staff: false,
      users: [],
    }
  });

  const { data: editLocationUserInitQueryData } = useQuery(editLocationUserInitQuery(location_user_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationUserInitQueryData) {
      setUser(editLocationUserInitQueryData.users_location_by_pk);
    }
    return () => { mounted = false; };
  }, [editLocationUserInitQueryData]);


  const handleFinish = () => {
    history.push(`/loc/users/${location_user_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Users', link: `/loc/users` },
          { label: user.full_name, link: `/loc/users/${location_user_id}` },
          { label: 'Edit' },
        ]}>
        <EditLocationUser
          location_user_id={location_user_id}
          pinEnabled={user.location.has_staff}
          usedPins={user.location.users.filter((i) => i.pin).map((i) => i.pin)}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(OrgEditLocationUser));
