import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { locationReducer, LocationReducerAction, LocationType } from '../Common/';
import Steps from '../Common/components/Steps';

import { editLocationInitQuery } from './queries';
import { updateLocation } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  location_id: string,
  handleFinish: () => void,
}

const EditLocation = ({ classes, history, location_id, handleFinish }: Props): React.ReactElement => {

  const [locationTypes, setLocationTypes] = useState<LocationType[]>([]);
  const [location, dispatchLocation] = useReducer(locationReducer, {
    name: '',
    email: '',
    phone: '',
    website: '',
    tax_number: '',
    number_of_tables: 0,
    location_type: '',
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_country: '',
    address_postcode: '',
    tax_rate: 0,
    currency: '', 
    locale: '',
    can_manage_menus: false,
    has_kitchen_printer: false,
    has_bar_printer: false,
    has_staff: false,
    has_station_printers: false,
    service_charge: null,
    idle_timeout: 30,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editLocationInitData } = useQuery(editLocationInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationInitData) {
      dispatchLocation({ type: LocationReducerAction.INIT, value: editLocationInitData.locations_by_pk });
      setLocationTypes(editLocationInitData.enum_location_types);
    }
    return () => { mounted = false; };
  }, [editLocationInitData]);

  const handleSave = async () => {
    setSaving(true);

    const updateLocationVariables = {
      pk_columns: {
        id: location_id,
      },
      set: location,
    };
    await updateLocation(updateLocationVariables);

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        location={location}
        locationTypes={locationTypes}
        dispatch={dispatchLocation}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditLocation));
