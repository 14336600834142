import React from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

const Logo = (): React.ReactElement => {
  const [darkMode] = useLocalStorage<boolean>('dark-mode', false);

  const textColour = darkMode ? '#FFFFFF' : '#020202';

  return (
    <svg id="APOS_LOGO" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1650 320">
      <path style={{ fill: '#EE8E01' }} d="M160.21,311.72C79.49,312.41,9.56,246.97,9.79,161.1C10.01,77.34,76.27,10.98,160.13,10.86c85.17-0.12,150.96,69.15,150.42,151.7C310.02,244.8,242.44,312.38,160.21,311.72z M160.14,282.7c65.06,1.02,121.2-53.29,121.48-120.7c0.28-66.16-52.92-121.95-121.2-122.29C93.76,39.37,38.5,94.45,38.6,160.93C38.71,230.47,95.73,283.62,160.14,282.7z"/>
      <path style={{ fill: '#95C01F' }} d="M264.55,161.37c0.76,56.65-45.69,105.43-105.16,105.14c-57.6-0.27-104.74-45.83-104.65-105.29c0.1-60.65,49.47-105.1,105.54-104.9C217.75,56.53,265.37,104.34,264.55,161.37z"/>
      <path style={{ fill: textColour }} d="M1161.17,279.49c-13.24,0-25.71,0-38.51,0c0-57.51,0-114.93,0-172.65c11.75,0,23.68,0,36.29,0c0,4.17,0,8.5,0,13.8c3.01-2.74,5.06-4.82,7.32-6.63c21.12-16.89,52.7-13.52,71.01,7.55c21.22,24.4,19.52,67.09-3.14,90.11c-20.25,20.57-56.72,19.42-72.03,0.12c-0.05-0.06-0.31,0.05-0.94,0.17C1161.17,234.24,1161.17,256.63,1161.17,279.49z	M1214.68,164.42c0.01-17.3-11.16-29.66-26.84-29.7c-15.7-0.05-28.39,13.37-28.32,29.94c0.06,16.4,12.61,29.71,28.02,29.74C1203.44,194.43,1214.67,182.03,1214.68,164.42z"/>
      <path style={{ fill: textColour }} d="M1334.9,103.22c27.34,1.15,49.68,10.79,60.34,36.99c16.9,41.54-8.98,85.59-57.11,87.34c-21.45,0.78-40.27-5.79-53.99-22.81c-15.29-18.96-17.67-40.74-9.32-63.1c8.09-21.66,25.16-33.17,47.55-37.1C1326.61,103.8,1330.95,103.62,1334.9,103.22z M1335.42,195.4c15.87-0.01,27.84-13.2,27.59-30.37c-0.25-16.72-12.11-29.3-27.65-29.31c-16.1-0.02-27.41,12.31-27.33,29.79C1308.1,182.86,1319.61,195.41,1335.42,195.4z"/>
      <path style={{ fill: textColour }} d="M891.6,107.28c12.53,0,24.64,0,37.11,0c0,4.99,0,9.73,0,15.95c5.4-8.24,11.51-13.58,19.46-16.68c21.55-8.43,51.28-0.91,57.28,31.26c0.4,2.12,0.93,4.27,0.93,6.41c0.06,26.29,0.04,52.58,0.04,79.12c-12.78,0-25.52,0-38.76,0c0-1.74,0-3.35,0-4.96c0-19.66,0.05-39.33-0.05-58.99c-0.02-3.47-0.41-6.98-1.1-10.38c-1.37-6.68-4.93-11.72-12.08-12.96c-7.4-1.29-13.93,0.58-18.71,6.71c-3.94,5.05-5.4,10.94-5.39,17.29c0.03,19.33,0.01,38.66,0.01,57.99c0,1.64,0,3.28,0,5.23c-13,0-25.72,0-38.74,0C891.6,184.73,891.6,146.17,891.6,107.28z"/>
      <path style={{ fill: textColour }} d="M837.3,186.42c8.65,5.45,17.36,10.93,26.47,16.67c-6.46,9.5-15.08,15.81-25.5,19.89c-25.86,10.13-58.35,4.48-75.72-14.12c-17.52-18.76-19.98-41.36-12.5-64.79c7.2-22.56,23.92-35.35,47.09-39.56c11.65-2.11,23.29-1.66,34.44,2.56c21.88,8.28,32.64,25.23,35.8,47.59c0.95,6.68,0.82,13.51,1.21,20.7c-28.77,0-56.68,0-85.15,0c1.47,10.23,7.1,16.54,15.78,20.42C811.98,201.49,823.72,198.58,837.3,186.42z M832.41,151.41c-0.28-13.49-8.89-21.67-22.4-21.69c-14.37-0.03-25.83,9.43-26.16,21.69C799.92,151.41,815.99,151.41,832.41,151.41z"/>
      <path style={{ fill: textColour }} d="M488.47,223.4c-11.9,0-23.32,0-35.02,0c0-3.78,0-7.38,0-10.94c-0.56-0.07-1.04-0.25-1.12-0.13c-12.86,18.06-44.7,17.81-60.98,6.48c-11.76-8.18-15.34-19.91-13.98-33.63c1.33-13.36,9.94-21.06,21.12-26.28c14.64-6.84,30.48-7.79,46.33-8.33c2-0.07,4-0.01,6-0.06c0.3-0.01,0.58-0.26,0.88-0.4c0.12-10.8-6.33-16.79-18.55-17.38c-10.12-0.49-18.89,3.06-26.97,8.76c-1.35,0.95-2.65,1.99-4.15,3.12c-6.41-6.91-12.72-13.72-19.96-21.52c5.76-3.91,10.85-8.18,16.62-11.11c18.42-9.34,37.91-10.96,57.8-5.89c18.22,4.64,27.01,18.02,29.84,35.52c1.58,9.8,1.88,19.87,2.06,29.83C488.7,188.57,488.47,205.74,488.47,223.4z M452.86,172.17c-7.7,0.66-15.47,0.98-23.11,2.11c-3.83,0.57-7.65,2.2-11.09,4.07c-4.44,2.41-6.65,6.58-5.86,11.7c0.82,5.4,4.54,8.27,9.62,9.54C441.16,204.26,454.48,192.66,452.86,172.17z"/>
      <path style={{ fill: textColour }} d="M1513.76,120.62c-7.71,6.85-15.28,13.57-23.01,20.43c-6.07-5.16-14.05-9.78-23.99-9.26c-2.77,0.14-5.97,0.44-8.11,1.91c-2.29,1.58-4.75,4.56-4.9,7.07c-0.13,2.18,2.37,5.65,4.56,6.61c5.28,2.31,11.07,3.43,16.62,5.13c5.39,1.65,10.97,2.91,16.05,5.26c11.14,5.14,19.35,13.02,20.58,26.07c1.38,14.56-3.19,26.6-15.72,34.91c-11.62,7.71-24.74,9.41-38.23,8.78c-15.59-0.73-30.17-4.76-42.71-14.58c-0.92-0.72-1.8-1.48-2.65-2.28c-0.35-0.33-0.57-0.79-1.16-1.63c7-7.43,14.03-14.89,21.1-22.4c3.72,2.68,7.12,5.57,10.93,7.77c7.4,4.29,15.35,6.46,23.91,4.05c4-1.12,7.38-3.05,7.68-7.98c0.31-5.05-2.49-7.81-6.78-9.22c-5.83-1.91-11.79-3.45-17.67-5.21c-3.5-1.05-7.03-2.04-10.43-3.36c-12.22-4.74-20.54-12.95-21.92-26.53c-1.39-13.69,3.38-25.04,14.81-32.88c16.73-11.48,35.22-11.77,54.17-7.22C1496.72,108.44,1505.64,112.72,1513.76,120.62z"/>
      <path style={{ fill: textColour }} d="M620.29,119.02c-7.53,8.72-14.79,17.14-22.34,25.89c-6.79-6.64-14.74-10.28-24.23-8.98c-6.31,0.86-11.59,3.95-15.7,8.89c-10.1,12.15-9.96,31.14,0.31,42.2c10.31,11.09,25.21,11.2,40.69,0.11c6.94,8.41,13.89,16.85,21.09,25.57c-7.66,7.39-16.73,11.13-26.58,13.18c-18.01,3.73-35.55,2.33-51.84-6.58c-22.17-12.12-33.57-36.69-29.73-62.65c3.92-26.48,21.13-45.47,46.52-51.12c19.08-4.24,37.36-2.33,54.36,7.89C615.36,114.92,617.58,116.96,620.29,119.02z"/>
      <path style={{ fill: textColour }} d="M716.03,187.12c6.94,8.41,13.89,16.84,21.08,25.55c-7.76,7.56-17.01,11.42-27.04,13.3c-22.04,4.12-43.15,1.8-60.74-13.21c-20.58-17.57-25.06-40.78-18.21-65.84c6.73-24.61,24.62-37.98,49.25-42.33c17.43-3.08,34.13-0.54,49.46,8.8c2.53,1.54,4.76,3.56,7.48,5.62c-7.52,8.72-14.78,17.13-22.34,25.89c-6.79-6.63-14.73-10.28-24.22-8.98c-6.31,0.86-11.59,3.94-15.7,8.88c-10.11,12.15-9.98,31.14,0.3,42.2C685.63,198.1,700.62,198.22,716.03,187.12z"/>
      <path style={{ fill: textColour }} d="M1103.09,195.2c0,9.38,0,18.49,0,28.13c-14.25,4.08-28.81,5.41-43.15-0.22c-14.93-5.86-19.63-18.71-20.16-33.27c-0.59-16.14-0.23-32.31-0.28-48.47c-0.01-1.8,0-3.6,0-5.69c-6.28,0-12.22,0-18.5,0c0-9.7,0-19.1,0-28.91c5.82,0,11.74,0,18.19,0c0-10.46,0-20.52,0-30.83c12.8,0,25.07,0,37.87,0c0,9.95,0,20,0,30.4c9.28,0,18.04,0,27.13,0c0,9.74,0,19.15,0,29.08c-8.68,0-17.56,0-26.66,0c0,16.97-0.34,33.42,0.14,49.85c0.27,9.08,6.41,13.16,16.44,12.03C1096.86,196.99,1099.55,196.05,1103.09,195.2z"/>
      <path style={{ fill: textColour }} d="M1536.36,108.6c0.02-30,23.71-53.1,54.44-53.08c28.68,0.02,52.39,23.98,52.58,53.13c0.19,29.69-23.82,52.94-54.64,52.91C1559.65,161.53,1536.34,137.95,1536.36,108.6z M1564.31,138.38c0-21.12,0-41.87,0-61.85c12.75,0,25.31-1.01,37.63,0.28c12.52,1.31,19.08,11.53,17.19,23.08c-1.09,6.64-4.76,11.16-10.84,14.02c4.85,8.39,9.54,16.51,14.34,24.81c17.75-20.12,15.12-49.8-5.32-65.6c-20.71-16-50.42-11.14-64.87,10.6c-14.27,21.49-7.3,50.7,15.02,63.7c21.03,12.25,46.51,3.75,54.24-8.87c-6.42,0-12.73-0.23-19.01,0.09c-3.24,0.17-4.71-1.09-5.88-3.85c-2.27-5.34-4.72-10.62-7.37-15.79c-0.62-1.21-2.32-1.85-4.56-3.53c0,8.83,0,15.78,0,22.9C1577.95,138.38,1571.32,138.38,1564.31,138.38z M1585.25,101.36c4.28,0.11,8.7,1.24,11.33-3.15c0.64-1.06,0.27-4.08-0.36-4.32c-3.44-1.31-7.1-2.04-10.97-3.04C1585.25,95.28,1585.25,98.23,1585.25,101.36z"/>
    </svg>
  );
};

export default Logo;
