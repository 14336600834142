import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {},
  locationContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  locationTitle: {
    marginBottom: theme.spacing(1),
  }
});

export default styles;
