import React from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import EditMenu from '../../../../components/Global/Menus/Edit';
import Dashboard from '../../../../layouts/Dashboard';


interface Props extends RouteComponentProps {
  
}

interface UrlParams {
  menu_id: string,
}

const LocEditMenu = ({ history }: Props): React.ReactElement => {
  const { menu_id } = useParams<UrlParams>();

  const handleFinish = (id: string) => {
    history.push(`/loc/menus/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Menus', link: `/loc/menus` },
          { label: 'Edit' },
        ]}>
        <EditMenu
          menu_id={menu_id}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(LocEditMenu);
