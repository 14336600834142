import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import CreateLocationStaff from '../../../../../components/Global/LocationStaff/Create';

import { createLocationUserInitQuery, Location } from './queries';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  location_id: string,
}

const OrgCreateLocationStaff = ({ classes, history }: Props): React.ReactElement => {
  const { location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({ 
    name: '',
    organisation: {
      name: '',
    },
    users: [],
  });

  const { data: locationUserInitData } = useQuery(createLocationUserInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUserInitData) {
      setLocation(locationUserInitData.locations_by_pk);
    }
    return () => { mounted = false; };
  }, [locationUserInitData]);

  const handleFinish = (id: string) => {
    history.push(`/org/locations/${location_id}/staff/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Locations', link: `/org/locations` },
          { label: location.name, link: `/org/locations/${location_id}` },
          { label: 'Staff', link: `/org/locations/${location_id}/staff` },
          { label: 'Create' },
        ]}>
        <CreateLocationStaff
          location_id={location_id}
          usedPins={location.users.filter((i) => i.pin).map((i) => i.pin)}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(OrgCreateLocationStaff));
