import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateLocationPrinterMutation = gql`
  mutation ($pk_columns: location_devices_pk_columns_input!, $set: location_devices_set_input!, $append: location_devices_append_input!) {
    update_location_devices_by_pk(pk_columns: $pk_columns, _set: $set, _append: $append) {
      id
    }
  }
`;

export const updateLocationPrinter = async (variables: object) => {
  return await gqlClient.mutate<{
    update_users_location_by_pk: {
      id: string,
    }
  }>({ mutation: updateLocationPrinterMutation!, variables });
}
