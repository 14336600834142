import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertOrganisationMutation = gql`
  mutation ($object: organisations_insert_input!) {
    insert_organisations_one(object: $object) {
      id
    }
  }
`;

export const createOrganisation = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_organisations_one: {
      id: string,
    }
  }>({ mutation: insertOrganisationMutation!, variables });
  return res.data?.insert_organisations_one.id;
}
