import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';

import CustomBackdrop from '../../../CustomBackdrop';
import { EnumReportOutputCalc, EnumReportOutputType, ReportData, ReportDataHeader, reportReducer } from '../Common';
import Steps from '../Common/components/Steps';

import { createReport } from './mutations';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const CreateReport = ({ classes, history }: Props): React.ReactElement => {
  const [report, dispatchReport] = useReducer(reportReducer, {
    name: '',
    table: '',
    filters: [],
    fields: [],
  });

  const [reportData, setReportData] = useState<ReportData>({
    table: '',
    name: '',
    filters: [],
    headers: [],
    returns: [],
    totals: [],
    output: {
      calc: EnumReportOutputCalc.DATA,
      type: EnumReportOutputType.DATA,
    },
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const insertReportVariables = {
      object: {
        name: report.name,
        schema: reportData,
      }
    };

    const report_id: string | undefined = await createReport(insertReportVariables);
    if (report_id) {
      setSaving(false);
      handleFinish(report_id);
    }

  };

  const handleFinish = (id: string) => {
    history.push(`/admin/reports/${id}`);
  };

  const updateReportData = useCallback(() => {
    const { name, table } = report;
    const headers: ReportDataHeader[] = [];

    report.fields.forEach((f) => {
      headers.push({
        key: f.key,
        label: f.label,
      });
    });

    setReportData(i => ({
      ...i,
      name: name,
      table,
      headers,
    }));
  }, [report]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      updateReportData();
    }
    return () => { mounted = false; };
  }, [report, updateReportData]);

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Reports', link: '/admin/reports' },
          { label: 'Create' },
        ]}>
        <Steps
          completeLabel="Create"
          report={report}
          reportData={reportData}
          dispatch={dispatchReport}
          handleSave={handleSave} />
      </Dashboard>
      {saving && <CustomBackdrop label="Creating Report" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateReport));
