import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
});

export default styles;
