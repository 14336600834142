import React, { useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';

import { timeToFormattedString } from '../../../../utils/stringUtils';

import { menuReducer, MenuReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { createMenu } from './mutations';
import { getMenuNextOrderIndex, getOrgMenuNextOrderIndex } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  location_id?: string,
  handleFinish: (id: string) => void,
}

const CreateMenu = ({ classes, organisation_id, location_id, handleFinish, history }: Props): React.ReactElement => {
  const defaultData = {
    name: '',
    description: '',
    active: true,
    all_day: true,
    menu_types: [],
    available_from: '00:00:00:000',
    available_to: '23:59:59:999',
  };
  
  const [menu, dispatchMenu] = useReducer(menuReducer, {...defaultData});

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async (another: boolean) => {
    setSaving(true);

    const order_index: number = organisation_id ?  await getOrgMenuNextOrderIndex({ organisation_id }) : await getMenuNextOrderIndex();

    const data: UnknownObject = { ...menu };
    delete data.all_day;
    data.available_from = `${timeToFormattedString(data.available_from, 'HMS')}`;
    data.available_to = `${timeToFormattedString(data.available_to, 'HMS')}`;

    const insertMenuVariables = {
      object: {
        ...data,
        order_index: order_index + 1,
        organisation_id,
      },
    };
    const menu_id: string | undefined = await createMenu(insertMenuVariables);
    if (menu_id) {
      setSaving(false);
      if(another) {
        dispatchMenu({ type: MenuReducerAction.INIT, value: defaultData });
      } else {
        handleFinish(menu_id);
      }
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        menu={menu}
        dispatch={dispatchMenu}
        handleSave={() => handleSave(false)} 
        handleAnother={() => handleSave(true)}/>
      {saving && <CustomBackdrop label="Creating Menu" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateMenu));
