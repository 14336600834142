import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    // overflow: 'auto',
    // [theme.breakpoints.up('md')]: {
    //   overflow: 'unset',
    // },
    // display: 'flex',
    width: '100%',
    height: '100%',
    fontFamily: 'Roboto',
    backgroundColor: theme.palette.background.paper,
  },
});

export default styles;
