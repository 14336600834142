
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';
import { ServiceCharge } from '../Common';

const _selectLocationInitQuery = (location_id: string) => ({
  locations_by_pk: params(
    { id: rawString(location_id) },
    {
      id: types.string,
      name: types.string,
      email: types.string,
      phone: types.string,
      website: types.string,
      tax_number: types.string,
      currency: types.string,
      locale: types.string,
      number_of_tables: types.number,
      location_type: types.string,
      address_line_1: types.string,
      address_line_2: types.string,
      address_city: types.string,
      address_country: types.string,
      address_postcode: types.string,
      can_manage_menus: types.boolean,
      has_kitchen_printer: types.boolean,
      has_bar_printer: types.boolean,
      has_staff: types.boolean,
      idle_timeout: types.number,
      has_station_printers: types.boolean,
      service_charge: types.custom<ServiceCharge | null>(),
      integrations: {
        vouchers: types.boolean,
      },
      enum_location_type: {
        description: types.string,
      },
      voucher_codes_aggregate: {
        aggregate: {
          count: types.number,
        },
      },
    },
  ),
});

type X = Unpacked<typeof _selectLocationInitQuery>
export type Location = X['locations_by_pk'];


export const selectLocationInitQuery = (location_id: string) => gql`${query(_selectLocationInitQuery(location_id))}`;
