import { v4 as uuidv4 } from 'uuid';
import { ValidationType } from '../../../../react-app-env';
import { validateEmailAddress } from '../../../../utils/validators';

export interface Referral {
  referral: string,
  organisation: ReferralOrganisation,
  locations: ReferralLocation[],
  note: string,
}

export interface ReferralOrganisation {
  name: string,
  contact_name: string,
  contact_phone: string,
  contact_email: string,
  singleLocation: boolean,
}
export interface ReferralLocation {
  id: string,
  name: string,
  type: string,
  website: string,
  contact_name: string,
  contact_phone: string,
  contact_email: string,
  address_line_1: string,
  address_line_2: string,
  address_city: string,
  address_country: string,
  address_postcode: string,
}

export interface ValidationErrors {
  organisation: ValidationErrorsOrganisation,
  location: ValidationErrorsLocation,
}

export interface ValidationErrorsOrganisation {
  name: ValidationType,
  contact_phone: ValidationType,
  contact_email: ValidationType,
  contact_name: ValidationType,
}

export interface ValidationErrorsLocation {
  name: ValidationType,
  contact_name: ValidationType,
  contact_phone: ValidationType,
  contact_email: ValidationType,
  address_line_1: ValidationType,
  address_city: ValidationType,
  address_postcode: ValidationType,
}

export const validationErrorsOrganisation: ValidationErrorsOrganisation = {
  name: 'Please provide a name',
  contact_phone: 'Please provide a contact phone number',
  contact_email: 'Please provide a valid contact email address',
  contact_name: 'Please provide a contact name',
}

export const validationErrorsLocation: ValidationErrorsLocation = {
  name: 'Please provide a name',
  contact_name: 'Please provide a contact name',
  contact_phone: 'Please provide a contact phone number',
  contact_email: 'Please provide a valid contact email address',
  address_line_1: 'Please provide an address',
  address_city: 'Please provide a city',
  address_postcode: 'Please provide a postcode',
}
export interface Validation {
  organisation: {
    name: (value: string) => ValidationType,
    contact_phone: (value: string) => ValidationType,
    contact_email: (value: string) => ValidationType,
    contact_name: (value: string) => ValidationType,
  },
  locations: (value: ReferralLocation[]) => ValidationType,
  singleLocation: (value: ReferralLocation) => ValidationType,
  location: {
    name: (value: string) => ValidationType,
    contact_name: (value: string) => ValidationType,
    contact_phone: (value: string) => ValidationType,
    contact_email: (value: string) => ValidationType,
    address_line_1: (value: string) => ValidationType,
    address_city: (value: string) => ValidationType,
    address_postcode: (value: string) => ValidationType,
  },
}

export const validation: Validation = {
  organisation: {
    name: (value: string) => !value ? validationErrorsOrganisation.name : false,
    contact_phone: (value: string) => !value ? validationErrorsOrganisation.contact_phone : false,
    contact_email: (value: string) => !value || !validateEmailAddress(value) ? validationErrorsOrganisation.contact_email : false,
    contact_name: (value: string) => !value ? validationErrorsOrganisation.contact_name : false,
  },
  locations: (locations: ReferralLocation[]) => {
    return locations.every((i) =>
      validation.location.name(i.name) === false &&
      validation.location.contact_name(i.contact_name) === false &&
      validation.location.contact_phone(i.contact_phone) === false &&
      validation.location.contact_email(i.contact_email) === false &&
      validation.location.address_line_1(i.address_line_1) === false &&
      validation.location.address_city(i.address_city) === false &&
      validation.location.address_postcode(i.address_postcode) === false
    );
  },
  singleLocation: (location: ReferralLocation) => {
    return validation.location.name(location.name) === false &&
    validation.location.contact_name(location.contact_name) === false &&
    validation.location.contact_phone(location.contact_phone) === false &&
    validation.location.contact_email(location.contact_email) === false &&
    validation.location.address_line_1(location.address_line_1) === false &&
    validation.location.address_city(location.address_city) === false &&
    validation.location.address_postcode(location.address_postcode) === false;
  },
  location: {
    name: (value: string) => !value ? validationErrorsLocation.name : false,
    contact_name: (value: string) => !value ? validationErrorsLocation.contact_name : false,
    contact_phone: (value: string) => !value ? validationErrorsLocation.contact_phone : false,
    contact_email: (value: string) => !value|| !validateEmailAddress(value) ? validationErrorsLocation.contact_email : false,
    address_line_1: (value: string) => !value ? validationErrorsLocation.address_line_1 : false,
    address_city: (value: string) => !value ? validationErrorsLocation.address_city : false,
    address_postcode: (value: string) => !value ? validationErrorsLocation.address_postcode : false,
  },
}

export enum ReferralReducerAction {
  NOTE,
  ORGANISATION_NAME,
  ORGANISATION_CONTACT_PHONE,
  ORGANISATION_CONTACT_EMAIL,
  ORGANISATION_CONTACT_NAME,
  ORGANISATION_SINGLE_LOCATION,
  ADD_LOCATION,
  DELETE_LOCATION,
  LOCATION_NAME,
  LOCATION_CONTACT_NAME,
  LOCATION_CONTACT_PHONE,
  LOCATION_CONTACT_EMAIL,
  LOCATION_ADDRESS_LINE_1,
  LOCATION_ADDRESS_LINE_2,
  LOCATION_ADDRESS_CITY,
  LOCATION_ADDRESS_POSTCODE,
}

export const referralReducer = (state: Referral, action: { type: ReferralReducerAction, value: any }): Referral =>  {
  switch (action.type) {
    case ReferralReducerAction.NOTE:
      return { ...state, note: action.value };
    case ReferralReducerAction.ORGANISATION_NAME:
      return { ...state, organisation: {
        ...state.organisation,
        name: action.value,
      }};
    case ReferralReducerAction.ORGANISATION_CONTACT_NAME:
      if (state.organisation.singleLocation) {
        state.locations[0].contact_name = action.value;
      }
      return { ...state, organisation: {
        ...state.organisation,
        contact_name: action.value,
      }};
    case ReferralReducerAction.ORGANISATION_CONTACT_PHONE:
      if (state.organisation.singleLocation) {
        state.locations[0].contact_phone = action.value;
      }
      return { ...state, organisation: {
        ...state.organisation,
        contact_phone: action.value,
      }};
    case ReferralReducerAction.ORGANISATION_CONTACT_EMAIL:
      if (state.organisation.singleLocation) {
        state.locations[0].contact_email = action.value;
      }
      return { ...state, organisation: {
        ...state.organisation,
        contact_email: action.value,
      }};
    case ReferralReducerAction.ORGANISATION_SINGLE_LOCATION:
      const singleLocation = action.value as boolean;
      if (singleLocation) {
        state.locations[0].contact_name = state.organisation.contact_name;
        state.locations[0].contact_email = state.organisation.contact_email;
        state.locations[0].contact_phone = state.organisation.contact_phone;
      } else {
        state.locations[0].contact_name = '';
        state.locations[0].contact_email = '';
        state.locations[0].contact_phone = '';
      }
      return { ...state, organisation: {
        ...state.organisation,
        singleLocation,
      }};
    case ReferralReducerAction.ADD_LOCATION:
      return { ...state, locations: [
        ...state.locations,
        {  
          id: uuidv4(),
          name: '',
          type: 'FREE_TRIAL_14',
          website: '',
          contact_name: '',
          contact_phone: '',
          contact_email: '',
          address_line_1: '',
          address_line_2: '',
          address_city: '',
          address_country: 'United Kingdom',
          address_postcode: '',
        }
      ]};
    case ReferralReducerAction.DELETE_LOCATION:
      state.locations = state.locations.filter((i) => i.id !== action.value.id);
      return { ...state };
    case ReferralReducerAction.LOCATION_NAME:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        name: action.value.value,
      }
      return { ...state };
    case ReferralReducerAction.LOCATION_CONTACT_NAME:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        contact_name: action.value.value,
      }
      return { ...state };
    case ReferralReducerAction.LOCATION_CONTACT_PHONE:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        contact_phone: action.value.value,
      }
      return { ...state };
    case ReferralReducerAction.LOCATION_CONTACT_EMAIL:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        contact_email: action.value.value,
      }
      return { ...state };
    case ReferralReducerAction.LOCATION_ADDRESS_LINE_1:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        address_line_1: action.value.value,
      }
      return { ...state };
    case ReferralReducerAction.LOCATION_ADDRESS_LINE_2:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        address_line_2: action.value.value,
      }
      return { ...state };
    case ReferralReducerAction.LOCATION_ADDRESS_CITY:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        address_city: action.value.value,
      }
      return { ...state };
    case ReferralReducerAction.LOCATION_ADDRESS_POSTCODE:
      state.locations[action.value.index] = {
        ...state.locations[action.value.index],
        address_postcode: action.value.value,
      }
      return { ...state };
    default:
      throw new Error();
  }
}
