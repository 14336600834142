import { gql } from '@apollo/client';
import gqlClient from '../../../utils/apolloClient';

const _createReferralMutation = gql`
  mutation ($objects: [referrals_insert_input!]!) {
    insert_referrals(objects: $objects) {
      affected_rows
    }
  }
`;

export const createReferralMutation = async (variables: object) => {
  const res = await gqlClient.mutate<{
    insert_referrals: {
      affected_rows: number,
    }
  }>({ mutation: _createReferralMutation!, variables });
  return res.data?.insert_referrals.affected_rows;
}
