import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const deleteMenuMutation = gql`
  mutation ($id: uuid!) {
    delete_menus_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteMenu = async (variables: object) => {
  return await gqlClient.mutate<{
    delete_menus_by_pk: {
      id: string,
    }
  }>({ mutation: deleteMenuMutation!, variables });
}


const _updateCategoryOrderIndexMutation = gql`
  mutation ($objects: [categories_insert_input!]!) {
    insert_categories(
      objects: $objects, 
      on_conflict:{ 
        constraint: categories_pkey, 
        update_columns: [order_index]
        }
      ) {
      affected_rows
    }
  }
`;

export const updateCategoryOrderIndexMutation = async (variables: object) => {
  return await gqlClient.mutate<{
    insert_categories: {
      affected_rows: string,
    }
  }>({ mutation: _updateCategoryOrderIndexMutation!, variables });
}
