import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import UploadMenu from '../../../../../components/Global/Menus/Upload';

import { menusInitQuery, Organisation } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}
interface UrlParams {
  organisation_id: string,
}

const UploadMenuAdmin = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    locale: '',
    currency: '',
  });

  const { data: menusInitData } = useQuery(menusInitQuery(organisation_id));

  useEffect(() => {
    let mounted = true;
    if (mounted && menusInitData) {
      setOrganisation(menusInitData.organisations_by_pk);
    }
    return () => { mounted = false; };
  }, [menusInitData]);

  const handleFinish = () => {
    history.push(`/admin/organisations/${organisation_id}/menus`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Organisations', link: '/admin/organisations' },
        { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
        { label: 'Menus', link: `/admin/organisations/${organisation_id}/menus` },
        { label: 'Upload' },
      ]}>
        <UploadMenu
          organisation_id={organisation_id}
          locale={organisation.locale}
          currency={organisation.currency}
          handleFinish={handleFinish} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(UploadMenuAdmin));
