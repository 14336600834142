
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _editAdminUserInitQuery = (admin_user_id: string) => ({
  users_admin_by_pk: params(
    { id: rawString(admin_user_id) },
    {
      id: types.string,
      first_name: types.string,
      last_name: types.string,
      email: types.string,
    },
  ),
});

type X = Unpacked<typeof _editAdminUserInitQuery>
export type AdminUser = X['users_admin_by_pk'];

export const editAdminUserInitQuery = (admin_user_id: string) => gql`${query(_editAdminUserInitQuery(admin_user_id))}`;
