import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  withStyles,
} from '@material-ui/core';

import { EnumUserRole } from '../../../../../AuthProvider';
import ReadOnlyBlock from '../../../../../../components/ReadOnlyBlock';
import { LocationIntegration  } from '../..';

import styles from './styles';
import { booleanToYesNo } from '../../../../../../utils/stringUtils';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  integration: LocationIntegration,
  userRole: EnumUserRole | null,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, integration, userRole, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        gridSize={6}
        title="Payment"
        items={integration.payment.active ? [
          { label: 'Provider', value: integration.payment.provider || '' },
          integration.payment.accountId ? { label: 'Account ID', value: integration.payment.accountId } : null,
          integration.payment.username ? { label: 'Username', value: integration.payment.username } : null,
          integration.payment.password ? { label: 'Password', value: integration.payment.password } : null,
        ] : [{ label: 'Payments are not activated' }]}
        handleEdit={() => setStep(0)} />
      <ReadOnlyBlock
        gridSize={6}
        title="Deliverect"
        items={integration.deliverect.active ? [
          { label: 'Account ID', value: integration.deliverect.accountId },
        ] : [{ label: 'Deliverect is not activated' }]}
        handleEdit={() => setStep(1)} />
      <ReadOnlyBlock
        gridSize={6}
        title="Xero"
        items={integration.xero.active ? [
          { label: 'Xero account is authenticated', value: '' },
        ] : [{ label: 'Xero is not activated' }]}
        handleEdit={() => setStep(2)} />
      {userRole === EnumUserRole.ADMIN && (
        <ReadOnlyBlock
          title="Vouchers"
          gridSize={3}
          items={[
            { label: 'Active', value: booleanToYesNo(integration.vouchers) },
          ]}
          handleEdit={() => setStep(3)} />
      )}
    </>
  );
};

export default withStyles(styles)(StepReview);
