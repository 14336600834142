import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';

import { Category, ValidationErrors, CategoryReducerAction, validation } from '../..';
import ColourPicker from '../../../../ColourPicker';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  category: Category,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: CategoryReducerAction, value: any }>,
}

const StepDetails = ({ classes, category, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    switch (field) {
      case 'name':
        errorItems.name = validation.name(category.name);
        break;
      case 'colour_id':
        errorItems.colour_id = validation.name(category.colour_id);
        break;
      default:
        break;
    }
    setErrors({...errors, ...errorItems});
    return undefined;
  }, [category, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <TextField
          className={classes.textField}
          data-qa="name-textfield"
          required
          label="Name"
          variant="outlined"
          error={errors.name as boolean}
          helperText={errors.name}
          value={category.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryReducerAction.NAME, value: event.target.value })}
          onKeyUp={() => validate('name')}
          onBlur={() => validate('name')}/>

        <ColourPicker
          colour={category.colour_id}
          label="Category colour"
          error={errors.colour_id}
          onChange={(value: string) => dispatch({ type: CategoryReducerAction.COLOUR, value })}
          onBlur={() => validate('colour_id')} />

        <FormControl className={classes.switch} component="fieldset">
          <FormControlLabel
            label="Active"
            labelPlacement="end"
            control={
              <Switch
                checked={category.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: CategoryReducerAction.ACTIVE, value: event.target.checked as boolean })}
              />
            }/>
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
