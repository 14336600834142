import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';


import {
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';


import { GridActionCard, GridCard } from '../../../Grid';
import { booleanToYesNo } from '../../../../utils/stringUtils';

import styles from './styles';

interface Printer {
  id: string,
  name: string,
  device_config: {
    location: string,
    printCopyToTill: boolean,
    categories: {
      id: string,
    }[],
  },
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  printers: Printer[],
  handleCreate: () => void,
  handleEdit: (id: string) => void,
}

const ListLocationStaff = ({ classes, history, printers, handleCreate, handleEdit }: Props): React.ReactElement => {
  return (
    <>
    <Grid container spacing={4}>
      <GridActionCard 
          gridSize={2} 
          label='Add printer' 
          onClick={handleCreate} />
    </Grid>
      {[...new Set(printers.map((p) => p.device_config.location))].map((location) => (
        <div className={classes.locationContainer}>
          <Typography variant='h5' className={classes.locationTitle}>{location}</Typography>
          <Grid container spacing={4}>
            {printers.filter((p) => p.device_config.location === location).map((p) => (
              <GridCard 
                gridSize={2}
                title={p.name}
                subTitle={`${p.device_config.categories?.length ?? 0} Menu categories`}
                description={`Print copy to till? ${booleanToYesNo(p.device_config.printCopyToTill)}`}
                fill
                handleEdit={() => handleEdit(p.id)}
              />
          ))}
          </Grid>
        </div>
      ))}
    </>
  );
};

export default withRouter(withStyles(styles)(ListLocationStaff));
