import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomStepper from '../../../../../CustomStepper';
import { Category, validationErrors, ValidationErrors, CategoryReducerAction, validation } from '../..';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  category: Category,
  dispatch: React.Dispatch<{ type: CategoryReducerAction, value: any }>,
  handleSave: () => void,
  handleAnother?: () => void,
}

const CategorySteps = ({ classes, completeLabel, category, dispatch, handleSave, handleAnother }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    colour_id: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = validation.name(category.name);
      errorItems.colour_id = validation.colour_id(category.colour_id);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [category, errors]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}
        handleAnother={handleAnother} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                category={category}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Summary',
            gridSize: 6,
            component:
              <StepReview
                category={category}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(CategorySteps);
