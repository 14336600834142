import { gql } from '@apollo/client';
import gqlClient from '../../../../../utils/apolloClient';

const insertSenderUserMutation = gql`
  mutation ($object: users_organisation_insert_input!) {
    insert_users_organisation_one(object: $object) {
      id
    }
  }
`;

export const createOrganisationUser = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_users_organisation_one: {
      id: string,
    }
  }>({ mutation: insertSenderUserMutation!, variables });
  return res.data?.insert_users_organisation_one.id;
}
