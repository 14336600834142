
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createVoucherCodeInitQuery = (id: string) => ({
  locations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      organisation: {
        categories: [{
          id: types.string,
          name: types.string,
          menu: {
            name: types.string,
          },
        }],
      },
      voucher_codes: [{
        id: types.string,
        code: types.string,
      }],
    },
  ),
});

type X = Unpacked<typeof _createVoucherCodeInitQuery>;
export type Location = X['locations_by_pk'];

export const createVoucherCodeInitQuery = (id: string) => gql`${query(_createVoucherCodeInitQuery(id))}`;
