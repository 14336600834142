import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomStepper from '../../../../../../../components/CustomStepper';
import { Menu, validationErrors, ValidationErrors, UploadMenuReducerAction, validation } from '../../../Common';
import StepDetails from '../StepDetails';
import StepUpload from '../StepUpload';
import StepCategories from '../StepCategories';
import StepModifiers from '../StepModifiers';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  menu: Menu,
  locale: string,
  currency: string,
  dispatch: React.Dispatch<{ type: UploadMenuReducerAction, value: any }>,
  handleSave: () => void,
  handleAnother?: () => void,
}

const MenuSteps = ({ classes, completeLabel, menu, locale, currency, dispatch, handleSave, handleAnother }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);  
  const [itemIndex, setItemIndex] = useState<number>(0);
  const [overrideSetStep, setOverrideSetStepStep] = useState<boolean>(false);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    description: false,
    availableFrom: false,
    availableTo: false,
    menuType: false,
    incompatibleTimes: false,
    fileUpload: false,
    itemsNotAssigned: false,
    categories: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = validation.name(menu.name);
      errorItems.description = validation.name(menu.description);
      errorItems.availableFrom = validation.name(menu.available_from);
      errorItems.availableTo = validation.name(menu.available_to);
      errorItems.menuType = validation.menuType(menu.menu_types);
      errorItems.incompatibleTimes = validation.incompatibleTimes(menu.available_from, menu.available_to);
    }
    if (_step === 1) {
      errorItems.fileUpload = validation.fileUpload(menu.items);
      errorItems.itemsNotAssigned = validation.itemsNotAssigned(menu.items);
    }
    if (_step === 2) {
      errorItems.categories = validation.categories(menu.items);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [menu, errors]);

  const handleStepOveride = (isNext: boolean) => {
    setItemIndex((i) => isNext ? i + 1 : i - 1);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && step === 3) {
      setOverrideSetStepStep(itemIndex < menu.items.length - 1);
    }
    return () => { mounted = false; };
  }, [step, itemIndex, menu.items.length, setOverrideSetStepStep]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        overrideSetStep={overrideSetStep}
        setStep={setStep}
        handleStepOveride={handleStepOveride}
        handleAnother={handleAnother}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            detail: menu.name,
            gridSize: 6,
            component:
              <StepDetails
                menu={menu}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Upload',
            detail: menu.items.length > 0 ? `${menu.items.length} items` : '',
            gridSize: 8,
            component:
              <StepUpload
                menu={menu}
                errors={errors}
                validate={validate}
                dispatch={dispatch} />
          },
          {
            label: 'Categories',
            detail: menu.items.length > 0 ? `${[...new Set(menu.items.filter((i) => i.category).map((i) => i.category))].length} categories` : '',
            gridSize: 8,
            component:
              <StepCategories
                menu={menu}
                errors={errors}
                validate={validate}
                dispatch={dispatch} />
          },
          {
            label: 'Modifiers',
            gridSize: 8,
            component:
              <StepModifiers
                menu={menu}
                itemIndex={itemIndex}
                errors={errors}
                locale={locale}
                currency={currency}
                setItemIndex={setItemIndex}
                dispatch={dispatch} />
          },
          {
            label: 'Summary',
            gridSize: 6,
            component:
              <StepReview
                menu={menu}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(MenuSteps);
