import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Backdrop,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  label: string,
}

const CustomBackdrop = ({ classes, label }: Props): React.ReactElement => {
  return (
    <Backdrop className={classes.root} open >
      <CircularProgress />
      <Typography className={classes.label} variant="subtitle1">{label}</Typography>
    </Backdrop>
  );
};

export default withStyles(styles)(CustomBackdrop);
