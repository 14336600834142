import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const inserVoucherCodeMutation = gql`
  mutation ($object: vouchers_code_insert_input!) {
    insert_vouchers_code_one(object: $object) {
      id
    }
  }
`;

export const createVoucherCode = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_vouchers_code_one: {
      id: string,
    }
  }>({ mutation: inserVoucherCodeMutation!, variables });
  return res.data?.insert_vouchers_code_one.id;
}
