import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';

import { PaymentIntegration, LocationIntegrationReducerAction, ValidationErrors, validation, PaymentProvider } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  payment: PaymentIntegration,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: LocationIntegrationReducerAction, value: any}>,
}

const StepPayment = ({ classes, payment, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'accountId') {
      errorItems.paymentAccountId = validation.paymentAccountId(payment.accountId);
    }
    if (field === 'provider') {
      errorItems.paymentProvider = validation.paymentProvider(payment.provider);
    }
    if (field === 'username') {
      errorItems.paymentUsername = validation.paymentUsername(payment.username);
    }
    if (field === 'password') {
      errorItems.paymentPassword = validation.paymentPassword(payment.password);
    }
    setErrors({...errors, ...errorItems});
  }, [payment, errors, setErrors]);

  const paymentProviders = [
    'Dojo',
    'Zettle',
  ];

  return (
    <>
      <div className={classes.root}>

      <FormControl className={classes.textField}>
        <FormControlLabel
          label="Active"
          className={classes.active}
          control={
            <>
              <Switch
                checked={payment.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.PAYMENT_ACTIVE, value: event.target.checked })}
              />
            </>
          }/>
          <FormHelperText error>{errors.paymentProvider as string}</FormHelperText>
        </FormControl>
          {payment.active && (
            <>
              <FormControl className={classes.textField}>
                <InputLabel id="provider-select-label">Payment provider</InputLabel>
                <Select
                  labelId="provider-select-label"
                  id="provider-select"
                  value={payment.provider || ''}
                  onChange={(event: React.ChangeEvent<any>) => dispatch({ type: LocationIntegrationReducerAction.PAYMENT_PROVIDER, value: event.target.value })}
                  onBlur={() => validate('provider')}>
                  {paymentProviders.map((p: string) => {
                    return (
                      <MenuItem key={p} value={p.toUpperCase()}>{p}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {payment.provider && [PaymentProvider.DOJO, PaymentProvider.ZETTLE].includes(payment.provider as PaymentProvider) && (
                <>
                  <Divider className={classes.divider} />
                  <TextField
                    className={classes.textField}
                    data-qa="accountId-textfield"
                    required
                    label="Account ID"
                    variant="outlined"
                    error={errors.paymentAccountId as boolean}
                    helperText={errors.paymentAccountId}
                    value={payment.accountId}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.PAYMENT_ACCOUNT_ID, value: event.target.value })}
                    onKeyUp={() => validate('accountId')}
                    onBlur={() => validate('accountId')}/>
                </>
              )}
              {payment.provider && [PaymentProvider.ZETTLE].includes(payment.provider as PaymentProvider) && (
                <>
                  <FormControl className={classes.textField}>
                     <FormControlLabel
                      label="Enable tipping"
                      className={classes.active}
                      control={
                        <>
                          <Switch
                            checked={payment.enableTipping}
                            color="primary"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.PAYMENT_TIPPING, value: event.target.checked })}
                          />
                        </>
                      }/>
                      <FormHelperText error>{errors.paymentProvider as string}</FormHelperText>
                    </FormControl>
                </>
              )}

              {payment.provider && [PaymentProvider.DOJO].includes(payment.provider as PaymentProvider) && (
                <>
                  <TextField
                    className={classes.textField}
                    data-qa="username-textfield"
                    required
                    label="Username"
                    variant="outlined"
                    error={errors.paymentUsername as boolean}
                    helperText={errors.paymentUsername}
                    value={payment.username}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.PAYMENT_USERNAME, value: event.target.value })}
                    onKeyUp={() => validate('username')}
                    onBlur={() => validate('username')}/>
                  <TextField
                    className={classes.textField}
                    data-qa="password-textfield"
                    required
                    label="Password"
                    variant="outlined"
                    error={errors.paymentPassword as boolean}
                    helperText={errors.paymentPassword}
                    value={payment.password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.PAYMENT_PASSWORD, value: event.target.value })}
                    onKeyUp={() => validate('password')}
                    onBlur={() => validate('password')}/>
                </>
              )}
            </>
          )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepPayment);
