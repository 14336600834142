import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 60,
  },
  caption: {
    marginLeft: theme.spacing(2),
  },
  counter: {
    marginRight: theme.spacing(2),
  },
  item: {
    height: '100%',
  },
});

export default styles;
