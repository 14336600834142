import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Typography,
  withStyles,
} from '@material-ui/core';

import CustomTransferList, { CustomTransferListItem } from '../../../../../CustomTransferList';
import { CodeVoucher, CodeVoucherType } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  voucher: CodeVoucher,
  categories: CustomTransferListItem[],
  assignedCategories: CustomTransferListItem[],
  handleAssignment: (left: CustomTransferListItem[], right: CustomTransferListItem[]) => void,
}

const StepCategoryAssignment = ({ classes, voucher, categories, assignedCategories, handleAssignment }: Props): React.ReactElement => {
  return (
    <>
      {voucher.voucher_type === CodeVoucherType.PERCENT && (
        <CustomTransferList
          labelLeft="Unassigned category"
          labelRight="Assigned category"
          allItems={categories}
          currentItems={assignedCategories}
          handleTransfer={handleAssignment} />
      )}

      {voucher.voucher_type === CodeVoucherType.AMOUNT && (
        <Typography>The voucher type is set to amount. Categories can only be assigned if the voucher type is a percentage.</Typography>
      )}
    </>
  );
};

export default withStyles(styles)(StepCategoryAssignment);
