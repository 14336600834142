import Amplify, { Auth } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import {
  CustomError,
  CognitoUser,
  User,
  OrganisationUser,
  LocationUser,
} from '../../react-app-env';

import useLocalStorage from '../../hooks/useLocalStorage';
import { getUserRoleQuery, getAdminUserQuery, getOrganisationUserQuery, getLocationUserQuery } from './queries';


Amplify.configure({
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT,
});

export enum EnumUserRole {
  ADMIN = 'admin',
  OWNER = 'owner',
  MANAGER = 'manager',
}

export const adminRoles = [EnumUserRole.ADMIN];
export const organisationRoles = [EnumUserRole.OWNER];
export const locationRoles = [EnumUserRole.MANAGER];

interface AuthContextItems {
  Auth: typeof Auth;
  userId: string | null;
  user: User | OrganisationUser | LocationUser | null;
  loggedInUser: CognitoUser | null;
  userRole: EnumUserRole | null;
  signIn: (username: string, password: string, ignoreSet?: boolean) => Promise<CognitoUser | CustomError | any>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<void | CustomError>;
  confirmSignUp: (username: string, code: string) => Promise<true | CustomError>;
  setLoggedInUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  resendSignUp: (username: string) => Promise<true | CustomError>;
  completeNewPassword: (password: string, attributes: { given_name: string, family_name: string }) => Promise<true | CustomError>;
}

export const AuthContext = React.createContext<AuthContextItems | null>(null);

type Props = { children: React.ReactNode }

const AuthProvider = ({ children }: Props): React.ReactElement => {
  const [userId, setUserId] = useState<string | null>(null);
  const [user, setUser] = useState<User | OrganisationUser | LocationUser | null>(null);
  const [userRole, setUserRole] = useState<EnumUserRole | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<CognitoUser | null>(null);
  const [, setToken, removeToken] = useLocalStorage('token', null);

  const getUser = useCallback(async () => {
    if (loggedInUser && loggedInUser.attributes && userId && userRole) {
      const role: EnumUserRole = userRole;
      if (adminRoles.includes(role)) {
        const user = await getAdminUserQuery(userId);
        if (user) {
          setUser({
           ...user,
           user_type: 'APOS Admin',
          } as User);
        }
      }
      if (organisationRoles.includes(role)) {
        const user = await getOrganisationUserQuery(userId);
        if (user) {
          setUser({
           ...user,
           user_type: 'Organisation Admin',
          } as OrganisationUser);
        }
      }
      if (locationRoles.includes(role)) {
        const user = await getLocationUserQuery(userId);
        if (user) {
          setUser({
           ...user,
           user_type: user.enum_user_type.description,
          } as LocationUser);
        }
      }
    }
  }, [loggedInUser, userId, userRole, setUser]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loggedInUser && userId && userRole) {
      getUser();
    }
  return () => { mounted = false; };
  }, [loggedInUser, userId, userRole, getUser]);

  const fetchRoles = useCallback(async () => {
    try {
      if (userId) {
        const res = await getUserRoleQuery(userId);
        setUserRole(res.data.user_roles[0]?.role as EnumUserRole);
      }
    } catch(err: any) {
      if (err.message === 'Authentication hook unauthorized this request') {
        return;
      }
      console.error(err);
    }
  }, [userId, setUserRole]);

  useEffect(() => {
    let mounted = true;
    if (mounted && loggedInUser && loggedInUser.attributes && userId) {
      fetchRoles();
    } else {
      setUserRole(null);
    }
    return () => { mounted = false; };
  }, [loggedInUser, userId, fetchRoles]);

  useEffect(() => {
    let mounted = true;
    if (mounted && !userId) {
      Auth.currentAuthenticatedUser().then(async (user) => {
        setUserId(user.attributes.sub);
        setLoggedInUser(user);
        setToken(user.signInUserSession.idToken.jwtToken);
      }).catch((error) => {
        console.error(error);
      });
    }
    return () => { mounted = false; };
  }, [userId, setToken]);

  const authContext: AuthContextItems = {
    Auth,
    user,
    userId,
    userRole,
    loggedInUser,
    setLoggedInUser,
    forgotPassword: async (email) => Auth.forgotPassword(email)
      .catch((error) => ({ error: true, ...error })),
    signIn: async (emailAddress: string, password: string) => {
      const cognitoUser = await Auth.signIn(emailAddress.toLowerCase(), password);
      if (cognitoUser.attributes) {
        setUserId(cognitoUser.attributes.sub);
        setLoggedInUser(cognitoUser);
        if(cognitoUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
          setToken(cognitoUser.signInUserSession.accessToken.jwtToken);
        }
      }
      return cognitoUser;
    },
    signOut: async () => {
      setToken(null);
      removeToken();
      await Auth.signOut();
      setLoggedInUser(null);
    },
    completeNewPassword: async (password, attributes) => {
      try {
        await Auth.completeNewPassword(loggedInUser, password, attributes);
        return true;
      } catch (error: any) {
        return error;
      }
    },
    confirmSignUp: async (username: string, code: string) => {
      try {
        await Auth.confirmSignUp(username.toLowerCase(), code);
        return true;
      } catch (error: any) {
        return error;
      }
    },
    resendSignUp: async (username: string) => {
      try {
        await Auth.resendSignUp(username);
        return true;
      } catch (error: any) {
        return error;
      }
    },
  };
  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};


export default AuthProvider;
