import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {},
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 60,
    // marginBottom: theme.spacing(1),
  },
  caption: {
    marginLeft: theme.spacing(2),
  },
  counter: {
    marginRight: theme.spacing(2),
  },
  item: {
    height: '100%',
  },
  chipsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  chipsLabel: {
    marginBottom: theme.spacing(1),
    minWidth: theme.spacing(10),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  chipLabel: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
  },
});

export default styles;
