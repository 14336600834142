import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  disabled: {
    '& > $content, $components': {
      opacity: 0.6,
      // textDecoration: 'line-through',
    },
  },
  content: {
    display: 'flex',
    flexGrow: 1,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  fill: {
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
  subTitle: {
    fontSize: 14,
    // maxWidth: theme.spacing(25),
  },
  description: {
    fontSize: 12,
    // maxWidth: theme.spacing(25),
  },
  counterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  counter: {
    marginTop: 0,
    lineHeight: 1,
    cursor: 'default',
    marginBottom: theme.spacing(0.5),
  },
  subCounterLabel: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    // marginRight: theme.spacing(1),
  },
  icon: {
    marginTop: 0,
    marginRight: theme.spacing(2),
    filter: theme.palette.type === 'dark' ? 'invert(1)' : '',
    opacity: theme.palette.type === 'dark' ? 1 : 0.87,
    '& > img': {
      width: 70,
      height: 70,
    },
  },
  components: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: theme.spacing(3),
  },
  actions: {
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
});

export default styles;
