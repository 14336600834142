
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editMenuInitQuery = (id: string) => ({
  menus_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    },
  ),
});

type X = Unpacked<typeof _editMenuInitQuery>;
export type Menu = X['menus_by_pk'];

export const editMenuInitQuery = (id: string) => gql`${query(_editMenuInitQuery(id))}`;
