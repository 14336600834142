import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    flexGrow: 1,
  },

  locationsList: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginRight: 0,
    paddingRight: 0,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginRight: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRightWidth: 1,
      borderRightColor: theme.palette.divider,
      borderRightStyle: 'solid',
    },
  },

  locationsListItem: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },

  locationsListItemSelected: {
    backgroundColor: theme.palette.background.default,
  },

  locationsListItemDeleteIcon: {
    width: 20,
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
    },
  },

  locationsListAddButton: {
    marginTop: theme.spacing(1),
  },

  locationsEdit: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },


  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    flex: '1 1 auto',
  },
  button: {
    flex: '1 1 auto', 
    '&:first-of-type': {
      marginRight: theme.spacing(1),
    },
    '&:last-of-type': {
      marginLeft: theme.spacing(1),
    },
    '&:only-child': {
      margin: 0,
    },
  },
});

export default styles;
