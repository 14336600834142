import React, { useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Typography,
  withStyles,
} from '@material-ui/core';

import { Logo } from '../../../../components/Logos';
import { XeroLogo } from '../../../../components/Logos/Partners';

import { CenteredLayout } from '../../../../layouts';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const StepXeroSuccess = ({ classes }: Props): React.ReactElement => {
  
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // setTimeout(() => {
      //   window.open('','_self')?.close();
      // }, 3000);
    }
    return () => { mounted = false; };
  }, []);

  return (
    <CenteredLayout>
      <div className={classes.root}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <Typography className={classes.text}>+</Typography>
        <div className={classes.xeroLogo}>
          <XeroLogo />
        </div>
        <Typography className={classes.text}>integration</Typography>
        <Typography className={classes.text}>completed</Typography>
      </div>
    </CenteredLayout>
  );
};

export default withStyles(styles)(StepXeroSuccess);
