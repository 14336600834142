import { capitaliseFirstOnly, numbersOnly } from "../../../../utils/stringUtils";
import { ValidationType } from '../../../../react-app-env';
import { validateEmailAddress } from "../../../../utils/validators";
import { OrderType } from "../../Menus/Common";

export interface Location {
  name: string,
  email: string,
  phone: string,
  website: string,
  tax_number: string,
  number_of_tables: number,
  location_type: string,
  address_line_1: string,
  address_line_2: string,
  address_city: string,
  address_country: string,
  address_postcode: string,
  currency: string,
  locale: string,
  tax_rate: number,
  service_charge: ServiceCharge | null,
  can_manage_menus: boolean,
  has_kitchen_printer: boolean,
  has_bar_printer: boolean,
  has_staff: boolean,
  has_station_printers: boolean,
  idle_timeout: number,
}

export interface ServiceCharge {
  order_types: OrderType[],
  percentage: number,
  min_covers: number,
}

export interface LocationType {
  location_type: string,
  description: string,
}

export interface ValidationErrors {
  name: ValidationType,
  email: ValidationType,
  phone: ValidationType,
  email_invalid: ValidationType,
  tax_number: ValidationType,
  number_of_tables: ValidationType,
  location_type: ValidationType,
  address_line_1: ValidationType,
  address_city: ValidationType,
  address_country: ValidationType,
  address_postcode: ValidationType,
  currency: ValidationType,
  locale: ValidationType,
  tax_rate: ValidationType,
  service_charge_order_types: ValidationType,
  service_charge_percentage: ValidationType,
  service_charge_percentage_too_small: ValidationType,
  service_charge_percentage_too_large: ValidationType,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  email: 'Please provide an email address',
  email_invalid: 'Please provide a valid email address',
  phone: 'Please provide a phone number',
  tax_number: 'Please provide a tax number (VAT)',
  number_of_tables: 'Please provide how many tables',
  location_type: 'Please provide a location type',
  address_line_1: 'Please provide a first line of address',
  address_city: 'Please provide a city',
  address_country: 'Please provide a country',
  address_postcode: 'Please provide a postcode',
  currency: 'Please provide a currency',
  locale: 'Please provide a locale',
  tax_rate: 'Please provide a tax rate',
  service_charge_order_types: 'Please provide an order type',
  service_charge_percentage: 'Please provide a percentage',
  service_charge_percentage_too_small: 'Please provide a percentage',
  service_charge_percentage_too_large: 'Please provide a percentage less than 100',
}

export interface Validation {
  name: (value: string) => ValidationType,
  email: (value: string) => ValidationType,
  phone: (value: string) => ValidationType,
  tax_number: (value: string) => ValidationType,
  number_of_tables: (value: number) => ValidationType,
  location_type: (value: string) => ValidationType,
  address_line_1: (value: string) => ValidationType,
  address_city: (value: string) => ValidationType,
  address_country: (value: string) => ValidationType,
  address_postcode: (value: string) => ValidationType,
  currency: (value: string) => ValidationType,
  locale: (value: string) => ValidationType,
  tax_rate: (value: string) => ValidationType,
  service_charge_order_types: (value: OrderType[]) => ValidationType,
  service_charge_percentage: (value: number) => ValidationType,
}

export const validation: Validation = {
  name: (value) => !value ? validationErrors.name : false,
  email: (value) => {
    if (!value) return validationErrors.email;
    if (!validateEmailAddress(value)) return validationErrors.email_invalid;
    return false;
  },
  phone: (value) => !value ? validationErrors.phone : false,
  tax_number: (value) => !value ? validationErrors.tax_number : false,
  location_type: (value) => !value ? validationErrors.location_type : false,
  number_of_tables: (value: number) => value === 0 ? validationErrors.number_of_tables : false,
  address_line_1: (value) => !value ? validationErrors.address_line_1 : false,
  address_city: (value) => !value ? validationErrors.address_city : false,
  address_country: (value) => !value ? validationErrors.address_country : false,
  address_postcode: (value) => !value ? validationErrors.address_postcode : false,
  currency: (value) => !value ? validationErrors.currency : false,
  locale: (value) => !value ? validationErrors.locale : false,
  tax_rate: (value) => !value ? validationErrors.tax_rate : false,
  service_charge_order_types: (value: OrderType[]) => value.length === 0 ? validationErrors.service_charge_order_types : false,
  service_charge_percentage: (value: number) => {
    if (value <= 0) return validationErrors.service_charge_percentage_too_small;
    if (value > 100) return validationErrors.service_charge_percentage_too_large;
    return false;
  }
}

export enum LocationReducerAction {
  NAME,
  EMAIL,
  PHONE,
  WEBSITE,
  TAX_NUMBER,
  NUMBER_OF_TABLES,
  LOCATION_TYPE,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_CITY,
  ADDRESS_COUNTRY,
  ADDRESS_POSTCODE,
  CURRENCY,
  LOCALE,
  TAX_RATE,
  SERVICE_CHARGE_ACTIVE,
  SERVICE_CHARGE_ORDER_TYPES,
  SERVICE_CHARGE_PERCENT,
  SERVICE_CHARGE_COVERS,
  CAN_MANAGE_MENUS,
  HAS_KITCHEN_PRINTER,
  HAS_BAR_PRINTER,
  HAS_STAFF,
  HAS_STATION_PRINTERS,
  IDLE_TIMEOUT,
  INIT,
}

export const locationReducer = (state: Location, action: { type: LocationReducerAction, value: any }): Location =>  {
  switch (action.type) {
    case LocationReducerAction.NAME:
      return { ...state, name: capitaliseFirstOnly(action.value) };
    case LocationReducerAction.EMAIL:
      return { ...state, email: action.value.toLowerCase() };
    case LocationReducerAction.PHONE:
      return { ...state, phone: action.value };
    case LocationReducerAction.WEBSITE:
      return { ...state, website: action.value };
    case LocationReducerAction.TAX_NUMBER:
      return { ...state, tax_number: action.value };
    case LocationReducerAction.NUMBER_OF_TABLES:
      return { ...state, number_of_tables: +(numbersOnly(action.value)) };
    case LocationReducerAction.LOCATION_TYPE:
      return { ...state, location_type: action.value };
    case LocationReducerAction.ADDRESS_LINE_1:
      return { ...state, address_line_1: action.value };
    case LocationReducerAction.ADDRESS_LINE_2:
      return { ...state, address_line_2: action.value };
    case LocationReducerAction.ADDRESS_CITY:
      return { ...state, address_city: action.value };
    case LocationReducerAction.ADDRESS_COUNTRY:
      return { ...state, address_country: action.value };
    case LocationReducerAction.ADDRESS_POSTCODE:
      return { ...state, address_postcode: action.value };
    case LocationReducerAction.TAX_RATE:
      return { ...state, tax_rate: +(numbersOnly(action.value)) };
    case LocationReducerAction.SERVICE_CHARGE_ACTIVE:
      return { ...state, service_charge: action.value ? {
        order_types: [],
        percentage: 0,
        min_covers: 0,
      } : null };
    case LocationReducerAction.SERVICE_CHARGE_ORDER_TYPES:     
      let oTypes = state.service_charge!.order_types;
      if (action.value.active) {
        oTypes.push(action.value.orderType);
      } else {
        oTypes = oTypes.filter((m) => m !== action.value.orderType);
      }
      return { ...state, service_charge: {
        order_types: oTypes,
        percentage: state.service_charge!.percentage,
        min_covers: state.service_charge!.min_covers,
      }};
    case LocationReducerAction.SERVICE_CHARGE_PERCENT:  
      return { ...state, service_charge: {
        order_types: state.service_charge!.order_types,
        percentage: +(action.value),
        min_covers: state.service_charge!.min_covers,
      }};
    case LocationReducerAction.SERVICE_CHARGE_COVERS:     
      return { ...state, service_charge: {
        order_types: state.service_charge!.order_types,
        percentage: state.service_charge!.percentage,
        min_covers: +(numbersOnly(action.value)),
      }};
    case LocationReducerAction.CURRENCY:
      return { ...state, currency: action.value };
    case LocationReducerAction.LOCALE:
      return { ...state, locale: action.value };
    case LocationReducerAction.CAN_MANAGE_MENUS:
      return { ...state, can_manage_menus: action.value as boolean };
    case LocationReducerAction.HAS_KITCHEN_PRINTER:
      return { ...state, has_kitchen_printer: action.value as boolean };
    case LocationReducerAction.HAS_BAR_PRINTER:
      return { ...state, has_bar_printer: action.value as boolean };
    case LocationReducerAction.HAS_STAFF:
      return { ...state, has_staff: action.value as boolean };
    case LocationReducerAction.HAS_STATION_PRINTERS:
      return { ...state, has_station_printers: action.value as boolean };
    case LocationReducerAction.IDLE_TIMEOUT:
      return { ...state, idle_timeout: action.value as number };
    case LocationReducerAction.INIT:
      const { name,
        email,
        phone,
        website,
        location_type,
        number_of_tables,
        address_line_1,
        address_line_2,
        address_city,
        address_country,
        address_postcode,
        tax_number,
        service_charge,
        currency,
        locale,
        can_manage_menus,
        has_kitchen_printer,
        has_bar_printer,
        has_staff,
        has_station_printers,
        idle_timeout,
      } = action.value;
      const obj = {
        name,
        email,
        phone,
        website,
        number_of_tables,
        location_type,
        address_line_1,
        address_line_2,
        address_city,
        address_country,
        address_postcode,
        tax_number,
        service_charge,
        currency,
        locale,
        can_manage_menus,
        has_kitchen_printer,
        has_bar_printer,
        has_staff,
        has_station_printers,
        idle_timeout,
      };
      return { ...obj as Location };
    default:
      throw new Error();
  }
}
