import { capitaliseFirst } from "../../../../utils/stringUtils";
import { ValidationType } from '../../../../react-app-env';

export interface LocationPrinter {
  name: string,
  location: string,
  printCopyToTill: boolean,
  categories: Category[],
}

export interface Menu {
  id: string,
  name: string,
  categories: Category[],
}

export interface Category{ 
  id: string, 
  name: string,
  category_type: string,
}

export interface Organisation {
  id: string,
  name: string,
}


export interface ValidationErrors {
  name: ValidationType,
  location: ValidationType,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  location: 'Please provide a location',
}

export interface Validation {
  name: (value: string) => ValidationType,
  location: (value: string) => ValidationType,
}

export const validation: Validation = {
  name: (value) => !value ? validationErrors.name : false,
  location: (value) => !value ? validationErrors.location : false,
}
export enum LocationPrinterReducerAction {
  NAME,
  LOCATION,
  COPY,
  CATEGORY,
  CATEGORIES,
  INIT,
}

export const locationPrintersReducer = (state: LocationPrinter, action: { type: LocationPrinterReducerAction, value: any }): LocationPrinter =>  {
  switch (action.type) {
    case LocationPrinterReducerAction.NAME:
      return { ...state, name: capitaliseFirst(action.value) };
    case LocationPrinterReducerAction.LOCATION:
      return { ...state, location: capitaliseFirst(action.value).slice(0, 20) };
    case LocationPrinterReducerAction.COPY:
      return { ...state, printCopyToTill: action.value as boolean };
    case LocationPrinterReducerAction.CATEGORY: {
      const { category } = action.value;
      let categories = [...state.categories];
      if(categories.some((c) => c.id === category.id)) {
        categories = categories.filter((c) => c.id !== category.id);
      } else {
        categories.push(category);
      }
      return { ...state, categories };
    }
    case LocationPrinterReducerAction.CATEGORIES: {
      const cats: Category[] = action.value.categories;
      const categoryIds = cats.map((i) => i.id);
      let categories = [...state.categories];

      if(categories.some((c) => categoryIds.includes(c.id))) {
        categories = categories.filter((c) => !categoryIds.includes(c.id));
      } else {
        categories = [...categories, ...cats];
      }
      return { ...state, categories };
    }
    case LocationPrinterReducerAction.INIT:
      const { name, device_config } = action.value;
      const obj = {
        name,
        location: device_config.location,
        categories:  device_config.categories ?? [],
        printCopyToTill: device_config.printCopyToTill ?? false,
      };
      return { ...obj as LocationPrinter };
    default:
      throw new Error();
  }
}
