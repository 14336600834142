import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import GridCard from '../../../components/Grid/GridCard';

import { homeInitQuery } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const Home = ({ classes, history }: Props): React.ReactElement => {
  const [integrations, setIntegrations] = useState({
    vouchers: false,
    has_staff: false,
    has_station_printers: false,
  });

  const { data: homeInitData } = useQuery(homeInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && homeInitData) {
      setIntegrations({
        vouchers: homeInitData.location_integrations[0].vouchers,
        has_staff: homeInitData.locations[0].has_staff,
        has_station_printers: homeInitData.locations[0].has_station_printers,
      });
    }
    return () => { mounted = false; };
  }, [homeInitData]);

  const handleAction = (link: string) => {
    history.push(link);
  }

  const actions = [
    // {
    //   title: 'Bookings',
    //   createLink: '/loc/bookings/create',
    //   viewLink: '/loc/bookings',
    // },
    {
      title: 'Users',
      viewLink: '/loc/users',
    },
    {
      title: 'Accounts',
      viewLink: '/loc/orders',
    },
    {
      title: 'Reports',
      viewLink: '/loc/reports',
    },
  ];

  if (integrations.vouchers) {
    actions.splice(1, 0, 
      {
        title: 'Vouchers',
        viewLink: '/loc/vouchers',
      }
    );
  }

  if (integrations.has_staff) {
    actions.splice(1, 0, 
      {
        title: 'Staff',
        viewLink: '/loc/staff',
      }
    );
  }

  if (integrations.has_station_printers) {
    actions.splice(1, 0, 
      {
        title: 'Printers',
        viewLink: '/loc/printers',
      }
    );
  }

  return (
    <Dashboard title="Dashboard">
      <Grid container spacing={4}>
        {actions.map((action) => (
          <GridCard
            fill
            gridSize={3}
            key={action.title}
            title={action.title}
            editLabel="Create"
            handleView={() => handleAction(action.viewLink)} />
        ))}
      </Grid>
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(Home));
