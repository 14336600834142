import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomStepper from '../../../../../CustomStepper';
import { CategoryItem, validationErrors, ValidationErrors, CategoryItemReducerAction, validation, Allergy } from '../..';
import StepDetails from '../StepDetails';
import StepModifiers from '../StepModifiers';
import StepAllergies from '../StepAllergies';
import StepReview from '../StepReview';


import styles from './styles';
import { CustomTransferListItem } from '../../../../../CustomTransferList';
import { useQuery } from '@apollo/client';
import { createCategoryItemInitQuery, DishAssignments, DishAssignment, DrinkAssignments, DrinkAssignment } from './queries';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  category_id: string,
  category_type: string,
  categoryItem: CategoryItem,
  currency: string,
  locale: string,
  taxRate: number,
  dispatch: React.Dispatch<{ type: CategoryItemReducerAction, value: any }>,
  handleSave: () => void,
  handleAnother?: () => void,
}

const CategoryItemSteps = ({ classes, completeLabel, category_id, categoryItem, category_type, currency, locale, taxRate, dispatch, handleSave, handleAnother }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    description: false,
    tag: false,
    tax: false,
  });
  const [categoryTags, setCategoryTags] = useState<Array<string>>([]);


  const { data: createCategoryItemInitData } = useQuery(createCategoryItemInitQuery(category_id), { fetchPolicy: 'no-cache' });


  useEffect(() => {
    let mounted = true;
    if (mounted && createCategoryItemInitData) {
      const dishes: DishAssignments = createCategoryItemInitData.categories_by_pk.dish_assignments;
      const dishTags: string[] = [...new Set(dishes.map((i: DishAssignment) => i.dish.tag))];

      const drinks: DrinkAssignments = createCategoryItemInitData.categories_by_pk.drink_assignments;
      const drinkTags: string[] = [...new Set(drinks.map((i: DrinkAssignment) => i.drink.tag))];

      setCategoryTags([...dishTags, ...drinkTags]);
    }
    return () => { mounted = false; };
  }, [createCategoryItemInitData]);


  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.name = validation.name(categoryItem.name);
      errorItems.description = validation.description(categoryItem.description);
      errorItems.tax = validation.tax(categoryItem.tax);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [categoryItem, errors]);

  const handleAllergyAssignment = (_: unknown, assigned: CustomTransferListItem[]) => {
    const assignedIds = assigned.map((i) => i.id);
    const a = createCategoryItemInitData.allergies.filter((i: Allergy) => assignedIds.includes(i.id));
    dispatch({ type: CategoryItemReducerAction.UPDATE_ALLERGIES, value: a });
  }

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}
        handleAnother={handleAnother} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                categoryItem={categoryItem}
                currency={currency}
                categoryTags={categoryTags}
                locale={locale}
                taxRate={taxRate}
                category_type={category_type}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Modifiers',
            gridSize: 7,
            component:
              <StepModifiers
                modifiers={categoryItem.modifiers}
                currency={currency}
                locale={locale}
                taxRate={taxRate}
                errors={errors}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Allergies',
            gridSize: 8,
            component:
              <StepAllergies
                allergies={createCategoryItemInitData && createCategoryItemInitData.allergies ? createCategoryItemInitData.allergies.map((allergy: Allergy) => ({ id: allergy.id, label: allergy.name })) : []}
                assignedAllergies={categoryItem.allergies.map((allergy: Allergy) => ({ id: allergy.id, label: allergy.name }))}
                handleAllergyAssignment={handleAllergyAssignment} />
          },
          {
            label: 'Summary',
            gridSize: 6,
            component:
              <StepReview
                categoryItem={categoryItem}
                currency={currency}
                locale={locale}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(CategoryItemSteps);
