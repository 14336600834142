
import { query, params, types, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _locationsInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      locations: [{
        id: types.string,
        name: types.string,
      }],
    },
  ),
});

type X = Unpacked<typeof _locationsInitQuery>
export type Organisation = X['organisations_by_pk'];
export type Location = Organisation['locations'][0];

export const locationsInitQuery = (id: string) => gql`${query(_locationsInitQuery(id))}`;
