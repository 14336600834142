import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomStepper from '../../../../../CustomStepper';
import { LocationStaff, validationErrors, ValidationErrors, LocationStaffReducerAction, validation } from '../..';
import StepDetails from '../StepDetails';
import StepReview from '../StepReview';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  completeLabel: string,
  user: LocationStaff,
  usedPins: string[],
  dispatch: React.Dispatch<{ type: LocationStaffReducerAction, value: any }>,
  handleSave: () => void,
}

const OrganisationUserSteps = ({ classes, completeLabel, user, usedPins, dispatch, handleSave }: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);

  const [errors, setErrors] = useState<ValidationErrors>({
    first_name: false,
    last_name: false,
    pin: false,
    pinLength: false,
    pinUsed: false,
  });

  const validate = useCallback((_step: number) => {
    const errorItems: UnknownObject = {}
    if (_step === 0) {
      errorItems.first_name = validation.first_name(user.first_name);
      errorItems.last_name = validation.last_name(user.last_name);
      errorItems.pin = validation.pin(user.pin, usedPins);
    }
    setErrors({...errors, ...errorItems});
    return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
  }, [user, usedPins, errors]);

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave} >
        {[
          {
            label: 'Details',
            component:
              <StepDetails
                user={user}
                errors={errors}
                usedPins={usedPins}
                setErrors={setErrors}
                dispatch={dispatch} />
          },
          {
            label: 'Summary',
            component:
              <StepReview
                user={user}
                setStep={setStep} />
            },
          ]}
        </CustomStepper>
    </>
  );
};

export default withStyles(styles)(OrganisationUserSteps);
