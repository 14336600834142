
import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _menusInitQuery = () => ({
  locations: [{
    can_manage_menus: types.boolean,
  }],
  menus: [{
    id: types.string,
    name: types.string,
    description: types.string,
    active: types.boolean,
    order_index: types.number,
    available_from: types.string,
    available_to: types.string,
  }],
});

type X = Unpacked<typeof _menusInitQuery>
export type Location = X['locations'][0];
export type Menu = X['menus'][0];

export const menusInitQuery = () => gql`${query(_menusInitQuery())}`;
