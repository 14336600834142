import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    width: 400,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    width: theme.spacing(14),
    height: theme.spacing(14),
    marginRight: theme.spacing(2),
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  action: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});

export default styles;
