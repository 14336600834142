import React, { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EmptyObject } from '../../../react-app-env';

import { withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  id: string;
}

const SortableItem: FC<Props> = ({ id, children }) => {
  const { listeners, attributes, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : 0,
    opacity: isDragging ? 0.3 : 1,
    width: '100%',
    cursor: 'move',
  };

  return (
    <div ref={setNodeRef} {...listeners} {...attributes} style={style}>
      {children}
    </div>
  );
};

export default withStyles(styles)(SortableItem);
