import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../react-app-env';

import {
  withStyles,
} from '@material-ui/core';

import CustomSortableTable, { ColumnTotals, SortableTableHeader } from '../../../../CustomSortableTable';
import { buildQuery, EnumReportOutputCalc, EnumReportOutputType, getNestedObjectValue, groupReportData, ReportData, ReportDataHeader } from '../../Common';

// import data from './data.json';
import styles from './styles';
import { capitaliseFirstOnly } from '../../../../../utils/stringUtils';

export interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  data: ReportData,
  noQuery?: boolean,
}

interface ReportTableData {
  name: string,
  headers: ReportDataHeader[],
  rows: ReportTableRowData[],
  totals: string[],
}

interface ReportTableRowData {
  key: string,
  columns: ReportTableColumnData[],
}

interface ReportTableColumnData {
  key: string,
  label: string,
}

const createHeaders = (report: ReportData): ReportTableData => {
  const table: ReportTableData = {
    name: report.name,
    headers: report.headers,
    rows: [],
    totals: report.totals || [],
  };
  return table;
};

const createData = (data: UnknownObject, report: ReportData): ReportTableData => {
  const table: ReportTableData = {
    name: report.name,
    headers: [],
    rows: [],
    totals: [],
  };
  const tableData = data[report.table];
  if (tableData) {
    if (report.output.calc === EnumReportOutputCalc.SUM) {
      if (report.output.type === EnumReportOutputType.GROUP) {
        const key: string = report.output.field || '';
        const groups = groupReportData(tableData, key);
        const headerLabel = capitaliseFirstOnly(key.replace(/_/g, ' '));
        Object.keys(groups).forEach((k) => {
          if (table.rows.length === 0) {
            table.rows.push({ key: 'r1', columns: [] });
          }
          table.headers.push({ key: k, label: `${headerLabel} ${k}`, align: 'center' });
          table.rows[0].columns.push({ key: k, label: groups[k] });
        });
      }
    }
    if ((report.output.calc === EnumReportOutputCalc.DATA)) {
      table.headers = report.headers;
      table.totals = report.totals;
      table.rows = tableData.map((k: UnknownObject, rIndex: number) => {
        const rowKey = `r${rIndex + 1}`;
        const columns = Object.keys(k).map((i, cIndex) => {
          const colKey = report.headers[cIndex].key;
          let label = getNestedObjectValue(colKey, k, true);
          if (typeof label === 'boolean') {
            label = label ? 'Yes' : 'No';
          }
          if (!isNaN(Number(label))) {
            const header = table.headers.find((h) => h.key === colKey);
            if (header) {
              header.align = 'center';
            }
          }
          return { key: colKey, label };
        });
        return { key: rowKey, columns }
      });
    }
  }

  return table;
};

const CustomReportTable = ({ classes, data, noQuery = false }: Props): React.ReactElement => {
  const [table, setTable] = useState<ReportTableData>({
    name: '',
    rows: [],
    headers: [],
    totals: [],
  });

  const init = useCallback(async () => {
    if (noQuery) {
      setTable(createHeaders(data));
    } else {
      const queryRes = await buildQuery(data);
      setTable(createData(queryRes, data));
    }
  }, [data, noQuery]);

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      init();
    }
    return () => { mounted = false; };
  }, [data, init]);

  useEffect(() => {
    let mounted = true;
    if (mounted && table.name === '') {
      init();
    }
    return () => { mounted = false; };
  }, [table, init]);

  // const formatMoney = (v: number, isNegative: boolean = false) => location.locale && location.currency ? numberToMoney(isNegative && v > 0 ? -v : v, location.currency, location.locale, true) : v;

  return (
    <CustomSortableTable
      title={table.name}
      counter={table.rows.length > 1 ? table.rows.length : undefined}
      headers={table.headers as SortableTableHeader[]}
      columnTotals={table.totals.map((i) => [i, undefined, 'center']) as ColumnTotals[]}
      rows={table.rows} />
  );
};

export default withStyles(styles)(CustomReportTable);
