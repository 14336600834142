import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  GridSize,
  Tooltip,
  Typography,
  withStyles,
  CircularProgress,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  className?: string,
  classes: ClassNameMap<string>,
  gridSize?: GridSize,
  noGrid?: boolean,
  fill?: boolean,
  title: string,
  subTitle?: string,
  description?: string,
  loading?: boolean,
  counter?: number | string,
  counterSmall?: boolean,
  counterLabel?: string,
  subCounterLabel?: string,
  svg?: string,
  icon?: React.ReactElement,
  editLabel?: string,
  viewLabel?: string,
  disabled?: boolean,
  components?: React.Component[] | any[],
  handleEdit?: () => void,
  handleView?: () => void,
}

const GridCard = ({ className = '', classes, gridSize = 3, noGrid = false, fill, title, subTitle, description, counter, loading = false, counterSmall = false, counterLabel, subCounterLabel, svg, icon, editLabel = 'Edit', viewLabel = 'View', disabled = false, components, handleEdit, handleView }: Props): React.ReactElement => {
  const gridWrapper = (card: React.ReactElement) => {
    if (!noGrid) {
      return (
        <Grid item xs={gridSize} zeroMinWidth>
          {card}
        </Grid>
      );
    }
    return card;
  }

  return (
    gridWrapper(
      <Card 
      className={`${className} ${classes.root} ${fill ? classes.fill : ''} ${disabled ? classes.disabled : ''}`} 
      variant="outlined"
      // onClick={handleView && !components ? handleView : undefined}
       >
        <CardContent className={classes.content}>
          {svg && (
            <div className={classes.icon}>
              <img src={svg} alt="icon" />
            </div>
          )}
          {icon && (
            <div className={classes.icon}>
              {icon}
            </div>
          )}
          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant="h5" component="h2">{title}</Typography>
            <Typography className={classes.subTitle} variant="subtitle1">{subTitle}</Typography>
            <Typography className={classes.description} variant="subtitle2" color="textSecondary">{description}</Typography>
          </div>
          {loading && (
            <div className={classes.counterContainer}>
              <CircularProgress size={counterSmall ? 30 : 50} />
            </div>
          )}
          {!loading && counter !== null && (
            counterLabel
            ? <Tooltip className={classes.counterTooltip} title={counterLabel} placement="left">
                <div className={classes.counterContainer}>
                  <Typography className={classes.counter} variant={counterSmall ? 'h4' : 'h2'} component="h2" color="textSecondary">{counter}</Typography>
                  {subCounterLabel && (
                    <Typography className={classes.subCounterLabel} variant="subtitle2" color="textSecondary">{subCounterLabel}</Typography>
                  )}
                </div>
              </Tooltip>
            : (
              <div className={classes.counterContainer}>
                <Typography className={classes.counter} variant={counterSmall ? 'h4' : 'h2'}  component="h2" color="textSecondary">{counter}</Typography>
                {subCounterLabel && (
                  <Typography className={classes.subCounterLabel} variant="subtitle2" color="textSecondary">{subCounterLabel}</Typography>
                )}
              </div>
            )
          )}
        </CardContent>
        {components && (
          <CardContent className={classes.components}>
            {components}
          </CardContent>
        )}
        <CardActions className={classes.actions}>
          {handleView && (
            <Button size="small" variant="text" color="primary" onClick={handleView}>{viewLabel}</Button>
          )}
          {handleEdit && (
            <Button size="small" variant="text" color="primary" onClick={handleEdit}>{editLabel}</Button>
          )}
        </CardActions>
      </Card>)
  );
};

export default withStyles(styles)(GridCard);
