
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../react-app-env';

const _createMenuInitQuery = (id: string) => ({
  organisations_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
    },
  ),
});

type X = Unpacked<typeof _createMenuInitQuery>;
export type Organisation = X['organisations_by_pk'];

export const createMenuInitQuery = (id: string) => gql`${query(_createMenuInitQuery(id))}`;
