import { capitaliseFirst, numbersOnly } from "../../../../utils/stringUtils";
import { ValidationType } from '../../../../react-app-env';
import { validateEmailAddress } from "../../../../utils/validators";

export interface LocationUser {
  first_name: string,
  last_name: string,
  email: string,
  user_type: OrganisationUserType | null,
  pin: string | null,
  active: boolean,
}

export interface Organisation {
  id: string,
  name: string,
}

export interface LocationUserType {
  user_type: string,
  description: string,
}

export interface ValidationErrors {
  first_name: ValidationType,
  last_name: ValidationType,
  email: ValidationType,
  email_invalid: ValidationType,
  user_type: ValidationType,
  pin: ValidationType,
  pinUsed: ValidationType,
}

export const validationErrors: ValidationErrors = {
  first_name: 'Please provide a first name',
  last_name: 'Please provide a last name',
  email: 'Please provide an email address',
  email_invalid: 'Please provide a valid email address',
  user_type: 'Please provide a user type',
  pin: 'PIN must equal 4 numbers',
  pinUsed: 'PIN has already been assigned',
}

export interface Validation {
  first_name: (value: string) => ValidationType,
  last_name: (value: string) => ValidationType,
  email: (value: string) => ValidationType,
  user_type: (value: OrganisationUserType | null) => ValidationType,
  pin: (value: string | null, usedPins: string[]) => ValidationType,
}

export const validation: Validation = {
  first_name: (value) => !value ? validationErrors.first_name : false,
  last_name: (value) => !value ? validationErrors.last_name : false,
  email: (value) => {
    if (!value) return validationErrors.email;
    if (!validateEmailAddress(value)) return validationErrors.email_invalid;
    return false;
  },
  user_type: (value) => !value ? validationErrors.user_type : false,
  pin: (value, usedPins) => {
    if (value && value.length !== 4) return validationErrors.pin;
    if (value && usedPins.includes(value)) return validationErrors.pinUsed;
    return false;
  },
}

export enum OrganisationUserType {
  MANAGER = 'MANAGER',
  HEAD_CHEF = 'HEAD_CHEF',
  CHEF = 'CHEF',
  WAITER = 'WAITER',
  BARTENDER = 'BARTENDER',
}

export enum LocationUserReducerAction {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  ACTIVE,
  USER_TYPE,
  PIN,
  INIT,
}

export const locationUserReducer = (state: LocationUser, action: { type: LocationUserReducerAction, value: any }): LocationUser =>  {
  switch (action.type) {
    case LocationUserReducerAction.FIRST_NAME:
      return { ...state, first_name: capitaliseFirst(action.value) };
    case LocationUserReducerAction.LAST_NAME:
      return { ...state, last_name: capitaliseFirst(action.value) };
    case LocationUserReducerAction.EMAIL:
      return { ...state, email: action.value?.toLowerCase() };
    case LocationUserReducerAction.PIN:
      return { ...state, pin: action.value ? (numbersOnly(action.value)) : null };
    case LocationUserReducerAction.USER_TYPE:
      return { ...state, user_type: action.value };
    case LocationUserReducerAction.ACTIVE:
      return { ...state, active: action.value as boolean };
    case LocationUserReducerAction.INIT:
      const { first_name, last_name, email, pin, user_type, active } = action.value;
      const obj = {
        first_name,
        last_name,
        email,
        user_type,
        pin: pin,
        active,
      };
      return { ...obj as LocationUser };
    default:
      throw new Error();
  }
}
