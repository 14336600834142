import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const insertDishMutation = gql`
  mutation ($object: dishes_insert_input!) {
    insert_dishes_one(object: $object) {
      id
    }
  }
`;

export const createDish = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_dishes_one: {
      id: string,
    }
  }>({ mutation: insertDishMutation!, variables });
  return res.data?.insert_dishes_one.id;
}

const insertDrinkMutation = gql`
  mutation ($object: drinks_insert_input!) {
    insert_drinks_one(object: $object) {
      id
    }
  }
`;

export const createDrink = async (variables: object) => {
  const res=  await gqlClient.mutate<{
    insert_drinks_one: {
      id: string,
    }
  }>({ mutation: insertDrinkMutation!, variables });
  return res.data?.insert_drinks_one.id;
}
