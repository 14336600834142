
import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _createCategoryItemInitQuery = (id: string) => ({
  locations: [{
    name: types.string,
    currency: types.string,
    locale: types.string,
    tax_rate: types.number,
  }],
  categories_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      menu: {
        name: types.string,
      },
    },
  ),
});

type X = Unpacked<typeof _createCategoryItemInitQuery>;
export type Location = X['locations'][0];
export type Category = X['categories_by_pk'];

export const createCategoryItemInitQuery = (id: string) => gql`${query(_createCategoryItemInitQuery(id))}`;
