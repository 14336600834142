import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthContext, EnumUserRole } from '../../AuthProvider';

interface Props extends RouteProps {
  roles: EnumUserRole[],
}

const ProtectedRoleRoute = ({ roles = [], ...rest }: Props): React.ReactElement => {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }

  const { userRole } = authContext;

  if (!userRole) {
    return <Redirect to={window.location.pathname} />
  }

  if (roles.includes(userRole as EnumUserRole)) {
    return (
      <Route {...rest} />
    );
  }
  return <Redirect to="/not-authorised" />
};

export default ProtectedRoleRoute;
