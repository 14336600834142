import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { WithStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { CenteredLayout } from '../../../layouts';
import { AuthContext, locationRoles, organisationRoles } from '../../../components/AuthProvider';
import { Logo } from '../../../components/Logos';
import { validatePassword, validateEmailAddress } from '../../../utils/validators';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface CustomSignInError {
  signIn?: string,
  emailAddress?: string,
  password?: string,
}

const SignIn = ({ classes, history }: Props): React.ReactElement => {
  const authContext = useContext(AuthContext);
  if (authContext === null) {
    throw new Error('No AuthContext');
  }
  const { loggedInUser, userRole, signIn, setLoggedInUser } = authContext;

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<CustomSignInError>({});
  const [signingIn, setSigningIn] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [forgetPassword, setForgetPassword] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    if (mounted && userRole && loggedInUser) {
      let role: string = userRole;
      if (organisationRoles.includes(userRole)) {
        role = 'org';
      }
      if (locationRoles.includes(userRole)) {
        role = 'loc';
      }
      return history.push(`${role}/home`);
    }
    return () => { mounted = false; };
  }, [history, loggedInUser, userRole]);

  const handleSignIn = () => {
    let error = false;
    setErrors({});
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!password) {
      setErrors((e) => ({ ...e, password: 'Please provide a password' }));
      error = true;
    } else if (!validatePassword(password)) {
      setErrors((e) => ({ ...e, password: 'Passwords must be at least 8 characters long and contain at least 1 lower case letter, 1 upper case letter, and a number' }));
      error = true;
    }
    if (!error) {
      handleContinue();
    }
  };

  const handleContinue = useCallback(async () => {
    setSigningIn(true);
    try {
      const signInRequest = await signIn(emailAddress, password);
      if (signInRequest) {
        const { challengeName } = signInRequest;
        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
          setLoggedInUser(signInRequest);
          history.push('/initial-sign-in');
        } else if (challengeName === 'NEW_PASSWORD') {
          history.push('/initial-sign-in');
        } else if (challengeName === 'RESET_REQUIRED') {
          history.push('/reset-password');
        } else {
          setSigningIn(false);
        }
      }
    } catch(err: any) {
      setSigningIn(false);
      if (err.code === 'PasswordResetRequiredException') {
        setResetPassword(true);
      }
      if (err.code === 'UserNotFoundException') {
        setErrors((e) => ({ ...e, signIn: 'Incorrect username and password' }));
      } else {
        setErrors((e) => ({ ...e, signIn: err.message }));
      }
    }
  }, [history, emailAddress, password, setLoggedInUser, signIn]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleContinue();
    }
  };

  const handleResetPassword = () => {
    history.push(`/reset-password?e=${btoa(emailAddress)}`);
  };

  const handleForgotPassword = () => {
    setForgetPassword(true);
  };

  const handleResetPasswordSubmit = async () => {
    let error = false;
    if (!emailAddress) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide an email address' }));
      error = true;
    } else if (!validateEmailAddress(emailAddress)) {
      setErrors((e) => ({ ...e, emailAddress: 'Please provide a valid email address' }));
      error = true;
    }
    if (!error) {
      setSigningIn(true);
      try {
        await Auth.forgotPassword(emailAddress);
        setSigningIn(false);
        setTimeout(() => {
          history.push(`/reset-password?e=${btoa(emailAddress)}`);
        }, 1500);
      } catch (err: any) {
        setSigningIn(false);
        setErrors((e) => ({ ...e, signIn: err.message || 'Unknown error' }));
      }
    }
  };

  const getContent = () => (
    <>
      <div className={classes.root}>
        <TextField
          data-qa="email-textfield"
          required
          label="Email address"
          type="email"
          variant="outlined"
          error={errors.emailAddress !== undefined}
          helperText={errors.emailAddress}
          value={emailAddress}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailAddress(event.target.value || '')} />
        {!forgetPassword && !resetPassword && (
          <TextField
            data-qa="password-textfield"
            className={classes.password}
            required
            label="Password"
            type="password"
            variant="outlined"
            error={errors.password !== undefined}
            helperText={errors.password}
            value={password}
            onKeyDown={handleKeyPress}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value || '')} />
        )}
      </div>
      {errors.signIn && (
        <Typography align="center">{errors.signIn}</Typography>
      )}
      {forgetPassword && !emailAddress && (
        <Typography align="center">Please provide email address</Typography>
      )}
      <div className={classes.buttonContainer}>
        {!signingIn && !resetPassword && !forgetPassword && (
          <>
            <Button data-qa="signin-button" variant="contained" color="primary" onClick={handleSignIn}>SIGN IN</Button>
            <Button className={classes.forgotPassowrd} variant="text" color="secondary" onClick={handleForgotPassword}>Forgot password</Button>
            <Link href="mailto:hello@accentpos.app" className={classes.link} variant="body1" color="primary">If you don't have an account, click here and contact us</Link>
          </>
        )}
        {/* {!signingIn && !resetPassword && !forgetPassword && <Button data-qa="create-account-button" variant="contained" color="secondary" onClick={handleSignIn}>Create account</Button>} */}
        {!signingIn && forgetPassword && !resetPassword && <Button data-qa="request-new-password" variant="contained" color="primary" onClick={handleResetPasswordSubmit}>Request New Password</Button>}
        {!signingIn && resetPassword && <Button data-qa="reset-password-button" variant="contained" color="primary" onClick={handleResetPassword}>RESET PASSWORD</Button>}
        {signingIn && !forgetPassword && (
          <div className={classes.progress}>
            <CircularProgress />
            <Typography className={classes.progressText} variant="subtitle2">Signing in</Typography>
          </div>
        )}
      </div>
    </>
  );

  return (
    <CenteredLayout>
      <div className={classes.modal}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.content}>
          {getContent()}
        </div>
      </div>
    </CenteredLayout>
  );
};

export default withRouter(withStyles(styles)(SignIn));
