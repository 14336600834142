import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
}

const StepEnd = ({ classes }: Props): React.ReactElement => {

  const another = () => window.location.reload();

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.text} variant='body1' align='center'>Thank you for your referral.</Typography>
        <div className={classes.buttonContainer}>
          <Button variant='contained' color='primary' onClick={() => another()}>Refer another</Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(StepEnd);
