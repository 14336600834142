/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyObject } from '../../react-app-env';
import { Sidebar, Topbar } from './components';
import { Breadcrum } from './components/Topbar';

import {
  Paper,
  withStyles,
} from '@material-ui/core';

import {
  HomeOutlined as HomeIcon,
  Home as HomeActiveIcon,

  HomeWorkOutlined as OrganisationIcon,
  HomeWork as OrganisationActiveIcon,

  StoreMallDirectoryOutlined as LocationIcon,
  StoreMallDirectory as LocationActiveIcon,

  GroupAddOutlined as UesrsIcon,
  GroupAdd as UesrsActiveIcon,

  MenuBookOutlined as MenuIcon,
  MenuBook as MenuActiveIcon,

  ListAltOutlined as ReportsActiveIcon,
  ListAlt as ReportsIcon,

  // CalendarTodayOutlined as BookingIcon,
  // CalendarToday as BookingActiveIcon,

  // LocalOfferOutlined as VoucherIcon,
  // LocalOffer as VoucherActiveIcon,

  ReorderOutlined as AccountsIcon,
  Reorder as AccountsActiveIcon,

  PlaylistAddCheckOutlined as ReferralsIcon,
  PlaylistAddCheck as ReferralsActiveIcon,
} from '@material-ui/icons';

import { AuthContext, EnumUserRole } from '../../components/AuthProvider';
import { Hierarchy } from './components/Sidebar/components/HierarchyList';
import { ListItemRoles } from './components/Sidebar/components/SidebarItemsList';
import { getAdminHierarchyQuery, getOrganisationHierarchyQuery } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject> {
  classes: ClassNameMap<string>,
  title?: string;
  breadcrumbs?: Breadcrum[],
}

const listItems: ListItemRoles = {
  admin: {
    key: 'admin',
    title: 'Admin',
    items: [
      { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/admin/home' },
      { label: 'Organisations', icon: <OrganisationIcon />, iconActive: <OrganisationActiveIcon />, to: '/admin/organisations' },
      { label: 'Users', icon: <UesrsIcon />, iconActive: <UesrsActiveIcon />, to: '/admin/users' },
      { label: 'Referrals', icon: <ReferralsIcon />, iconActive: <ReferralsActiveIcon />, to: '/admin/referrals' },
    ],
  },
  owner: {
    key: 'org',
    title: 'Organisation',
    items: [
      { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/org/home' },
      { label: 'Locations', icon: <LocationIcon />, iconActive: <LocationActiveIcon />, to: '/org/locations' },
      { label: 'Menus', icon: <MenuIcon />, iconActive: <MenuActiveIcon />, to: '/org/menus' },
      { label: 'Reports', icon: <ReportsIcon />, iconActive: <ReportsActiveIcon />, to: '/org/reports' },
      { label: 'Users', icon: <UesrsIcon />, iconActive: <UesrsActiveIcon />, to: '/org/users' },
    ],
  },
  manager: {
    key: 'loc',
    title: 'Location',
    items: [
      { label: 'Home', icon: <HomeIcon />, iconActive: <HomeActiveIcon />, to: '/loc/home' },
      // { label: 'Bookings', icon: <BookingIcon />, iconActive: <BookingActiveIcon />, to: '/loc/bookings' },
      { label: 'Accounts', icon: <AccountsIcon />, iconActive: <AccountsActiveIcon />, to: '/loc/orders' },
      { label: 'Menus', icon: <MenuIcon />, iconActive: <MenuActiveIcon />, to: '/loc/menus' },
      // { label: 'Vouchers', icon: <VoucherIcon />, iconActive: <VoucherActiveIcon />, to: '/loc/vouchers' },
      { label: 'Reports', icon: <ReportsIcon />, iconActive: <ReportsActiveIcon />, to: '/loc/reports' },
      { label: 'Users', icon: <UesrsIcon />, iconActive: <UesrsActiveIcon />, to: '/loc/users' },
    ],
  }
}

const Dashboard = ({ classes, title, breadcrumbs, children }: Props): React.ReactElement => {
  const { user, userRole, signOut } = useContext(AuthContext)!;
  const [hierarchy, setHierarchy] = useState<Hierarchy | undefined>();

  const getHierarchy = useCallback(async () => {
    switch (userRole) {
      case EnumUserRole.ADMIN:
        const orgs = await getAdminHierarchyQuery();
        setHierarchy({
          routes: [
            { key: 'owner', label: 'Organisation', home: '/org/home' },
            { key: 'manager', label: 'Location', home: '/loc/home' },
          ],
          items: orgs.map((o) => ({
            key: o.id,
            label: o.name,
            items: o.locations.map((l) => ({
              key: l.id,
              label: l.name,
            })),
          })),
        });
        break;
      case EnumUserRole.OWNER:
        const locs = await getOrganisationHierarchyQuery();
        setHierarchy({
          routes: [
            { key: 'manager', label: 'Location', home: '/loc/home' },
          ],
          items: locs.map((o) => ({
            key: o.id,
            label: o.name,
          })),
        });
        break;
      default:
        break;
    }
  }, [userRole]);

  useEffect(() => {
    let mounted = true;
    if (mounted && userRole) {
      getHierarchy();
    }
    return () => { mounted = false; };
  }, [userRole, getHierarchy]);

  return (
    <>
      <div className={classes.root}>
        <Sidebar
          userRole={userRole as string}
          signOut={signOut}
          listItemRoles={listItems}
          hierarchy={hierarchy}/>
        <Paper
          elevation={0}
          className={classes.main}>
          <Topbar
            title={title}
            user={{ ...user }}
            breadcrumbs={breadcrumbs} />
          <main className={classes.content}>
            <div className={classes.contentContainer}>
              {children}
            </div>
          </main>
        </Paper>
      </div>
    </>
  );
};

export default withStyles(styles)(Dashboard);
