import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../../react-app-env';
import { exactDateString, formatDate, getDateSecondsDiff} from '../../../../../utils/dateUtils';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import {
  GetApp as DownloadIcon,
} from '@material-ui/icons';

import { getKeyValue, numberToMoney } from '../../../../../utils/stringUtils';

import CustomSortableTable, { 
  SortableTableAction,
  SortableTableHeader, 
  SortableTableRow, 
} from '../../../../CustomSortableTable';

import { 
  selectLocationOrdersInitQuery,
  OrderServed,
  Location,
  OrderTakeaway,
  OrderPaymentData,
  Refund,
  OrderDeliverect,
  OrderItem,
} from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  location_id?: string,
  startDate: Date,
  endDate: Date,
  locale: string,
  currency: string,
}

interface DayOrder {
  date: string,
  dishes: number,
  drinks: number,
  dine_in: number,
  quick_sale: number,
  takeaway: number,
  deliverect: number,
  gross: number,
  net: number,
  tax: number,
  discount: number,
  refund: number,
  service_charge: number,
  tip: number,
  cash: number,
  card: number,
  voucher: number,
}

interface Order {
  id: string,
  order_type: EnumOrderType,
  order_type_key: string,
  created_at: string,
  drinks: number,
  dishes: number,
  paid: boolean,
  gross: number,
  net: number,
  tax: number,
  discount: number,
  refund: number,
  service_charge: number,
  tip: number,
  cash: number,
  card: number,
  voucher: number,
}

enum EnumOrderType {
  DINE_IN,
  QUICK_SALE,
  TAKEAWAY,
  DELIVERECT,
}

const ReportDailyTotals = ({ classes, location_id, startDate, endDate, locale, currency }: Props): React.ReactElement => {
  const { data, loading } = useQuery(selectLocationOrdersInitQuery(location_id, exactDateString(startDate), exactDateString(endDate)), { fetchPolicy: 'network-only' });

  const [deliverect, setDeliverect] = useState<boolean>(false);
  const [orders, setOrders] = useState<DayOrder[]>([]);

  const _locationHasDeliverect = (loc: Location) => {
    return loc.integrations.length > 0 && loc.integrations[0].deliverect;
  }
  
  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      try {
        const _reduceLocations = (locs: Location[]) : Location => locs.reduce((a: Location, b: Location) => ({
          integrations: [],
          orders_served: [...a.orders_served, ...b.orders_served],
          orders_takeaway: [...a.orders_takeaway, ...b.orders_takeaway],
          orders_deliverect: [...a.orders_deliverect, ...b.orders_deliverect],
          order_served_payments: [...a.order_served_payments, ...b.order_served_payments],
          order_takeaway_payments: [...a.order_takeaway_payments, ...b.order_takeaway_payments],
          order_deliverect_payments: [...a.order_deliverect_payments, ...b.order_deliverect_payments],
          order_served_dish_items: [...a.order_served_dish_items, ...b.order_served_dish_items],
          order_served_drink_items: [...a.order_served_drink_items, ...b.order_served_drink_items],
          order_takeaway_dish_items: [...a.order_takeaway_dish_items, ...b.order_takeaway_dish_items],
          order_takeaway_drink_items: [...a.order_takeaway_drink_items, ...b.order_takeaway_drink_items],
          order_deliverect_dish_items: [...a.order_deliverect_dish_items, ...b.order_deliverect_dish_items],
          order_deliverect_drink_items: [...a.order_deliverect_drink_items, ...b.order_deliverect_drink_items],
        }), {
          integrations: [],
          orders_served: [],
          orders_takeaway: [],
          orders_deliverect: [],
          order_served_payments: [],
          order_takeaway_payments: [],
          order_deliverect_payments: [],
          order_served_dish_items: [],
          order_served_drink_items: [],
          order_takeaway_dish_items: [],
          order_takeaway_drink_items: [],
          order_deliverect_dish_items: [],
          order_deliverect_drink_items: [],
        });

        const loc = data.locations ? _reduceLocations(data.locations) : data.locations_by_pk;
        setDeliverect(data.locations ? data.locations.some((o: Location) => _locationHasDeliverect(o)) : _locationHasDeliverect(data.locations_by_pk));

        const orders_served: OrderServed[] = loc.orders_served;
        const orders_takeaway: OrderTakeaway[] = loc.orders_takeaway;
        const orders_deliverect: OrderDeliverect[] = loc.orders_deliverect;
        const order_served_payments: OrderPaymentData[] = loc.order_served_payments;
        const order_takeaway_payments: OrderPaymentData[] = loc.order_takeaway_payments;
        const order_deliverect_payments: OrderPaymentData[] = loc.order_deliverect_payments;
        const order_served_dish_items: OrderItem[] = loc.order_served_dish_items;
        const order_served_drink_items: OrderItem[] = loc.order_served_drink_items;
        const order_takeaway_dish_items: OrderItem[] = loc.order_takeaway_dish_items;
        const order_takeaway_drink_items: OrderItem[] = loc.order_takeaway_drink_items;
        const order_deliverect_dish_items: OrderItem[] = loc.order_deliverect_dish_items;
        const order_deliverect_drink_items: OrderItem[] = loc.order_deliverect_drink_items;

        const ords: Order[] = [
          ...(orders_served.map((os: OrderServed) => {
            const payments = order_served_payments.filter((e) => e.order_id === os.id);
            const refunds = payments.filter((e) => e.payment_method === 'REFUND');
            const created_at = new Date(os.created_at);
            // created_at.setHours(0,0,0,0);

            const dishes = order_served_dish_items.filter((i) => i.order_id === os.id).length;
            const drinks = order_served_drink_items.filter((i) => i.order_id === os.id).length;
            const tip = calculateTips(payments, refunds);
            console.log(tip);
            return ({
              ...os,
              created_at: formatDate(created_at, 'YYYY/MM/DD'),
              dishes,
              drinks,
              order_type: os.quick_sale ? EnumOrderType.QUICK_SALE : EnumOrderType.DINE_IN,
              order_type_key: 'SERVED',
              paid: os.paid,
              discount: os.discounts.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              refund: os.refunds.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              service_charge: os.service_charge,
              net: os.total - os.tax + tip,
              tax: os.tax,
              gross: os.total + tip,
              tip,
              cash: calculatePayments(payments, refunds, ['CASH'], false),
              card: calculatePayments(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true),
              voucher: calculatePayments(payments, refunds, ['VOUCHER_AT_DOOR'], false),
            })})),
          ...(orders_takeaway.map((ta: OrderTakeaway) => {
            const payments = order_takeaway_payments.filter((e) => e.order_id === ta.id);
            const refunds = payments.filter((e) => e.payment_method === 'REFUND');
            const created_at = new Date(ta.created_at);
            const tip = calculateTips(payments, refunds);

            // created_at.setHours(0,0,0,0);
            return ({
              ...ta,
              created_at: formatDate(created_at, 'YYYY/MM/DD'),
              dishes: order_takeaway_dish_items.filter((i) => i.order_id === ta.id).length,
              drinks: order_takeaway_drink_items.filter((i) => i.order_id === ta.id).length,
              order_type: EnumOrderType.TAKEAWAY,
              order_type_key: 'TAKEAWAY',
              paid: ta.paid,
              discount: ta.discounts.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              refund: ta.refunds.map((d) => d.before - d.total).reduce((a, b) => a + b, 0),
              service_charge: ta.service_charge,
              net: ta.total - ta.tax + tip,
              tax: ta.tax,
              gross: ta.total + tip,
              tip,
              cash: calculatePayments(payments, refunds, ['CASH'], false),
              card: calculatePayments(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true),
              voucher: calculatePayments(payments, refunds, ['VOUCHER_AT_DOOR'], false),
          })})),
          ...(orders_deliverect.map((de: OrderDeliverect) => {
            const payments = order_deliverect_payments.filter((e) => e.order_id === de.id);
            const refunds = payments.filter((e) => e.payment_method === 'REFUND');
            const created_at = new Date(de.created_at);
            const tip = calculateTips(payments, refunds);
            // created_at.setHours(0,0,0,0);
            return ({
              ...de,
              created_at: formatDate(created_at, 'YYYY/MM/DD'),
              dishes: order_deliverect_dish_items.filter((i) => i.order_id === de.id).length,
              drinks: order_deliverect_drink_items.filter((i) => i.order_id === de.id).length,
              order_type: EnumOrderType.DELIVERECT,
              order_type_key: 'DELIVERECT',
              paid: de.paid,
              created_by_user: de.delivery_by,
              discount: 0,
              refund: 0,
              service_charge: de.service_charge,
              net: de.total - de.tax + tip,
              tax: de.tax,
              gross: de.total +tip,
              tip,
              cash: calculatePayments(payments, refunds, ['CASH'], false),
              card: calculatePayments(payments, refunds, ['CASH', 'REFUND', 'VOUCHER_AT_DOOR'], true),
              voucher: calculatePayments(payments, refunds, ['VOUCHER_AT_DOOR'], false),
          })})),
        ];

        const dayOrders: DayOrder[] = [];

        ords.forEach((ord: Order, index: number) => {
          if(dayOrders.every((i) => i.date !== ord.created_at)) {
            dayOrders.push({
              date: ord.created_at,
              dishes: 0,
              drinks: 0,
              dine_in: 0,
              quick_sale: 0,
              takeaway: 0,
              deliverect: 0,
              gross: 0,
              net: 0,
              tax: 0,
              discount: 0,
              refund: 0,
              service_charge: 0,
              tip: 0,
              cash: 0,
              card: 0,
              voucher: 0,
            });
          }
          const item = dayOrders.find((i) => i.date === ord.created_at);
          if (item) {
            item.dishes += ord.dishes;
            item.drinks += ord.drinks;
            item.gross += ord.gross;
            item.net += ord.net;
            item.tax += ord.tax;
            item.discount += ord.discount;
            item.refund += ord.refund;
            item.service_charge += ord.service_charge;
            item.tip += ord.tip;
            item.cash += ord.cash;
            item.card += ord.card;
            item.voucher += ord.voucher;
            switch (ord.order_type) {
              case EnumOrderType.DINE_IN:
                item.dine_in += 1;
                break;
              case EnumOrderType.QUICK_SALE:
                item.quick_sale += 1;
                break;
              case EnumOrderType.TAKEAWAY:
                item.takeaway += 1;
                break;
              case EnumOrderType.DELIVERECT:
                item.deliverect += 1;
                break;
              default:
                break;
            }
          }
        });
        console.log(dayOrders);
        setOrders(dayOrders);
      } catch(e) {}
    }
    return () => { mounted = false; };
  }, [data]);

  const formatMoney = (v: number, isNegative: boolean = false) => locale && currency ? numberToMoney(isNegative && v > 0 ? -v : v, currency, locale, true) : v;

  const calculatePayments = (payments: OrderPaymentData[], refunds: Refund[], paymentMethods: string[], notPaymentMethod: boolean) => {
    return payments.filter((p) => {
      const id: string = p.id;
      const refId: string | null = p.reference_id || null;
      const paymentMethod: string = p.payment_method;
      if (notPaymentMethod ? !paymentMethods.includes(paymentMethod) : paymentMethods.includes(paymentMethod)) {
        const isRefunded: boolean = refunds.filter((r) => r.id !== id && r.reference_id === refId).length > 0;
        return !isRefunded;
      }
      return false;
    }).reduce((a, b) => a + b.total + b.tip, 0);
  };

  const calculateTips = (payments: OrderPaymentData[], refunds: Refund[]) => {
    return payments.filter((p) => {
      const id: string = p.id;
      const refId: string | null = p.reference_id || null;
        const isRefunded: boolean = refunds.filter((r) => r.id !== id && r.reference_id === refId).length > 0;
        return !isRefunded;
    }).reduce((a, b) => a + b.tip, 0);
  };

  const headers: SortableTableHeader[] = [
    { key: 'date', label: 'Date', format: (v) => formatDate(new Date(v as string), 'dddd Do MMM YY') },
    { key: 'dine_in', label: 'Dine in', align: 'center', format: (v) => v as number > 0 ? v : '' },
    { key: 'quick_sale', label: 'Quick sale', align: 'center', format: (v) => v as number > 0 ? v : '' },
    { key: 'takeaway', label: 'Takeaway', align: 'center', format: (v) => v as number > 0 ? v : '' },
    { key: 'deliverect', label: 'Deliverect', align: 'center', disabled: !deliverect, format: (v) => v as number > 0 ? v : '' },
    { key: 'dishes', label: 'Dishes', align: 'center', format: (v) => v as number > 0 ? v : '' },
    { key: 'drinks', label: 'Drinks', align: 'center', format: (v) => v as number > 0 ? v : '' },
    { key: 'discount', label: 'Discount', format: (v) => formatMoney(v as number) },
    { key: 'refund', label: 'Refund', format: (v) => formatMoney(v as number) },
    { key: 'service_charge', label: 'Service charge', format: (v) => formatMoney(v as number) },
    { key: 'net', label: 'Net', format: (v) => formatMoney(v as number) },
    { key: 'tax', label: 'Tax', format: (v) => formatMoney(v as number) },
    { key: 'gross', label: 'Gross', format: (v) => formatMoney(v as number) },
    { key: 'tip', label: 'Tip', format: (v) => formatMoney(v as number) },
    { key: 'card', label: 'Card', format: (v) => formatMoney(v as number) },
    { key: 'cash', label: 'Cash', format: (v) => formatMoney(v as number) },
    { key: 'voucher', label: 'Voucher', format: (v) => formatMoney(v as number) },
  ];

  const rows: SortableTableRow[] = orders.map((o: DayOrder, index: number) => ({
    key: `${o.date}_${index}`,
    columns: headers.filter((i) => !i.disabled).map((h: SortableTableHeader) => {
      const value = getKeyValue(o, h.key, true);
      let label = value;
      if (h.key === 'time') {
        const v = value as UnknownObject;
        label = v.servedAt ? getDateSecondsDiff(v.createdAt, v.servedAt) : 0;
      }
      return {
        key: h.key,
        label,
        format: h.format,
        data: value,
        component: h.component ? h.component!(value) : undefined,
      };
    }),
  }));

  const createCsvLines = (rows: SortableTableRow[]) => rows.map((r) => r.columns.map((c) => c.format ? c.format(c.label, c.data) : c.label));

  const handleDownloadCsv = (rows: SortableTableRow[]) => {
    const element = document.createElement('a');
    const csvLines = createCsvLines(rows);
    const file = new Blob([headers.filter((h) => !h.hidden && !h.disabled).map((h) => h.key).join(), '\n', csvLines.map(i => i.join(',')).join('\n')], { type: 'text/csv;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    const dateFrom = formatDate(startDate, 'YYYY_MM_DD');
    const dateTo = formatDate(new Date(), 'YYYY_MM_DD');
    element.download = `orders-export-${dateFrom}-${dateTo}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const actions: SortableTableAction[] = [
    { key: 'export', label: 'Export to CSV', onClick: handleDownloadCsv, icon: <DownloadIcon /> }
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <CustomSortableTable
            loading={loading}
            loadingMessage="This may take a minute to load data for more than one month"
            title="Daily Totals"
            counter="filtered"
            orderdBy="date"
            ordered="desc"
            headers={headers}
            rows={rows}
            columnTotals={[
              ['dine_in', undefined, 'center'],
              ['quick_sale', undefined, 'center'],
              ['takeaway', undefined, 'center'],
              ['deliverect', undefined, 'center'],
              ['dishes', undefined, 'center'],
              ['drinks', undefined, 'center'],
              ['discount', (v) => formatMoney(v as number)],
              ['refund', (v) => formatMoney(v as number)],
              ['service_charge', (v) => formatMoney(v as number)],
              ['net', (v) => formatMoney(v as number)],
              ['tax', (v) => formatMoney(v as number)],
              ['gross', (v) => formatMoney(v as number)],
              ['tip', (v) => formatMoney(v as number)],
              ['card', (v) => formatMoney(v as number)],
              ['cash', (v) => formatMoney(v as number)],
              ['voucher', (v) => formatMoney(v as number)],
            ]}
            footer={{
              actions,
            }}
          />
        </>
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ReportDailyTotals));
