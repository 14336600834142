import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { capitaliseFirst } from '../../../../../utils/stringUtils';

import Dashboard from '../../../../../layouts/Dashboard';
import CreateCategory from '../../../../../components/Global/Categories/Create';

import { createCategoryInitQuery, Menu } from './queries';

interface Props extends RouteComponentProps {

}

interface UrlParams {
  menu_id: string,
  category_type: string,
}

const CreateCategoryOrg = ({ history }: Props): React.ReactElement => {
  const { menu_id, category_type } = useParams<UrlParams>();

  const [menu, setMenu] = useState<Menu>({
    name: '',
  });

  const { data: createCategoryInitData } = useQuery(createCategoryInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createCategoryInitData) {
      setMenu(createCategoryInitData.menus_by_pk);
    }
    return () => { mounted = false; };
  }, [createCategoryInitData]);

  const handleFinish = (id: string) => {
    history.push(`/loc/menus/${menu_id}/${category_type}/categories/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Menus', link: `/loc/menus` },
          { label: menu.name, link: `/loc/menus/${menu_id}` },
          { label: `${capitaliseFirst(category_type)} Categories` },
          { label: 'Create' },
        ]}>
        <CreateCategory
          menu_id={menu_id}
          category_type={category_type}
          handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(CreateCategoryOrg);
