import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';
import EditLocation from '../../../../../components/Global/Location/Edit';
import { editLocationInitQuery, Location } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

interface UrlParams {
  organisation_id: string,
  location_id: string,
}

const AdminEditLocation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id, location_id } = useParams<UrlParams>();

  const [location, setLocation] = useState<Location>({
    name: '',
    organisation: {
      name: '',
    },
  });


  const { data: editLocationInitData } = useQuery(editLocationInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationInitData) {
      setLocation(editLocationInitData.locations_by_pk);
    }
    return () => { mounted = false; };
  }, [editLocationInitData]);

  const handleFinish = () => {
    history.push( `/admin/organisations/${organisation_id}/locations/${location_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: location.organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
          { label: location.name, link: `/admin/organisations/${organisation_id}/locations/${location_id}` },
          { label: 'Edit' },
        ]}>
          <EditLocation
            location_id={location_id}
            handleFinish={handleFinish} />
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(AdminEditLocation));
