import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  handleNext: React.Dispatch<React.SetStateAction<void>>,
}

const StepStart = ({ classes, handleNext }: Props): React.ReactElement => {

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.text} variant='body1' align='center'>Welcome to the AccentPOS referral system. Please add the details of your referral here</Typography>
        <div className={classes.buttonContainer}>
          <Button variant='contained' color='primary' onClick={() => handleNext()}>Start</Button>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(StepStart);
