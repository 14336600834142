import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateMenuMutation = gql`
  mutation ($pk_columns: menus_pk_columns_input!, $set: menus_set_input!) {
    update_menus_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateMenu = async (variables: object) => {
  return await gqlClient.mutate<{
    update_menus_by_pk: {
      id: string,
    }
  }>({ mutation: updateMenuMutation!, variables });
}
