import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';

import { Location, ValidationErrors, LocationReducerAction, validation } from '../..';

import styles from './styles';
import { capitaliseFirst } from '../../../../../../utils/stringUtils';
import { orderTypes, OrderType } from '../../../../Menus/Common';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  location: Location,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: LocationReducerAction, value: any }>,
}

const StepServiceCharge = ({ classes, location, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'order_types') {
      errorItems.service_charge_order_types = validation.service_charge_order_types(location.service_charge!.order_types);
    }
    if (field === 'percentage') {
      errorItems.service_charge_percentage = validation.service_charge_percentage(location.service_charge!.percentage);
    }
    setErrors({...errors, ...errorItems});
  }, [location, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <FormControlLabel
          label="Active"
          className={classes.active}
          control={
            <Switch
              checked={location.service_charge != null}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.SERVICE_CHARGE_ACTIVE, value: event.target.checked })}
            />
          }/>
        {location.service_charge != null && (
          <>
            <FormControl
              className={classes.orderType}
              component="fieldset"
              required
              error={errors.service_charge_order_types as boolean} >
              <FormLabel component="legend">Order type</FormLabel>
              <FormGroup className={classes.formGroup}>
                {orderTypes.map((orderType: OrderType) => (
                  <FormControlLabel
                    key={orderType as string}
                    control={<Checkbox
                      name={orderType}
                      color="primary"
                      checked={location.service_charge!.order_types.includes(orderType)}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.SERVICE_CHARGE_ORDER_TYPES, value: { orderType, active: event.target.checked } })}
                      onBlur={() => validate('menuType')} />}
                    label={capitaliseFirst(orderType.replace(/_/g, ' '))} />
                ))}
              </FormGroup>
              <FormHelperText>{errors.service_charge_order_types}</FormHelperText>
            </FormControl>

            <TextField
              className={classes.textField}
              data-qa="percentage-textfield"
              required
              label="Percentage"
              variant="outlined"
              type="number"
              inputProps={{
                step: 0.1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              error={errors.service_charge_percentage as boolean}
              helperText={errors.service_charge_percentage}
              value={location.service_charge!.percentage || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.SERVICE_CHARGE_PERCENT, value: event.target.value })}
              onKeyUp={() => validate('percentage')}
              onBlur={() => validate('percentage')} />

            <TextField
              className={classes.textField}
              data-qa="covers-textfield"
              required
              label="Minimum covers"
              type="number"
              variant="outlined"
              value={location.service_charge!.min_covers}
              helperText="Minimum covers to apply service charge. When zero service charge will be applied to all orders"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.SERVICE_CHARGE_COVERS, value: event.target.value })} />
          </>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepServiceCharge);
