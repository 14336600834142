import { UnknownObject } from "../react-app-env";

export const capitaliseFirst = (value: string) => {
  const words = value?.toLowerCase().split(' ');
  return words.map((i) => i ? `${i[0].toUpperCase()}${i.slice(1)}` : '').join(' ');
};

export const capitaliseFirstOnly = (value: string) => {
  const words = value.split('. ');
  return words.map((i) => i ? `${i[0].toUpperCase()}${i.slice(1)}` : '').join('. ');
};

export const sentenceCase = (value: string) => {
  const words = value?.toLowerCase().split('. ');
  return words.map((i) => i ? `${i[0].toUpperCase()}${i.slice(1)}` : '').join('. ');
};

export const camelToCapital = (value: string) => {
  const words = value?.replace(/([A-Z])/g, ',$1').replace(/^,/, '').split(',');
  return words.map((i) => `${i[0].toUpperCase()}${i.slice(1)}`).join(' ');
};

export const camelToSpaces = (value: string) => {
  return value?.replace(/([A-Z])/g, ',$1').replace(/^,/, '').split(',').join(' ');
};

export const wordsToAcronym = (value: string) => {
  const words = value ? value.split(' ').filter((i) => i) : [];
  return words.map((i) => i[0].toUpperCase()).join('');
};

export const stringFromArray = (words: string[], divider: string = ' ') => {
  return words.join(divider);
};

export const numbersOnly = (value: string) => {
  return value?.replace(/(\D+^.)/g, '');
};

export const pluralise = (value: string | string[], length: number): string => {
  let plu = 's';
  if (value.constructor.name === 'Array') {
    return length === 1 ? value[0] : value[1];
  }
  let v = value as string;
  if (v.endsWith('h')) {
    plu = 'es';
  }
  if (v.endsWith('y')) {
    if ((length !== 1)) {
      plu = 'ies';
      v = v.slice(0, -1);
    }
  }
  return `${v}${length === 1 ? '' : plu}`;
};

export const dateToFormattedString = (value: string | number | Date) => {
  const d = new Date(value);
  return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`;
};

export const timeToFormattedString = (value: string, format: string, noTimeZone: boolean = false) => {
  if (value) {
    let sp = 0;
    switch (format) {
      case 'H':
        sp = 1;
        break;
      case 'HM':
        sp = 2;
        break;
      case 'HMS':
        sp = 3;
        break;
      case 'HMSM':
        sp = 4;
        break;
      default:
        break;
    }
    const time = value.replace('.', ':').split(':').slice(0, sp).join(':');
    return noTimeZone ? removeTimeZone(time) : time;
  }
  return value;
};

export const timeToSeconds = (time: string) => {
  const [h, m, s] = time.split(':');
  return ((+h) * 60 * 60) + ((+m) * 60) + (+s);
}

export const secondsToTime = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().split('T')[1].slice(0, -1).split('.')[0];
}

export const removeTimeZone = (value: string) => {
  return value.split('+')[0];
}

export const generateShortUuid = () => {
  return Math.random().toString(36).slice(-6);
};

export const generateNumberUuid = (length: number) => {
  return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
};

export const booleanToYesNo = (value: boolean) => {
  return value ? 'Yes' : 'No';
}

export const numbersToWords = (limit: number) => {
  const n = [
    'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Nineth', 'Tenth',
    'Eleventh', 'Twelveth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth', 'Twentieth',
  ];
  return n.slice(0, limit);
};


export const numericToAlpha = (num: number): string => {
  let s: string = '';
  let t: number;
  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = (num - t)/26 | 0;
  }
  return s;
}

export const getKeyValue = (obj: UnknownObject, key: string, booleanAsString = false): string | number | UnknownObject => {
  const nesting: string[] = key.split('.');
  if (nesting.length > 1) {
    return getKeyValue(obj[nesting[0]], nesting.slice(1).join('.'));
  }
  if (booleanAsString && (obj[key] === true || obj[key] === false)) {
    return booleanToYesNo(obj[key]);
  }
  return obj[key];
};

export const numberToMoney = (value: number, currency: string, locale: string, isPennies = false) => {
  if (!currency || !locale) {
    return '';
  }
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });
  return formatter.format(isPennies ? value / 100 : value);
}

export const getCurrencySymbol = (currency: string, locale: string) => {
  if (!currency || !locale) {
    return '';
  }
  const m = numberToMoney(0, currency, locale);
  return m.replace(/\d./g, '').trim();
}


export const removeDuplicateWords = (value: string) => value.split(' ').filter((item, i, allItems) => i === allItems.indexOf(item)).join(' ');