import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: theme.spacing(45),
    position: 'sticky',
    top: 0,
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    cursor: 'default',
  },
  completed: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  label: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
  },
  active: {
    color: theme.palette.primary.main,
  },
  detail: {
    // marginLeft: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.grey[600],
    // '&::before': {
    //   content: '"-"',
    //   marginRight: theme.spacing(0.5),
    // },
  },
  completedIcon: {
    height: 24,
    justifySelf: 'flex-end',
    color: theme.palette.success.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  action: {
    width: '100%',
  },
});

export default styles;
