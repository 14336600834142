import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';

const updateLocationMutation = gql`
  mutation ($pk_columns: locations_pk_columns_input!, $set: locations_set_input!) {
    update_locations_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateLocation = async (variables: object) => {
  return await gqlClient.mutate<{
    update_locations_by_pk: {
      id: string,
    }
  }>({ mutation: updateLocationMutation!, variables });
}
