import React, { useState, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { locationPrintersReducer, Menu } from '../Common';
import Steps from '../Common/components/Steps';

import { createLocationPrinter } from './mutations';

import styles from './styles';
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  location_id?: string,
  locations: string[],
  menus: Menu[],
  handleFinish: () => void,
}

const CreateLocationStaff = ({ classes, history, location_id, locations, menus, handleFinish }: Props): React.ReactElement => {

  const [printer, dispatchPrinter] = useReducer(locationPrintersReducer, {
    name: '',
    location: '',
    printCopyToTill: false,
    categories: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const { location, categories, printCopyToTill, name } = printer;

    const createVariables = {
      object: {
        location_id,
        name: name.trim(),
        device_id: name,
        device_type: 'PRINTER',
        device_config: {
          location: location.trim(),
          categories,
          printCopyToTill,
        },
      }
    };
    const printer_id: string | undefined = await createLocationPrinter(createVariables);
    if (printer_id) {
      setSaving(false);
      handleFinish();
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        printer={printer}
        dispatch={dispatchPrinter}
        locations={[...new Set(locations)]}
        menus={menus}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating Printer" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateLocationStaff));
