import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  FormControlLabel,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';

import { DeliverectIntegration, LocationIntegrationReducerAction, ValidationErrors, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  deliverect: DeliverectIntegration,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: LocationIntegrationReducerAction, value: any}>,
}

const StepDeliverect = ({ classes, deliverect, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'accountId') {
      errorItems.accountId = validation.deliverectAccountId(deliverect.accountId);
    }
    if (field === 'locationId') {
      errorItems.locationId = validation.deliverectLocationId(deliverect.locationId);
    }
    setErrors({...errors, ...errorItems});
  }, [deliverect, errors, setErrors]);

  return (
    <>
      <div className={classes.root}>
        <FormControlLabel
          label="Active"
          className={classes.active}
          control={
            <Switch
              checked={deliverect.active}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.DELIVERECT_ACTIVE, value: event.target.checked })}
            />
          }/>
          {deliverect.active && (
            <>
              <TextField
                className={classes.textField}
                data-qa="accountId-textfield"
                required
                label="Account ID"
                variant="outlined"
                error={errors.paymentAccountId as boolean}
                helperText={errors.paymentAccountId}
                value={deliverect.accountId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.DELIVERECT_ACCOUNT_ID, value: event.target.value })}
                onKeyUp={() => validate('accountId')}
                onBlur={() => validate('accountId')} />
              <TextField
                className={classes.textField}
                data-qa="locationId-textfield"
                required
                label="Location ID"
                variant="outlined"
                error={errors.deliverectLocationId as boolean}
                helperText={errors.deliverectLocationId}
                value={deliverect.locationId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.DELIVERECT_LOCATION_ID, value: event.target.value })}
                onKeyUp={() => validate('locationId')}
                onBlur={() => validate('locationId')} />
              <FormControlLabel
                label="Auto accept"
                className={classes.active}
                control={
                  <Switch
                    checked={deliverect.autoAccept}
                    color="primary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.DELIVERECT_AUTO_ACCEPT, value: event.target.checked })}
                  />
                }/>
            </>
          )}
      </div>
    </>
  );
};

export default withStyles(styles)(StepDeliverect);
