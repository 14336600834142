import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  FormControlLabel,
  Switch,
  withStyles,
} from '@material-ui/core';

import { LocationIntegrationReducerAction } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  active: boolean,
  dispatch: React.Dispatch<{ type: LocationIntegrationReducerAction, value: any}>,
}

const StepVouchers = ({ classes, active, dispatch }: Props): React.ReactElement => {

  return (
    <>
      <div className={classes.root}>
        <FormControlLabel
          label="Active"
          className={classes.active}
          control={
            <Switch
              checked={active}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationIntegrationReducerAction.VOUCHERS, value: event.target.checked })}
            />
          }/>
      </div>
    </>
  );
};

export default withStyles(styles)(StepVouchers);
