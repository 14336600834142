import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { useQuery } from '@apollo/client';

import {
  Grid,
  withStyles,
} from '@material-ui/core';

import {
  GetApp as DownloadIcon,
} from '@material-ui/icons';

import { getKeyValue, numberToMoney, pluralise } from '../../../../../utils/stringUtils';
import { exactDateString, formatDate } from '../../../../../utils/dateUtils';
import CustomSortableTable, { ColumnTotals, SortableTableAction, SortableTableHeader, SortableTableLabel, SortableTableRow } from '../../../../CustomSortableTable';
import { selectMenuItemInitQuery, MenuCategroy, DishAssignment, DrinkAssignment, Dish, Drink, DishItem, DrinkItem } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  startDate: Date,
  endDate: Date,
  locale: string,
  currency: string,
}

interface Category {
  id: string,
  name: string,
  gross: number,
  quantity: number,
  dish_items: CategoryItem[],
  drink_items: CategoryItem[],
}

interface CategoryItem {
  id: string,
  name: string,
  quantity: number,
  tax: number,
  gross: number,
}

const ReportMenuItemSales = ({ classes, startDate, endDate, locale, currency }: Props): React.ReactElement => {
  const [categories, setCategories] = useState<Category[]>([]);

  const { data, loading } = useQuery(selectMenuItemInitQuery(exactDateString(startDate), exactDateString(endDate)), { fetchPolicy: 'network-only' });

  useEffect(() => {
    let mounted = true;
    if (mounted && data) {
      const categoryDishes = data.categories.filter((i: MenuCategroy) => i.category_type === 'DISH');
      const categoryDrinks = data.categories.filter((i: MenuCategroy) => i.category_type === 'DRINK');

      const dishes: DishItem[] = [...data.order_served_dish_items, ...data.order_takeaway_dish_items, ...data.order_deliverect_dish_items];
      const drinks: DrinkItem[] = [...data.order_served_drink_items, ...data.order_takeaway_drink_items, ...data.order_deliverect_drink_items];

      const catDishes: Category[] = [];
      const catDrinks: Category[] = [];

      categoryDishes.forEach((c: MenuCategroy) => {
        const category: Category = {
          id: c.id,
          name: c.name,
          gross: 0,
          quantity: 0,
          dish_items: [],
          drink_items: [],
        };
        const items = c.dish_assignments.map((i: DishAssignment) => i.dish);

        items.forEach((i: Dish) => {
          const { id, name, tax } = i;
          const dish: CategoryItem = {
            id,
            name,
            quantity: 0,
            tax,
            gross: 0,
          }
          dishes.filter((d: DishItem) => d.item.id === id).forEach((d: DishItem) => {
            dish.quantity++;
            dish.gross += d.price;
          });
          category.dish_items.push(dish);
        });
        category.quantity = category.dish_items.reduce((a: number, b: CategoryItem) => a + b.quantity, 0);
        category.gross = category.dish_items.reduce((a: number, b: CategoryItem) => a + b.gross, 0);
        category.dish_items.sort((a, b) => b.quantity - a.quantity);

        catDishes.push(category);
      });

      categoryDrinks.forEach((c: MenuCategroy) => {
        const category: Category = {
          id: c.id,
          name: c.name,
          gross: 0,
          quantity: 0,
          dish_items: [],
          drink_items: [],
        };
        const items = c.drink_assignments.map((i: DrinkAssignment) => i.drink);

        items.forEach((i: Drink) => {
          const { id, name, tax } = i;
          const drink: CategoryItem = {
            id,
            name,
            quantity: 0,
            tax,
            gross: 0,
          }
          drinks.filter((d: DrinkItem) => d.item.id === id).forEach((d: DrinkItem) => {
            drink.quantity++;
            drink.gross += d.price;
          });
          category.drink_items.push(drink);
        });
        category.quantity = category.drink_items.reduce((a: number, b: CategoryItem) => a + b.quantity, 0);
        category.gross = category.drink_items.reduce((a: number, b: CategoryItem) => a + b.gross, 0);
        category.drink_items.sort((a, b) => b.quantity - a.quantity);
        catDrinks.push(category);
      });

      setCategories([...catDishes, ...catDrinks]);
    }
    return () => { mounted = false; };
  }, [data]);

  const formatMoney = (v: number, isNegative: boolean = false) => numberToMoney(isNegative && v > 0 ? -v : v, currency, locale, true);

  const headers: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'quantity', label: 'Quantity' },
    { key: 'tax', label: '' },
    { key: 'gross', label: 'Gross sales', format: (v: SortableTableLabel) => formatMoney(v as number) },
  ];

  const itemHeaders: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'quantity', label: 'Quantity' },
    { key: 'tax', label: 'Tax rate' },
    { key: 'gross', label: 'Gross sales' },
  ];

  const rows: SortableTableRow[] = categories.map((o: Category, index: number) => ({
    key: `${o.id}_${index}`,
    columns: headers.map((h: SortableTableHeader) => ({
      key: h.key,
      label: getKeyValue(o, h.key, true),
      format: h.format,
      component: h.component ? h.component!(getKeyValue(o, h.key, true)) : undefined,
    })),
    collapsible: [
      {
        title: pluralise('Dish', o.dish_items.length),
        counter: o.dish_items.length,
        headers: itemHeaders,
        rows: o.dish_items.map((i) => ({
          key: i.id,
          columns: [
            { key: 'name', label: i.name },
            { key: 'quantity', label: i.quantity },
            { key: 'tax', label: i.tax, format: (v: SortableTableLabel) => `${v as number / 10}%` },
            { key: 'gross', label: i.gross, format: (v: SortableTableLabel) => formatMoney(v as number) },
          ],
        })),
        columnTotals: [
          ['quantity'],
          ['gross', (v: SortableTableLabel) => formatMoney(v as number)],
        ] as ColumnTotals[],
      },
      {
        title: pluralise('Drink', o.drink_items.length),
        counter: o.drink_items.length,
        headers: itemHeaders,
        rows: o.drink_items.map((i) => ({
          key: i.id,
          columns: [
            { key: 'name', label: i.name },
            { key: 'quantity', label: i.quantity },
            { key: 'tax', label: i.tax, format: (v: SortableTableLabel) => `${v as number / 10}%` },
            { key: 'gross', label: i.gross, format: (v: SortableTableLabel) => formatMoney(v as number) },
          ],
        })),
        columnTotals: [
          ['quantity'],
          ['gross', (v: SortableTableLabel) => formatMoney(v as number)],
        ] as ColumnTotals[],
      }
    ].filter((i) => i.rows.length > 0)
  }));

  const createCsvLines = (rows: SortableTableRow[]) => {
    return rows.flatMap((r) => {
      const cat = r.columns.map((c) => c.format ? c.format(c.label) : c.label);
      const items = r.collapsible!.flatMap((c) => c.rows.map((colR) => colR.columns.map((i) => i.format ? i.format(i.label) : i.label)));
      return [cat, ...items];
    });
  };

  const handleDownloadCsv = (rows: SortableTableRow[]) => {
    const element = document.createElement('a');
    const csvLines = createCsvLines(rows);
    const file = new Blob([headers.filter((h) => !h.hidden).map((h) => h.key).join(), '\n', csvLines.map(i => i.join(',')).join('\n')], { type: 'text/csv;charset=utf-8' });
    element.href = URL.createObjectURL(file);
    const dateFrom = formatDate(startDate, 'YYYY_MM_DD');
    const dateTo = formatDate(endDate, 'YYYY_MM_DD');
    element.download = `plu-export-${dateFrom}-${dateTo}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const actions: SortableTableAction[] = [
    { key: 'export', label: 'Export to CSV', onClick: handleDownloadCsv, icon: <DownloadIcon /> }
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <>
          <CustomSortableTable
            noSorting
            title="Categories"
            searchable={['name']}
            counter={rows.length}
            headers={headers}
            rows={rows}
            loading={loading}
            columnTotals={[
              ['quantity'],
              ['gross', (v) => formatMoney(v as number)],
            ]}
            footer={{
              actions,
            }}
          />
        </>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ReportMenuItemSales);
