
import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _createVoucherCodeInitQuery = () => ({
  categories: [{
    id: types.string,
    name: types.string,
    menu: {
      name: types.string,
    },
  }],
  vouchers_code: [{
    id: types.string,
    code: types.string,
  }],
});

type X = Unpacked<typeof _createVoucherCodeInitQuery>;
export type Category = X['categories'][0];
export type CodeVouchers = X['vouchers_code'][0];

export const createVoucherCodeInitQuery = () => gql`${query(_createVoucherCodeInitQuery())}`;
