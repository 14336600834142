import { removeTimeZone } from "../../../../utils/stringUtils";
import { ValidationType } from '../../../../react-app-env';

export interface Menu {
  name: string,
  description: string,
  active: boolean,
  all_day: boolean,
  available_from: string,
  available_to: string,
  menu_types: OrderType[],
}

export interface ValidationErrors {
  name: ValidationType,
  description: ValidationType,
  availableFrom: ValidationType,
  availableTo: ValidationType,
  menuType: ValidationType,
  incompatibleTimes: ValidationType,
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  description: 'Please provide a description',
  availableFrom: 'Please provide a time the menu is avaliable from',
  availableTo: 'Please provide a time the menu is avaliable to',
  menuType: 'Please select at least one menu type',
  incompatibleTimes: 'Please provide an avaliable from time that starts before avaliable to',
}

export interface Validation {
  name: (name: string) => ValidationType,
  description: (description: string) => ValidationType,
  availableFrom: (availableFrom: string) => ValidationType,
  availableTo: (availableTo: string) => ValidationType,
  menuType: (menuTypes: OrderType[]) => ValidationType,
  incompatibleTimes: (availableFrom: string, availableTo: string) => ValidationType,
}

export const validation: Validation = {
  name: (name) => !name ? validationErrors.name : false,
  description: (description) => !description ? validationErrors.description : false,
  availableFrom: (availableFrom) => !availableFrom ? validationErrors.availableFrom : false,
  availableTo: (availableTo) => !availableTo ? validationErrors.availableTo : false,
  menuType: (menuTypes) => menuTypes.length === 0 ? validationErrors.menuType : false,
  incompatibleTimes: (availableFrom, availableTo) => {
    const [hF, mF, sF, msF] = availableFrom.split(':');
    const [hT, mT, sT, msT] = availableTo.split(':');
    if (
      (hT < hF) ||
      (hT === hF && mT < mF) ||
      (hT === hF && mT === mF && sT < sF) ||
      (hT === hF && mT === mF && sT === sF && msT < msF)
    ) {
      return validationErrors.incompatibleTimes;
    }
    return false;
  }
}

export enum OrderType {
  DINE_IN = 'DINE_IN',
  QUICK_SALE = 'QUICK_SALE',
  TAKEAWAY = 'TAKEAWAY',
  DELIVERECT = 'DELIVERECT',
}

export const orderTypes = [OrderType.DINE_IN, OrderType.QUICK_SALE, OrderType.TAKEAWAY, OrderType.DELIVERECT];

export enum MenuReducerAction {
  NAME,
  DESCRIPTION,
  ACTIVE,
  ALL_DAY,
  AVAILABLE_FROM,
  AVAILABLE_TO,
  MENU_TYPE,
  INIT,
}

export const menuReducer = (state: Menu, action: { type: MenuReducerAction, value: any }): Menu =>  {
  switch (action.type) {
    case MenuReducerAction.NAME:
      return { ...state, name: action.value };
    case MenuReducerAction.DESCRIPTION:
      return { ...state, description: action.value };
    case MenuReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case MenuReducerAction.ALL_DAY:
      const af = '00:00:00:000';
      const at = '23:59:59:999';
      const ad = action.value as boolean;
      return { ...state, all_day: ad, available_from: af, available_to: at };
    case MenuReducerAction.AVAILABLE_FROM:
      return { ...state, available_from: action.value };
    case MenuReducerAction.AVAILABLE_TO:
      return { ...state, available_to: action.value };
    case MenuReducerAction.MENU_TYPE:
      let mTypes = state.menu_types;
      if (action.value.active) {
        mTypes.push(action.value.menuType as OrderType);
      } else {
        mTypes = mTypes.filter((m) => m !== action.value.menuType);
      }
      return { ...state, menu_types: mTypes };
    case MenuReducerAction.INIT:
      const {
        name,
        description,
        active,
        available_from,
        available_to,
        menu_types,
      } = action.value;
      let all_day = false;
      if (available_from && available_to) {
        const [hF, mF, sF] = removeTimeZone(available_from).split(':');
        const [hT, mT, sT] = removeTimeZone(available_to).split(':');
        all_day = (hF === '00' && hT === '23') && (mF === '00' && mT === '59') && (sF === '00' && sT === '59');
      }
      const obj = {
        name,
        description,
        active,
        all_day,
        available_from,
        available_to,
        menu_types,
      };
      return { ...obj as Menu };
    default:
      throw new Error();
  }
}
