import React, { useEffect, useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';

import { timeToFormattedString } from '../../../../utils/stringUtils';

import { menuReducer, MenuReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { updateMenu } from './mutations';
import { editMenuInitQuery } from './queries';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  menu_id: string,
  handleFinish: (id: string) => void,
}

const EditMenu = ({ classes, history, menu_id, handleFinish }: Props): React.ReactElement => {
  const [menu, dispatchMenu] = useReducer(menuReducer, {
    name: '',
    description: '',
    active: true,
    all_day: false,
    menu_types: [],
    available_from: '00:00:00:000',
    available_to: '23:59:59:999',
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editMenuInitData } = useQuery(editMenuInitQuery(menu_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editMenuInitData) {
      dispatchMenu({ type: MenuReducerAction.INIT, value: editMenuInitData.menus_by_pk });
    }
    return () => { mounted = false; };
  }, [editMenuInitData]);

  const handleSave = async () => {
    setSaving(true);

    const mData: UnknownObject = { ...menu };
    delete mData.all_day;
    mData.available_from = `${timeToFormattedString(mData.available_from, 'HMS', true)}`;
    mData.available_to = `${timeToFormattedString(mData.available_to, 'HMS', true)}`;

    const updateMenuVariables = {
      pk_columns: {
        id: menu_id,
      },
      set: mData,
    };

    await updateMenu(updateMenuVariables);

    setSaving(false);
    handleFinish(menu_id);
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        menu={menu}
        dispatch={dispatchMenu}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Menu" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditMenu));
