import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../../layouts/Dashboard';

import { createLocationInitQuery, Organisation } from './queries';
import CreateLocation from '../../../../../components/Global/Location/Create';

import styles from './styles';
interface UrlParams {
  organisation_id: string,
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const AdminCreateLocation = ({ classes, history }: Props): React.ReactElement => {
  const { organisation_id } = useParams<UrlParams>();

  const [organisation, setOrganisation] = useState<Organisation>({
    name: '',
    tax_rate: 0,
  });

  const { data: createLocationInitData } = useQuery(createLocationInitQuery(organisation_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createLocationInitData) {
      setOrganisation(createLocationInitData.organisations_by_pk);
    }
    return () => { mounted = false; };
  }, [createLocationInitData]);

  const handleFinish = (id: string) => {
    history.push(`/admin/organisations/${organisation_id}/locations/${id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Organisations', link: '/admin/organisations' },
          { label: organisation.name, link: `/admin/organisations/${organisation_id}` },
          { label: 'Locations', link: `/admin/organisations/${organisation_id}/locations` },
          { label: 'Create' },
        ]}>
        {organisation.tax_rate && (
          <CreateLocation
            organisation_id={organisation_id}
            tax_rate={organisation.tax_rate}
            handleFinish={handleFinish} />
        )}
      </Dashboard>
    </>
  );
};

export default withRouter(withStyles(styles)(AdminCreateLocation));
