
import { types, query } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _menusInitQuery = (id: string) => ({
  menus: [{
    id: types.string,
    name: types.string,
    description: types.string,
    active: types.boolean,
    order_index: types.number,
    available_from: types.string,
    available_to: types.string,
  }],
});

type X = Unpacked<typeof _menusInitQuery>
export type Menu = X['menus'][0];

export const menusInitQuery = (id: string) => gql`${query(_menusInitQuery(id))}`;
