import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootNoHeight: {
    height: 'unset',
  },
  title: {
    fontWeight: 700,
    paddingBottom: 0,
    marginLeft: theme.spacing(2),
    textAlign: 'center',
    fontSize: 24,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    // padding: theme.spacing(3),
    paddingBottom: 0,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  container: {
    height: '100%',
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(120),
    },
  },
  stepperContainer: {
    paddingTop: theme.spacing(3),
  },
  stepper: {
    padding: 0,
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    width: '100%',
  },
  logos: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    flex: '0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    
    // width: theme.spacing(45),
  },
  aposLogo: {
    alignSelf: 'center',
    paddingRight: theme.spacing(1),
    width: 150,
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
    // '& svg': {
    //   width: 60,
    //   [theme.breakpoints.up('md')]: {
    //     width: 200,
    //   },
    // },
  },
  partnerLogo: {
    alignSelf: 'center',
    paddingLeft: theme.spacing(1),
    width: 70,
    [theme.breakpoints.up('md')]: {
      width: 100,
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  passwordDetail: {
    marginBottom: theme.spacing(2),
  },
});

export default styles;
