import React, { useCallback, useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { EmptyObject } from '../../react-app-env';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  Grid,
  GridSize,
  withStyles,
} from '@material-ui/core';

import ColumnStepper from './ColumnStepper';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, React.PropsWithChildren<EmptyObject>, RouteComponentProps {
  classes: ClassNameMap<string>,
  completeLabel: string,
  isNextDisabled: boolean,
  gridSize?: GridSize,
  progressGridSize?: GridSize,
  children: CustomStepperStep[],
  step: number,
  overrideSetStep?: boolean,
  handleStepOveride?: (isNext: boolean) => void,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  validate: (i: number) => boolean,
  handleComplete: () => void,
  handleAnother?: () => void,
}

interface CustomStepperStep {
  label: string,
  detail?: string | null,
  gridSize?: GridSize,
  nextLabel?: string,
  disabled?: boolean,
  handleNext?: () => void,
  component: React.ReactElement,
}

const CutsomStepper = ({ classes, history, children = [], completeLabel, isNextDisabled, gridSize = 4, progressGridSize = 4, step, overrideSetStep = false, handleStepOveride, setStep, validate, handleComplete, handleAnother }: Props): React.ReactElement => {
  const [currentStep, setCurrentStep] = useState<number>(step);

  const updateCurrentStepOnStepChange = useCallback((nStep: number) => {
    if (currentStep !== nStep) {
      setTimeout(() => {
        setCurrentStep(nStep);
      }, 300);
    }
  }, [currentStep]);

  const updateStepOnCurrentStepChange = useCallback((nCurrentStep: number) => {
    if (step !== nCurrentStep) {
      setTimeout(() => {
        setStep(nCurrentStep);
      }, 300);
    }
  }, [step, setStep]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      updateStepOnCurrentStepChange(currentStep);
    }
    return () => { mounted = false; };
  },[currentStep, updateStepOnCurrentStepChange]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      updateCurrentStepOnStepChange(step);
    }
    return () => { mounted = false; };
  },[step, updateCurrentStepOnStepChange]);

  const handleSetStep = (index: number) => {
    setStep(index);
    setCurrentStep(index);
  }

  const handleNextStep = () => {
    if (!validate(step)) {
      if (!overrideSetStep) {
        setStep((i: number) => i + 1);
        setCurrentStep((i: number) => i + 1);
      } else if(handleStepOveride) {
        handleStepOveride(true);
      }
    }
  };

  const handlePreviousStep = () => {
    if (!overrideSetStep) {
      setStep((i: number) => i - 1);
      setCurrentStep((i: number) => i - 1);
    } else if(handleStepOveride) {
      handleStepOveride(false);
    }
  }

  return (
    <>
      <Grid container justify="space-between" spacing={3}>
        <Grid item xs={children[step].gridSize || gridSize}>
          <Grid container>
            <Grid item xs={12}>
              {children[step].component}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={progressGridSize}>
          <ColumnStepper
            title="Progress"
            handleStepClick={handleSetStep}
            steps={children.filter((i) => !i.disabled).map((i) => ({ label: i.label, detail: i.detail, nextLabel: i.nextLabel, handleNext: i.handleNext }))}
            step={currentStep}
            completeLabel={completeLabel}
            isNextDisabled={isNextDisabled}
            handlePrevious={handlePreviousStep}
            handleNext={handleNextStep}
            handleComplete={handleComplete}
            handleAnother={handleAnother ? () => { handleSetStep(0); handleAnother(); } : undefined}/>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(withStyles(styles)(CutsomStepper));
