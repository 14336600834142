import React, { useState, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
} from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { CodeVoucherType, Category, codeVoucherReducer, UsedCode } from '../Common';
import Steps from '../Common/components/Steps';

import { createVoucherCode } from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
  organisation_id?: string,
  location_id?: string,
  categories: Category[],
  used_codes: UsedCode[],
  handleFinish: (id: string) => void,
}

const CreateVoucher = ({ classes, history, organisation_id, location_id, categories, used_codes, handleFinish }: Props): React.ReactElement => {
  const [voucher, dispatchVoucher] = useReducer(codeVoucherReducer, {
    name: '',
    code: '',
    value: 0,
    voucher_type: CodeVoucherType.PERCENT,
    single_use: false,
    can_expire: false,
    start_date: new Date(),
    end_date: new Date(),
    category_ids: [],
  });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async () => {
    setSaving(true);

    const sd: Date = voucher.start_date;
    const start_date = voucher.can_expire ? `${[sd.getFullYear(), `${sd.getMonth() + 1}`.padStart(2, '0'), `${sd.getDate()}`.padStart(2, '0')].join('-')}T${[0, 0, 0].join(':')}` : undefined;
    const ed: Date = voucher.end_date;
    const end_date = voucher.can_expire ? `${[ed.getFullYear(), `${ed.getMonth() + 1}`.padStart(2, '0'), `${ed.getDate()}`.padStart(2, '0')].join('-')}T${[23, 59, 59].join(':')}` : null;

    const insertLocationVariables = {
      object: {
        organisation_id,
        location_id,
        ...voucher,
        start_date,
        end_date,
      },
    };
    const voucher_id: string | undefined = await createVoucherCode(insertLocationVariables);
    if (voucher_id) {
      setSaving(false);
      handleFinish(voucher_id);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        voucher={voucher}
        categories={categories}
        used_codes={used_codes}
        dispatch={dispatchVoucher}
        handleSave={handleSave} />
      {saving && <CustomBackdrop label="Creating Voucher" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateVoucher));
