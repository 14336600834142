import React, { useCallback } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';

import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';

import { Location, ValidationErrors, LocationReducerAction, validation } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  location: Location,
  errors: ValidationErrors,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  dispatch: React.Dispatch<{ type: LocationReducerAction, value: any }>,
}

const StepDetails = ({ classes, location, errors, setErrors, dispatch }: Props): React.ReactElement => {
  const validate = useCallback((field: string) => {
    const errorItems: UnknownObject = {}
    if (field === 'number_of_tables') {
      errorItems.number_of_tables = validation.number_of_tables(location.number_of_tables);
    }
    if (field === 'tax_number') {
      errorItems.tax_number = validation.tax_number(location.tax_number);
    }
    if (field === 'locale') {
      errorItems.locale = validation.locale(location.locale);
    }
    if (field === 'currency') {
      errorItems.currency = validation.currency(location.currency);
    }
    setErrors({...errors, ...errorItems});
  }, [location, errors, setErrors]);

  const currencies = ['GBP','USD'];
  const locales = ['en-GB','en-US'];

  return (
    <>
      <div className={classes.root}>
      <TextField
          className={classes.textField}
          data-qa="number-of-tables-textfield"
          label="Number of tables"
          variant="outlined"
          error={errors.number_of_tables as boolean}
          helperText={errors.number_of_tables}
          value={location.number_of_tables || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.NUMBER_OF_TABLES, value: event.target.value })}
          onKeyUp={() => validate('number_of_tables')}
          onBlur={() => validate('number_of_tables')}/>

        <Divider className={classes.divider} />

        <TextField
          className={classes.textField}
          data-qa="tax-number-textfield"
          required
          label="Tax number"
          variant="outlined"
          value={location.tax_number}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.TAX_NUMBER, value: event.target.value })}
          onKeyUp={() => validate('tax_number')}
          onBlur={() => validate('tax_number')} />

        <FormControl variant="outlined" className={classes.textField} required error={errors.currency as boolean}>
          <InputLabel id="currency-select-label">Currency</InputLabel>
          <Select
            labelId="currency-select-label"
            label="Currency"
            autoWidth
            value={location.currency}
            onChange={(event: React.ChangeEvent<any>) => dispatch({ type: LocationReducerAction.CURRENCY, value: event.target.value })} 
            onBlur={() => validate('currency')}>
              {currencies.map((i) =>  (<MenuItem key={i} value={i}>{i}</MenuItem>))}
          </Select>
          <FormHelperText>{errors.currency}</FormHelperText>
        </FormControl>

        <FormControl variant="outlined" className={classes.textField} required error={errors.locale as boolean}>
          <InputLabel id="locale-select-label">Locale</InputLabel>
          <Select
            labelId="locale-select-label"
            label="Locale"
            autoWidth
            value={location.locale}
            onChange={(event: React.ChangeEvent<any>) => dispatch({ type: LocationReducerAction.LOCALE, value: event.target.value })} 
            onBlur={() => validate('locale')} >
              {locales.map((i) =>  (<MenuItem key={i} value={i}>{i}</MenuItem>))}
          </Select>
          <FormHelperText>{errors.locale}</FormHelperText>
        </FormControl>

        <Divider className={classes.divider} />

        <FormControlLabel
          label="Can manage menus?"
          className={classes.switch}
          control={
            <Switch
              checked={location.can_manage_menus}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.CAN_MANAGE_MENUS, value: event.target.checked })}
            />
          }/>

        <FormControlLabel
          label="Has a kitchen tablet?"
          className={classes.switch}
          control={
            <Switch
              checked={location.has_kitchen_printer}
              color="primary"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.HAS_KITCHEN_PRINTER, value: event.target.checked })}
            />
          }/>
          <FormControlLabel
            label="Has a bar tablet?"
            className={classes.switch}
            control={
              <Switch
                checked={location.has_bar_printer}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.HAS_BAR_PRINTER, value: event.target.checked })}
              />
            }/>

          <Divider className={classes.divider} />
            
          <FormControl className={classes.staff}>
            <FormControlLabel
              label="Staff enabled (PIN access)?"
              className={classes.switch}
              control={
                <Switch
                  checked={location.has_staff}
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.HAS_STAFF, value: event.target.checked })}
                />
            }/>
            <FormHelperText className={classes.switchHelper}>This enables the management of staff members without user accounts and enables PIN access to the tablet</FormHelperText>
          </FormControl>

        {location.has_staff && (
          <FormControl variant="outlined" className={classes.textField} required error={errors.locale as boolean}>
            <InputLabel id="idle_timeout-select-label">Idle timeout</InputLabel>
            <Select
              labelId="idle_timeout-select-label"
              label="Idle timeout"
              autoWidth
              value={location.idle_timeout}
              onChange={(event: React.ChangeEvent<any>) => dispatch({ type: LocationReducerAction.IDLE_TIMEOUT, value: event.target.value })} >
                {
                  [
                    { t: 30, l: '30 seconds' },
                    { t: 45, l: '45 seconds' },
                    { t: 60, l: '1 minute' },
                    { t: 90, l: '1 minute 30 seconds' },
                    { t: 120, l: '2 minutes' },
                  ].map((i) =>  (<MenuItem key={i.t} value={i.t}>{i.l}</MenuItem>))
                }
            </Select>
            <FormHelperText>The time the device has to be inactive before it locks and requires a PIN to access</FormHelperText>
          </FormControl>
        )}
            
        <FormControl className={classes.staff}>
          <FormControlLabel
            label="Multi station printing"
            
            className={classes.switch}
            control={
              <Switch
                checked={location.has_station_printers}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: LocationReducerAction.HAS_STATION_PRINTERS, value: event.target.checked })}
              />
          }/>
          <FormHelperText className={classes.switchHelper}>This enables the management of LAN (WiFI / Network) printers to allow menu categories to be sent to a specific printer</FormHelperText>
        </FormControl>
      </div>
    </>
  );
};

export default withStyles(styles)(StepDetails);
