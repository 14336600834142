import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import {
  withStyles,
} from '@material-ui/core';

import ReadOnlyBlock from '../../../../../ReadOnlyBlock';
import ColourPickerItem from '../../../../../Global/ColourPicker/item';
import { booleanToYesNo } from '../../../../../../utils/stringUtils';
import { Category } from '../..';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>,
  category: Category,
  setStep: React.Dispatch<React.SetStateAction<number>>,
}

const StepReview = ({ classes, category, setStep }: Props): React.ReactElement => {
  return (
    <>
      <ReadOnlyBlock
        gridSize={6}
        title={category.name}
        items={[
          { label: 'Active', value: booleanToYesNo(category.active) },
          { label: 'Colour', components: [category.colour_id ? <ColourPickerItem small colour_id={category.colour_id} /> : null]  },
        ]}
        handleEdit={() => setStep(0)} />
    </>
  );
};

export default withStyles(styles)(StepReview);
