import { ValidationType } from '../../../../react-app-env';

export interface LocationIntegration {
  id: string,
  payment: PaymentIntegration,
  deliverect: DeliverectIntegration,
  xero: XeroIntegration,
  vouchers: boolean,
}

export interface PaymentIntegration {
  active: boolean,
  provider: PaymentProvider | null,
  accountId: string,
  enableTipping?: boolean,
  password: string,
  username: string,
}

export interface DeliverectIntegration {
  active: boolean,
  accountId: string,
  locationId: string,
  autoAccept: boolean,
}

export interface XeroIntegration {
  active: boolean,
  code: string,
}

export interface ValidationErrors {
  deliverectAccountId: ValidationType,
  deliverectLocationId: ValidationType,
  paymentAccountId: ValidationType,
  paymentProvider: ValidationType,
  paymentUsername: ValidationType,
  paymentPassword: ValidationType,
  xeroCode: ValidationType,
}

export const validationErrors: ValidationErrors = {
  deliverectAccountId: 'Please provide an account id',
  deliverectLocationId: 'Please provide an location id',
  paymentAccountId: 'Please provide an account id',
  paymentProvider: 'Please provide a payment provider',
  paymentUsername: 'Please provide a payment username',
  paymentPassword: 'Please provide a payment password',
  xeroCode: 'You must login to xero and authorise AccentPOS',
}

export interface Validation {
  deliverectAccountId: (value: string) => ValidationType,
  deliverectLocationId: (value: string) => ValidationType,
  paymentProvider: (value: PaymentProvider | null) => ValidationType,
  paymentAccountId: (value: string) => ValidationType,
  paymentUsername: (value: string) => ValidationType,
  paymentPassword: (value: string) => ValidationType,
  xeroCode: (value: string) => ValidationType,
}

export const validation: Validation = {
  deliverectAccountId: (value) => !value ? validationErrors.deliverectAccountId : false,
  deliverectLocationId: (value) => !value ? validationErrors.deliverectLocationId : false,
  paymentProvider: (value) => !value ? validationErrors.paymentProvider : false,
  paymentAccountId: (value) => !value ? validationErrors.paymentAccountId : false,
  paymentUsername: (value) => !value ? validationErrors.paymentUsername : false,
  paymentPassword: (value) => !value ? validationErrors.paymentPassword : false,
  xeroCode: (value) => !value ? validationErrors.xeroCode : false,
}

export enum LocationIntegrationReducerAction {
  DELIVERECT_ACTIVE,
  DELIVERECT_ACCOUNT_ID,
  DELIVERECT_LOCATION_ID,
  DELIVERECT_AUTO_ACCEPT,
  PAYMENT_ACTIVE,
  PAYMENT_ACCOUNT_ID,
  PAYMENT_TIPPING,
  PAYMENT_PROVIDER,
  PAYMENT_USERNAME,
  PAYMENT_PASSWORD,
  XERO_ACTIVE,
  XERO_ACCOUNT_ID,
  VOUCHERS,
  INIT,
}

export enum PaymentProvider {
  DOJO = 'DOJO',
  ZETTLE = 'ZETTLE',
}

export const locationIntegrationReducer = (state: LocationIntegration, action: { type: LocationIntegrationReducerAction, value: any }): LocationIntegration =>  {
  switch (action.type) {
    case LocationIntegrationReducerAction.DELIVERECT_ACTIVE:
      return { ...state, deliverect: {
        ...state.deliverect,
        active: action.value as boolean,
      } };
    case LocationIntegrationReducerAction.DELIVERECT_ACCOUNT_ID:
      return { ...state, deliverect: {
        ...state.deliverect,
        accountId: action.value,
      } };
    case LocationIntegrationReducerAction.DELIVERECT_LOCATION_ID:
      return { ...state, deliverect: {
        ...state.deliverect,
        locationId: action.value,
      } };
    case LocationIntegrationReducerAction.DELIVERECT_AUTO_ACCEPT:
      return { ...state, deliverect: {
        ...state.deliverect,
        autoAccept: action.value as boolean,
      } };
    case LocationIntegrationReducerAction.PAYMENT_ACTIVE:
      return { ...state, payment: {
        ...state.payment,
        active: action.value as boolean,
      } };
    case LocationIntegrationReducerAction.PAYMENT_ACCOUNT_ID:
      return {
        ...state,
        payment: {
          ...state.payment,
          accountId: action.value
        }
      };
    case LocationIntegrationReducerAction.PAYMENT_TIPPING:
      return { ...state, payment: {
        ...state.payment,
        enableTipping: action.value as boolean,
      } };
      case LocationIntegrationReducerAction.PAYMENT_PROVIDER:
        return { ...state, payment: {
          ...state.payment,
          provider: action.value as PaymentProvider,
        } };
    case LocationIntegrationReducerAction.PAYMENT_USERNAME:
      return { ...state, payment: {
        ...state.payment,
        username: action.value,
      } };
    case LocationIntegrationReducerAction.PAYMENT_PASSWORD:
      return { ...state, payment: {
        ...state.payment,
        password: action.value,
      } };
    case LocationIntegrationReducerAction.XERO_ACTIVE:
      return { ...state, xero: {
        ...state.xero,
        active: action.value as boolean,
      } };
    case LocationIntegrationReducerAction.XERO_ACCOUNT_ID:
      return { ...state, xero: {
        ...state.xero,
        code: action.value,
      } };
    case LocationIntegrationReducerAction.VOUCHERS:
      return { ...state, vouchers: action.value as boolean };
    case LocationIntegrationReducerAction.INIT:
      const { id, deliverect, payment, xero, vouchers } = action.value || { id: '', vouchers: false };
      const obj = {
        id,
        payment: {
          active: payment ? true : false,
          provider: payment ? payment.provider as PaymentProvider : null,
          accountId: payment ? payment.connection.accountId : '',
          enableTipping: payment ? payment.connection.enableTipping : null,
          username: payment ? payment.connection.username : '',
          password: payment ? payment.connection.password : '',
        },
        deliverect: {
          accountId: deliverect ? deliverect.accountId : '',
          locationId: deliverect ? deliverect.locationId : '',
          active: deliverect ? true : false,
          autoAccept: deliverect ? deliverect.autoAccept : false,
        },
        xero: {
          code: xero ? xero.code : '',
          active: xero ? true : false,
        },
        vouchers,
      };
      return { ...obj as LocationIntegration };
    default:
      throw new Error();
  }
}
