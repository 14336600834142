import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../../layouts/Dashboard';
import ListLocationPrinters from '../../../../components/Global/LocationPrinters/List';

import { selectLocationUsersInitQuery, Location } from './queries';
import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const OrgListLocationStaff = ({ classes, history }: Props): React.ReactElement => {

  const [location, setLocation] = useState<Location>({
    devices: [],
  });

  const { data: locationUsersInitQuery } = useQuery(selectLocationUsersInitQuery(), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && locationUsersInitQuery) {
      setLocation(locationUsersInitQuery.locations[0]);
    }
    return () => { mounted = false; };
  }, [locationUsersInitQuery]);

  const handleCreate = () => {
    history.push(`/loc/printers/create`);
  };

  const handleEdit = (id: string) => {
    history.push(`/loc/printers/${id}/edit`);
  };

  return (
    <Dashboard
      breadcrumbs={[
        { label: 'Printers' },
      ]}>
      <ListLocationPrinters
        printers={location.devices}
        handleCreate={handleCreate}
        handleEdit={handleEdit} />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(OrgListLocationStaff));
