import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  withStyles,
} from '@material-ui/core';

import Dashboard from '../../../layouts/Dashboard';
import LocationOrders from '../../../components/Global/LocationOrders';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>,
}

const ViewLocationOrders = ({ classes, history }: Props): React.ReactElement => {

  return (
    <Dashboard title="Orders">
      <LocationOrders />
    </Dashboard>
  );
};

export default withRouter(withStyles(styles)(ViewLocationOrders));
